import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from 'components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import GetAppIcon from '@material-ui/icons/GetApp';

import { AuthContext } from '../../AuthContext';

import {
  selectedValue,
  categoryTypesPresentOnTournament,
  finishedRegistration,
  findSortDirection,
  isSchool
} from 'helpers/selectors';
import {
  CATEGORY_ID,
  serverDefaultPath,
  DELETE_ICON,
  PARTICIPANT_ID,
  ADD_CATEGORIES,
  SUBMIT_APPLICATION,
  DOWNLOAD_FILES,
  TOUR_STEPS,
  ENABLED,
  NOT_SET,
  KEEP_EDITING,
  TEST_ID_DOWNLOAD_DOC,
  CLOSE_DISCARD,
  CLEAR_ICON,
  COACH_MODAL_EDIT_IC,
  ALL_SPORT_RANK,
  MODAL_EDIT_IC,
  MODAL_FILTER_IC,
  NAME,
  ID,
  CATEGORY_NAME,
  TEST_ID_INFO_BTN,
  TOURN_INFO_IC,
  SEND_FINAL_IC
} from 'helpers/constants';
import {
  textChangeHandler,
  fetchCountries,
  changeModal,
  fetchTokenForQuickRegitration,
  selectOption,
  changeImage,
  fetchUsers,
  addUser,
  updateUserDetails,
  fetchQdan,
  changeDate,
  addParticipant,
  fetchParticipants,
  updateParticipant,
  deleteParticipant,
  fetchCategories,
  onBlur,
  attachParticipants,
  downloadUplaodedFile,
  downloadFile,
  fetchTournaments,
  fetchUploadedFiles,
  validateForm,
  exportParticipants,
  exportJudges,
  onScroll,
  applyCategories,
  selectCheckbox,
  addNewCategory,
  selectMultipleCategories,
  selectMultipleCategoryType,
  selectMultipleTeams,
  removeCategory,
  validateMultipleCategoriesAssignment,
  setParticipantToSchool,
  selectCheckboxSchool,
  fetchAllCategoryTypes,
  fetchReference,
  fetchStaff,
  deleteStaff,
  addNewStaff,
  updateStaff,
  submitTournApplication,
  restoreParticipant,
  fetchReferenceRegion,
  pageChangeHandler,
  rowsPerPageHandler
} from 'helpers/util';
import { changeTitle } from 'helpers/actions';

import SideModal from 'components/Snackbar/SideModal';
import Modal from 'components/Modal/ModalNewDesign';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import QuickTournReg from 'components/QuickTournRegistration/Registration/QuickTournRegistration';
import AssignCategories from 'components/QuickTournRegistration/AssignCategories/AssignCategories';
import TournamentForm from '../../components/AddTournament/TournamentForm';
import DownloadFiles from 'components/DownloadFiles/DownloadFiles';
import PageGuide from 'components/PageGuide/PageGuide';
import LoadingState from 'components/LoadingState/LoadingState';
import Assign2School from 'components/School/Assign2School';
import CoachForm from 'components/QuickTournRegistration/CoachForm/CoachForm';
import ParticipantForm from 'components/QuickTournRegistration/ParticipantFormNewDesign/ParticipantForm';
import JudgeForm from 'components/StaffForm/StaffForm';
import Pagination from 'components/TablePagination/TablePagination';

import { InstallPendo } from '../../analytics';

import { styles } from './StylesRegisterToTourn';

const defaultParticipantForm = {
  first_name: '',
  last_name: '',
  gender: '',
  weight: '',
  birthday: '',
  age: ''
};
const defaultJudgeForm = {
  first_name: '',
  last_name: '',
  gender: '',
  birthday: ''
};
const initialStateCoachForm = {
  first_name: '',
  last_name: '',
  email: '',
  branch_chief: '',
  country_id: ''
};
const initialStateCategory = {
  type: '',
  id: '',
  team_id: '',
  filteredCategories: []
};
const schoolInfo = {
  training: true,
  nextQdan: false,
  next_qdan_id: ''
};

class RegisterToTourn extends Component {
  state = {
    token: this.props.match.params.token,
    countries: [],
    allRegions: [],
    allRegionsRegistrer: [],
    qdanList: [],
    participants: [],
    categoryTypes: [],
    errors: {},
    savedCoachForm: {},
    invitationInfo: {},
    isLoading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    showModal: false,
    success: false,
    shouldUpdate: false,
    open: false,
    filters: { categories: [], searchByOption: '0' },
    collapsed: {},
    filtersJudges: {},
    isFilterChecked: true,
    showError: {},
    selectedCategories: [initialStateCategory],
    selectedCategoriesErrors: [],
    personalAssignedCategories: [],
    tournamentData: {},
    uploadedFiles: [],
    categories: [],
    sortDirection: [1, 1, 1],
    sortDirectionJudges: [1, 1, 1],
    intermidiateSchoolInfo: schoolInfo,
    isSearching: false,
    innerPageIdx: 0,
    page: 1,
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      25,
    participantsCount: 0,
    allJudges: [],
    all_qual_iko: [],
    all_qual_rf: [],
    isSending: false,
    all_sport_rank: []
  };

  textChangeHandler = textChangeHandler.bind(this);
  fetchCountries = fetchCountries.bind(this);
  changeModal = changeModal.bind(this);
  fetchTokenForQuickRegitration = fetchTokenForQuickRegitration.bind(this);
  selectOption = selectOption.bind(this);
  changeImage = changeImage.bind(this);
  fetchUsers = fetchUsers.bind(this);
  addUser = addUser.bind(this);
  updateUserDetails = updateUserDetails.bind(this);
  fetchQdan = fetchQdan.bind(this);
  changeDate = changeDate.bind(this);
  addParticipant = addParticipant.bind(this);
  fetchParticipants = fetchParticipants.bind(this);
  updateParticipant = updateParticipant.bind(this);
  fetchCategories = fetchCategories.bind(this);
  deleteParticipant = deleteParticipant.bind(this);
  onBlur = onBlur.bind(this);
  attachParticipants = attachParticipants.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  fetchUploadedFiles = fetchUploadedFiles.bind(this);
  validateForm = validateForm.bind(this);
  exportParticipants = exportParticipants.bind(this);
  exportJudges = exportJudges.bind(this);
  onScroll = onScroll.bind(this);
  applyCategories = applyCategories.bind(this);
  selectCheckbox = selectCheckbox.bind(this);
  addNewCategory = addNewCategory.bind(this);
  selectMultipleCategories = selectMultipleCategories.bind(this);
  selectMultipleCategoryType = selectMultipleCategoryType.bind(this);
  selectMultipleTeams = selectMultipleTeams.bind(this);
  removeCategory = removeCategory.bind(this);
  setParticipantToSchool = setParticipantToSchool.bind(this);
  validateMultipleCategoriesAssignment =
    validateMultipleCategoriesAssignment.bind(this);
  selectCheckboxSchool = selectCheckboxSchool.bind(this);
  fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
  fetchReference = fetchReference.bind(this);
  fetchStaff = fetchStaff.bind(this);
  deleteStaff = deleteStaff.bind(this);
  addNewStaff = addNewStaff.bind(this);
  updateStaff = updateStaff.bind(this);
  submitTournApplication = submitTournApplication.bind(this);
  restoreParticipant = restoreParticipant.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const {
      shouldUpdate,
      filters,
      selectedCategories,
      isEditing,
      isFilterChecked,
      intermidiateSchoolInfo,
      modalData,
      langOnLoad
    } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (shouldUpdate && shouldUpdate !== prevState.shouldUpdate) {
      this.fetchData(filters.searchBar);
    } else if (filters.searchBar !== prevState.filters.searchBar) {
      this.setState({ isSearching: true });
    }

    if (
      isEditing &&
      ((selectedCategories &&
        selectedCategories !== prevState.selectedCategories) ||
        isFilterChecked !== prevState.isFilterChecked ||
        (intermidiateSchoolInfo &&
          intermidiateSchoolInfo !== prevState.intermidiateSchoolInfo) ||
        modalData !== prevState.modalData)
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('registerToTourn'));

      this.setState({ langOnLoad: currentLang });
    }

    document.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  componentDidMount() {
    const { token, filters } = this.state;
    const { navigate, location } = this.props;

    this.setState({ isLoading: true });

    if (token) {
      //used when you access the quick registration page from a specific url
      this.fetchTokenForQuickRegitration(
        token,
        () => {
          this.fetchCountries();
          const invitation = this.state.invitationInfo;

          if (
            !localStorage.getItem('signinQuickRegister') &&
            invitation.global_token === ENABLED &&
            !invitation.api_token
          ) {
            const lang = localStorage.getItem('i18nextLng');
            localStorage.setItem(
              'navigated_2_register_2_tournament',
              `navigate_${Math.random()}`
            );
            localStorage.clear();
            localStorage.setItem('i18nextLng', lang);
            localStorage.setItem('invitation_token', invitation.token);
            localStorage.setItem('input_language', invitation.input_language);
            this.context.changeAuthState('');
            localStorage.setItem('signinQuickRegister', true);
          }
        },
        () => {
          this.setState({ isLoading: false });
          navigate('/', {
            state: { prevUrl: location.pathname }
          });
        }
      );
    } else {
      //used when you hit login from login page
      this.fetchTournaments(
        localStorage.getItem('invitation_tournId'),
        null,
        null,
        () => {
          localStorage.removeItem('navigated_2_register_2_tournament');

          const tournData = this.state.tournamentData;

          const openRegistration = !!+tournData?.registration_active;
          const openParticipantRegistration =
            openRegistration &&
            !finishedRegistration(
              tournData?.registration_date,
              tournData?.finished
            );
          const openJudgeRegistration =
            openRegistration &&
            !finishedRegistration(
              tournData?.reg_judge_date,
              tournData?.finished
            );

          if (!openParticipantRegistration && openJudgeRegistration) {
            this.setState({ innerPageIdx: 1 });
          }

          // this.fetchTrainingSchoolParticipants(tournData && tournData.id);
          this.fetchCoach(localStorage.getItem('user_id'), () => {
            const coachData = this.state.savedCoachForm;

            this.fetchCountries();
            this.fetchUploadedFiles(null, tournData?.id);
            this.fetchQdan();
            this.fetchData(filters.searchBar);

            this.fetchReference(ALL_SPORT_RANK, (data) => {
              this.setState({ all_sport_rank: data });
            });
            this.fetchCategories((categories) => {
              this.setState((prevState) => ({
                filters: {
                  ...prevState.filters,
                  categories
                }
              }));
            }, tournData?.id);

            coachData?.country_id &&
              this.fetchReferenceRegion(coachData?.country_id, null, (data) =>
                this.setState({ allRegionsRegistrer: data })
              );
          });
        }
      );
    }

    changeTitle(this.props.t('registerToTourn'));

    localStorage.removeItem('signinQuickRegister');

    const user = {
      id: localStorage.getItem('user_id'),
      name: localStorage.getItem('name'),
      language: localStorage.getItem('i18nextLng'),
      role: localStorage.getItem('role')
    };

    InstallPendo(user, user);
  }

  fetchData = (search, action) => {
    const {
      savedCoachForm,
      innerPageIdx,
      filters,
      sortDirection,
      sortDirectionJudges,
      page,
      rowsPerPage
    } = this.state;
    let cpyFilters = { ...filters };

    if (innerPageIdx === 0) {
      this.fetchParticipants(
        page,
        rowsPerPage,
        search,
        null,
        (items) => {
          const { assignedParticipants, participantCategories } =
            this.createParticipantsCategoriesState(items);

          cpyFilters.assignedParticipants = assignedParticipants;

          this.setState(
            {
              filters: cpyFilters,
              participants: items && items.data,
              participantsCount: items?.count > 0 ? items?.count : 0,
              isSearching: false,
              shouldUpdate: false,
              participantCategories,
              isLoading: false
            },
            () => action && action(participantCategories)
          );
        },
        this.state.invitationInfo.tournament_id ||
          this.state.tournamentData?.id,
        sortDirection?.[0],
        sortDirection?.[2],
        this.state.invitationInfo.api_token,
        this.state.filters?.filteredByCategoryId,
        { id: filters?.searchByOption },
        filters
      );
    } else {
      this.fetchStaff(
        this.state.tournamentData?.id,
        savedCoachForm?.id,
        (data) => {
          const assignedJudges = data?.filter((it) => !!+it?.status);
          cpyFilters.assignedJudges = assignedJudges;

          this.setState(
            {
              filters: cpyFilters,
              allJudges: data,
              shouldUpdate: false,
              isLoading: false
            },
            () => {
              if (sortDirectionJudges?.length > 0) {
                this.onSort(
                  sortDirectionJudges[2],
                  sortDirectionJudges[1],
                  true
                );
              }
            }
          );
        },
        null,
        { search, personal: 1 }
      );
    }
  };

  closeSnackBar = () => this.setState({ showModal: false });

  onSearch = () => {
    const { innerPageIdx } = this.state;
    const currentFilterName = innerPageIdx === 0 ? 'filters' : 'filtersJudges';

    this.setState(
      (prevState) => ({
        [currentFilterName]: {
          ...prevState[currentFilterName],
          searchedValue: this.state[currentFilterName].searchBar
        }
      }),
      () => {
        const { [currentFilterName]: filterName } = this.state;
        this.fetchData(filterName.searchedValue);
      }
    );
  };

  onPageChange = (e, next) => {
    const { isSearching, filters } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.onSearch();
      } else {
        this.fetchData(filters.searchBar);
      }
    });
  };

  triggerUpdate = () => this.setState({ shouldUpdate: true });

  showModal = (evt, rowData, tournID, categoryID, idx, currentTargetID) => {
    const shouldDeleteParticipant =
      rowData &&
      (!rowData.categories_info || rowData.categories_info.length === 0);
    const {
      participantCategories,
      invitationInfo,
      tournamentData,
      innerPageIdx,
      savedCoachForm
    } = this.state;
    const { id, category_types } = tournamentData;
    const isTraining = isSchool(tournamentData);

    this.setState(
      { ...(evt ? { pressedIcon: currentTargetID ?? evt.target.id } : {}) },
      () => {
        const { pressedIcon } = this.state;
        let copyModalData = { ...(rowData ?? {}) };
        let personalAssignedCategories = [];

        if (!isTraining && pressedIcon === ADD_CATEGORIES) {
          this.setState({ shouldShowCategoriesSelectors: false });

          this.fetchCategories(
            () => {
              this.fetchAllCategoryTypes((allSystemTypes) => {
                const categoryTypes = categoryTypesPresentOnTournament(
                  allSystemTypes || [],
                  category_types
                );
                let selectedCategories = [];
                const myCategories = selectedValue(
                  participantCategories,
                  PARTICIPANT_ID,
                  rowData?.id
                );

                selectedCategories = [...(myCategories?.categories ?? [])].map(
                  (it) => {
                    return {
                      ...it,
                      filteredCategories:
                        Array.isArray(this.state.filteredCategories) &&
                        (!it.type
                          ? this.state.filteredCategories
                          : this.state.filteredCategories.filter(
                              (cat) =>
                                `${cat.category_type_id}` === `${it.type}`
                            ))
                    };
                  }
                );

                this.setState({
                  categoriesFitsParticipant: this.state.filteredCategories,
                  shouldShowCategoriesSelectors: true,
                  categoryTypes,
                  selectedCategories:
                    selectedCategories.length > 0
                      ? selectedCategories
                      : [
                          {
                            ...initialStateCategory,
                            filteredCategories: this.state.filteredCategories
                          }
                        ],
                  selectedCategoriesErrors: [] //remove errors when opening a modal window for the first time
                });
              }, invitationInfo.tournament_id ?? id);
            },
            invitationInfo.tournament_id || id,
            null,
            null,
            null,
            this.state.isFilterChecked,
            { ...rowData }
          );
        } else if (pressedIcon === TEST_ID_DOWNLOAD_DOC) {
          this.downloadUplaodedFile(rowData?.document_id, null, (file) => {
            copyModalData = {
              ...copyModalData,
              preview: URL.createObjectURL(file)
            };
          });
        } else if (pressedIcon === MODAL_EDIT_IC) {
          if (
            rowData?.participant_country_id &&
            rowData?.id &&
            innerPageIdx === 0
          ) {
            this.fetchReferenceRegion(rowData?.participant_country_id);
          } else if (innerPageIdx !== 0) {
            this.fetchReferenceRegion(
              rowData?.countryId
                ? rowData?.countryId
                : savedCoachForm?.country_id
            );
          }

          if (!isTraining && innerPageIdx === 0) {
            const myCategories = selectedValue(
              participantCategories,
              PARTICIPANT_ID,
              rowData?.id
            );

            personalAssignedCategories = myCategories?.categories ?? [];
          }

          copyModalData = {
            ...copyModalData,
            ...(innerPageIdx === 0
              ? { patronymic_required: true, date_required: true }
              : {}),
            ...(rowData?.id
              ? innerPageIdx !== 0
                ? {
                    first_name: rowData.firstName,
                    last_name: rowData.lastName,
                    country_id: rowData.countryId,
                    last_name_national: rowData.lastNameInt,
                    first_name_national: rowData.firstNameInt,
                    qdan: rowData.qdanId,
                    qual_iko: rowData.qualificationIkoRid,
                    qual_rf: rowData.qualificationRfRid,
                    tournament_id: rowData.tournamentId ?? tournamentData?.id,
                    registrar_id: rowData.userId,
                    birthdayInputDate: rowData?.birthday,
                    days: rowData.days?.map((it) => ({
                      id: `${it}`
                    })),
                    currentRecordIdx: idx
                  }
                : {
                    personalAssignedCategories,
                    country_id: rowData?.participant_country_id,
                    history: rowData.history === NOT_SET ? '' : rowData.history,
                    iko: rowData.iko === NOT_SET ? '' : rowData.iko,
                    club: rowData.club !== NOT_SET ? rowData.club : '',
                    city: rowData.participant_city,
                    birthdayInputDate: rowData.birthday,
                    region: rowData.participant_region,
                    ...(isTraining
                      ? {
                          next_qdan_id:
                            rowData.school_info &&
                            `${rowData.school_info.next_qdan_id}`,
                          training: Boolean(
                            rowData.school_info && rowData.school_info.training
                          ),
                          nextQdan: Boolean(
                            rowData.school_info &&
                              rowData.school_info.next_qdan_id
                          )
                        }
                      : {}),
                    ...(rowData.photo
                      ? { imagePreview: serverDefaultPath + rowData.photo }
                      : {}),
                    currentRecordIdx: idx
                  }
              : {
                  ...this.autocompleteCoachDetails(
                    savedCoachForm,
                    +tournamentData?.type === 3
                  ),
                  tournament_id: tournamentData?.id
                })
          };
        } else if (pressedIcon === TOURN_INFO_IC) {
          copyModalData = {
            ...copyModalData,
            ...tournamentData,
            country_id: tournamentData?.c_id,
            date: tournamentData.start_date,
            registration_date: tournamentData.registration_date,
            tour_time: tournamentData.start_time,
            tournament_id: tournamentData.id,
            imagePreview: serverDefaultPath + tournamentData?.poster
          };
        } else if (pressedIcon === COACH_MODAL_EDIT_IC) {
          copyModalData = { ...savedCoachForm };
        }

        this.setState({
          modalData: {
            ...copyModalData,
            ...(categoryID ? { current_category_id: categoryID } : {}) // used to delete a particular participant category
          },
          shouldDeleteParticipant,
          personalAssignedCategories,
          ...(rowData ? { participantId: rowData.id } : {}),
          open: true,
          ...(rowData
            ? isTraining && {
                intermidiateSchoolInfo: rowData.school_info
                  ? {
                      training: Boolean(rowData.school_info.training),
                      next_qdan_id: `${rowData.school_info.next_qdan_id}`,
                      nextQdan: Boolean(rowData.school_info.next_qdan_id)
                    }
                  : { ...schoolInfo }
              }
            : {})
        });
      }
    );
  };

  openAssignFormInsideParticipantForm = () => {
    const {
      modalData,
      invitationInfo,
      tournamentData,
      personalAssignedCategories
    } = this.state;

    this.fetchCategories(
      () => {
        this.fetchAllCategoryTypes((allSystemTypes) => {
          const categoryTypes = categoryTypesPresentOnTournament(
            allSystemTypes ?? [],
            tournamentData?.category_types
          );
          let selectedCategories = [];

          selectedCategories = [...(personalAssignedCategories ?? [])].map(
            (it) => {
              return {
                ...it,
                filteredCategories:
                  Array.isArray(this.state.filteredCategories) &&
                  (!it.type
                    ? this.state.filteredCategories
                    : this.state.filteredCategories.filter(
                        (cat) => `${cat.category_type_id}` === `${it.type}`
                      ))
              };
            }
          );

          this.setState((prevState) => ({
            categoriesFitsParticipant: this.state.filteredCategories,
            categoryTypes,
            selectedCategories:
              selectedCategories.length > 0
                ? selectedCategories
                : [
                    {
                      ...initialStateCategory,
                      filteredCategories: this.state.filteredCategories
                    }
                  ],
            selectedCategoriesErrors: [], //remove errors when opening a modal window for the first time
            modalData: {
              ...prevState.modalData,
              modalEditParticipantSecondTab: true
            }
          }));
        }, invitationInfo.tournament_id ?? tournamentData?.id);
      },
      invitationInfo.tournament_id || tournamentData?.id,
      null,
      null,
      null,
      this.state.isFilterChecked,
      { ...modalData }
    );
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        pressedIcon: null,
        isFilterChecked: true,
        shouldShowDiscardChanges: false,
        isEditing: false,
        errors: {},
        showError: {}
      });
    }
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  fetchCoach = (coachId, actions) => {
    this.fetchUsers(
      coachId,
      'modalData',
      () => {
        this.setState(
          {
            savedCoachForm: this.state.modalData
          },
          () => actions && actions()
        );
      },
      this.state.invitationInfo.api_token
    );
  };

  saveCoachData = (evt) => {
    const { invitationInfo } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          initialStateCoachForm,
          form,
          'errors',
          () => {
            if (invitationInfo.api_token || form.id) {
              this.updateUserDetails(
                evt,
                form,
                () => this.fetchCoach(form.id),
                invitationInfo.api_token
              );

              this.setState({ isAttemptingToEditModalFields: false }, () => {
                this.hideModal();
              });
            }
          },
          () =>
            this.setState({
              showError: {
                email: true
              }
            })
        );
      }
    );
  };

  obtainCategoriesList = (personalAssignedCategories) =>
    personalAssignedCategories &&
    personalAssignedCategories.length > 0 &&
    personalAssignedCategories.map((key) => {
      return { id: key.id, team_id: key.team_id ? key.team_id : null };
    });

  saveForm = (evt) => {
    const {
      savedCoachForm,
      invitationInfo,
      personalAssignedCategories,
      tournamentData,
      innerPageIdx,
      isSending
    } = this.state;
    const { t } = this.props;

    const regionVal = document.getElementById('region')?.value;
    const onTriggeredUpdate = (msg, status) => {
      this.setState({ isAttemptingToEditModalFields: false }, () => {
        this.triggerUpdate();
        this.hideModal();

        if (msg && status === 200) {
          this.changeModal(msg, status);
        }
      });
    };

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state?.modalData;

        this.validateForm(
          evt,
          {
            ...(innerPageIdx === 0 ? defaultParticipantForm : defaultJudgeForm),
            ...(tournamentData && +tournamentData.type === 2
              ? { first_name_national: '', last_name_national: '' }
              : {}),
            ...(innerPageIdx === 0 && !!+tournamentData.height_needed
              ? { height: '' }
              : {}),
            ...(innerPageIdx === 0 && !!+tournamentData.show_reg_patronymic
              ? { patronymic: '' }
              : {}),
            ...(!!+tournamentData.show_reg_country ? { country_id: '' } : {}),
            ...(!!+tournamentData.show_reg_region ? { region: '' } : {}),
            ...(!!+tournamentData.show_reg_city ? { city: '' } : {}),
            ...(!!+tournamentData.show_reg_club ? { club: '' } : {})
          },
          form,
          'errors',
          () => {
            if (innerPageIdx === 0) {
              const currentListOfCategories = this.obtainCategoriesList(
                personalAssignedCategories
              );
              const categoriesList = currentListOfCategories
                ? currentListOfCategories
                : [];

              if (form?.id) {
                this.updateParticipant(
                  evt,
                  form,
                  () => {
                    onTriggeredUpdate();
                  },
                  invitationInfo.tournament_id || tournamentData,
                  categoriesList,
                  invitationInfo.api_token
                );
              } else if (!isSending) {
                this.addParticipant(
                  evt,
                  form,
                  savedCoachForm,
                  'errors',
                  'modalData',
                  (val, msg, status) => {
                    onTriggeredUpdate(t('successfullyAdded'), status);
                  },
                  invitationInfo.tournament_id || tournamentData,
                  categoriesList,
                  invitationInfo.api_token
                );
              }
            } else {
              if (form?.id) {
                this.updateStaff(evt, form, () => {
                  onTriggeredUpdate();
                });
              } else {
                !isSending &&
                  this.addNewStaff(evt, form, () => {
                    onTriggeredUpdate();
                  });
              }
            }
          }
        );
      }
    );
  };

  //assign categories using a checkbox in the table
  assignCategoriesDirectly = (evt) => {
    const {
      invitationInfo,
      tournamentData,
      savedCoachForm,
      participantId,
      participantCategories,
      modalData,
      filters
    } = this.state;
    this.applyCategories(
      modalData,
      (categories) => {
        const categoriesList = this.obtainCategoriesList(categories);

        if (categoriesList) {
          if (filters.searchBar) {
            this.fetchAllParticipantsAvoidingAnyFiltering((partCat) => {
              this.attachParticipants(
                evt,
                partCat,
                savedCoachForm.id,
                invitationInfo.tournament_id || tournamentData.id,
                this.triggerUpdate,
                participantId,
                categoriesList
              );
            });
          } else {
            this.attachParticipants(
              evt,
              participantCategories,
              savedCoachForm.id,
              invitationInfo.tournament_id || tournamentData.id,
              this.triggerUpdate,
              participantId,
              categoriesList
            );
          }
        }
      },
      true
    );
  };

  removeParticipantOrPartCategory = (evt) => {
    const {
      filters,
      invitationInfo,
      tournamentData,
      pressedIcon,
      collapsed,
      modalData
    } = this.state;
    const isTraining = isSchool(tournamentData);
    const participantID = modalData?.id;

    this.deleteParticipant(
      () => {
        this.fetchData(filters.searchBar, (participantCategories) => {
          if (pressedIcon === CLEAR_ICON && collapsed[modalData.id]) {
            // when deleting a category, if there is only one category left, there is no need to show the collapsed list
            let copyCollapsed = { ...collapsed };
            const hasMoreThanOneCategory =
              participantCategories.find(
                (it) => +it?.participant_id === +participantID
              )?.categories?.length < 2;

            if (hasMoreThanOneCategory) {
              delete copyCollapsed[modalData.id];

              this.setState({ collapsed: copyCollapsed });
            }
          }

          this.hideModal();
        });
      },
      (pressedIcon !== DELETE_ICON ?? isTraining) && tournamentData.id,
      invitationInfo.api_token,
      pressedIcon === 'checkboxCheckedIcon',
      pressedIcon === CLEAR_ICON && modalData?.current_category_id,
      isTraining
    );
  };

  createParticipantsCategoriesState = (items) => {
    let assignedParticipants = [];
    const participantCategories = items?.data?.map((it) => {
      if (it?.categories_info?.length > 0) {
        assignedParticipants = [...assignedParticipants, it];
      }

      return {
        participant_name: it.participant_name,
        participant_id: it.id,
        categories:
          it?.categories_info?.map((cat) => ({
            type: cat.category_type_id,
            id: cat.category_id,
            team_id: cat.team_id,
            name: cat.category_name,
            issue: cat.issues
          })) ?? []
      };
    });

    return { assignedParticipants, participantCategories };
  };

  fetchAllParticipantsAvoidingAnyFiltering = (action) => {
    this.fetchParticipants(
      null,
      null,
      null,
      null,
      (items) => {
        const { participantCategories } =
          this.createParticipantsCategoriesState(items);

        this.setState(
          { participantCategories },
          () => action && action(participantCategories)
        );
      },
      this.state.invitationInfo.tournament_id ||
        (this.state.tournamentData && this.state.tournamentData.id),
      null,
      null,
      this.state.invitationInfo.api_token
    );
  };

  submitFinalApplication = () => {
    const {
      token,
      invitationInfo,
      participantCategories,
      tournamentData,
      filters,
      filteredByCategoryId,
      participants
    } = this.state;
    const isTraining = isSchool(tournamentData);

    let isAtLeastOnePArticipantWithCategory = false; // Logic changed on 12/16/2022. If there is at least one participant with a category, submit the application
    const callback = (assignedPart) => {
      const len = assignedPart && assignedPart.length;

      if (len > 0) {
        for (let i = 0; i < len; i++) {
          const myItem = assignedPart[i];

          if (myItem.categories.length > 0) {
            isAtLeastOnePArticipantWithCategory = true;
            break;
          }
        }

        if (!isAtLeastOnePArticipantWithCategory) {
          this.setState({
            open: true,
            pressedIcon: SUBMIT_APPLICATION
          });
        } else {
          this.submitTournApplication(
            token,
            tournamentData.id,
            invitationInfo.api_token
          );

          this.setState({ open: false });
        }
      }
    };

    //check if table data was changed by filtering or searching, if yes, get all participants again without displaying it in the table
    if (!isTraining) {
      if (filters.searchBar || filteredByCategoryId) {
        this.fetchAllParticipantsAvoidingAnyFiltering((partCat) =>
          callback(partCat)
        );
      } else {
        callback(participantCategories);
      }
    } else {
      const len = participants && participants.length;
      let shouldSubmit;

      for (let i = 0; i < len; i++) {
        if (participants[i].school_info) {
          shouldSubmit = true;
          break;
        }
      }

      if (shouldSubmit) {
        this.submitTournApplication(
          token,
          tournamentData.id,
          invitationInfo.api_token
        );
      }
    }
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { innerPageIdx, sortDirection } = this.state;

    if (innerPageIdx === 0) {
      const { filters } = this.state;
      const direction = findSortDirection(sortDirection, sortField);

      this.setState(
        {
          ...(!noFirstTimeSort
            ? { sortDirection: [direction, idxCell, sortField] }
            : {})
        },
        () => {
          this.fetchData(filters.searchBar);
        }
      );
    } else {
      const { allJudges, sortDirectionJudges } = this.state;
      const clone = [...allJudges];
      const direction = findSortDirection(
        sortDirectionJudges,
        sortField,
        noFirstTimeSort
      );

      let field;

      switch (sortField) {
        case 1:
          field = 'name';
          break;
        case 2:
          field = 'gender';
          break;
        case 3:
          field = 'qdan_name';
          break;
        case 4:
          field = 'experience';
          break;
        case 5:
          field = 'status';
          break;
        default:
      }

      const sortedData = this.compareValue2Sort(
        clone,
        field,
        direction,
        sortField
      );

      this.setState({
        page: 1,
        ...(!noFirstTimeSort
          ? { sortDirectionJudges: [direction, idxCell, sortField] }
          : {}),
        allJudges: sortedData
      });
    }
  };

  compareValue2Sort(data, key, order, sortField) {
    const { tournamentData } = this.state;

    //Order ASC = 1, DESC = 2
    return data.sort((a, b) => {
      let comparison = 0;
      const MODAL_FIRST_NAME = (item) =>
        !+tournamentData?.last_name_first ? item.firstName : item.lastName;
      const MODAL_LAST_NAME = (item) =>
        !+tournamentData?.last_name_first ? item.lastName : item.firstName;
      const name1 = [MODAL_FIRST_NAME(a), MODAL_LAST_NAME(a)]
        .join('')
        .toLowerCase();
      const name2 = [MODAL_FIRST_NAME(b), MODAL_LAST_NAME(b)]
        .join('')
        .toLowerCase();

      const f1 = () => {
        if (!a[key] && !b[key]) {
          return 0;
        }

        if (!a[key]) {
          return sortField === 5 && order === 2 ? -1 : 1; //sortField===5 - sort status even if it has undefined values
        }

        if (!b[key]) {
          return sortField === 5 && order === 2 ? 1 : -1;
        }

        if (typeof a[key] === 'string') {
          //sort strings
          const firstVal = sortField === 1 ? name1 : a[key]?.toLowerCase();
          const secondVal = sortField === 1 ? name2 : b[key]?.toLowerCase();

          if (firstVal > secondVal) {
            comparison = 1;
          } else if (firstVal < secondVal) {
            comparison = -1;
          }
          return order === 2 ? comparison * -1 : comparison;
        }

        if (typeof a[key] === 'number') {
          if (order === 2) {
            return +a[key] - +b[key];
          } else {
            return +b[key] - +a[key];
          }
        }
      };

      const f2 = () => {
        if (a[key] === b[key]) {
          if (name1 > name2) {
            return order === 2 ? -1 : 1;
          } else if (name1 < name2) {
            return order === 2 ? 1 : -1;
          }
        }
      };

      return f1() || f2();
    });
  }

  onChangeInnerPage = (idx) => {
    const { all_qual_iko, all_qual_rf, allJudges } = this.state;

    this.setState(
      {
        innerPageIdx: idx,
        isLoading: true
      },
      () => {
        if (idx === 1) {
          if (all_qual_iko?.length === 0) {
            this.fetchReference('qual_iko', (data) =>
              this.setState({ all_qual_iko: data })
            );
          }

          if (all_qual_rf?.length === 0) {
            this.fetchReference('qual_rf', (data) =>
              this.setState({ all_qual_rf: data })
            );
          }

          this.setState({ allRegions: [] });
        }

        this.resetFilters();
      }
    );
  };

  autocompleteCoachDetails = (savedCoachForm, isTraining) => {
    return {
      coach_id: savedCoachForm.id,
      ...(isTraining ? schoolInfo : {}),
      ...(savedCoachForm.region ? { region: savedCoachForm.region } : {}),
      ...(savedCoachForm.club ? { club: savedCoachForm.club } : {}),
      ...(savedCoachForm.coach_first_name
        ? { coach_first_name: savedCoachForm.coach_first_name }
        : { coach_first_name: savedCoachForm.first_name }),
      ...(savedCoachForm.coach_last_name
        ? { coach_last_name: savedCoachForm.coach_last_name }
        : { coach_last_name: savedCoachForm.last_name }),
      country_id: savedCoachForm.country_id,
      ...(savedCoachForm.city ? { city: savedCoachForm.city } : {})
    };
  };

  onToggleJudgeStatus = (evt, item, checked) => {
    const { tournamentData } = this.state;

    const form = {
      ...item,
      first_name: item.firstName,
      last_name: item.lastName,
      country_id: item.countryId,
      last_name_national: item.lastNameInt,
      first_name_national: item.firstNameInt,
      qdan: item.qdanId,
      qual_iko: item.qualificationIkoRid,
      qual_rf: item.qualificationRfRid,
      tournament_id: item?.tournamentId ?? tournamentData?.id,
      registrar_id: item.userId,
      days: item.days?.map((it) => ({
        id: `${it}`
      })),
      status: checked ? 1 : 0
    };

    this.updateStaff(
      evt,
      form,
      () => {
        this.triggerUpdate();
      },
      true
    );
  };

  fetchRegionBySelectedCountry = (key, name) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key, name);
    this.setState({ modalData: cpy });
  };

  onChangeSearchTxt(evt) {
    const { innerPageIdx } = this.state;
    const { value } = evt.target;
    const currentFilterName = innerPageIdx === 0 ? 'filters' : 'filtersJudges';

    this.setState((prevState) => ({
      [currentFilterName]: { ...prevState[currentFilterName], searchBar: value }
    }));
  }

  onClearSearchInput() {
    const { innerPageIdx } = this.state;
    const currentFilterName = innerPageIdx === 0 ? 'filters' : 'filtersJudges';

    this.setState(
      (prevState) => ({
        [currentFilterName]: { ...prevState[currentFilterName], searchBar: '' }
      }),
      () => {
        const { [currentFilterName]: filterName } = this.state;

        if (filterName.searchedValue) {
          this.onSearch();
        }
      }
    );
  }

  itemToggle = (key) => {
    this.setState((prevState) => ({
      collapsed: { ...prevState.collapsed, [key]: !this.state.collapsed[key] }
    }));
  };

  applyCategoriesInsideParticipantForm = () => {
    const { selectedCategories, categoriesFitsParticipant } = this.state;
    //here the categories are filtered again to identify the issue for the categories that were assigned erroneously

    const personalAssignedCategories = selectedCategories
      .filter((val, idx, array) => {
        // filter out categories that are repeated
        return (
          idx === array.findIndex((it) => `${it.id}` === `${val.id}`) &&
          val.id !== ''
        );
      })
      .map((it) => ({
        ...it,
        ...{
          issue: !selectedValue(
            categoriesFitsParticipant,
            CATEGORY_ID,
            `${it.id}`
          )
        }
      }));

    this.setState((prevState) => ({
      personalAssignedCategories: [...personalAssignedCategories],
      modalData: {
        ...prevState.modalData,
        modalEditParticipantSecondTab: false
      }
    }));
  };

  closeModalEditParticipantSecondTab = () => {
    this.setState((prevState) => ({
      modalData: {
        ...prevState.modalData,
        modalEditParticipantSecondTab: false
      }
    }));
  };

  selectDays = (_, value) => {
    const { modalData } = this.state;
    const copyForm = { ...modalData };

    if (value !== null) {
      copyForm.days = value;
    }

    this.setState({ modalData: copyForm });
  };

  selectArchive = (evt) => {
    const { searchBar } = this.state;
    const { checked } = evt.target;

    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, archive: checked } }),
      () => {
        this.fetchData(searchBar);
      }
    );
  };

  onFilterRecordsBySelectedCategory = (evt, val) => {
    this.selectOption(
      evt,
      val,
      'filters',
      'filteredByCategoryId',
      CATEGORY_ID,
      null,
      () => {
        const { searchBar } = this.state;

        this.fetchData(searchBar);
      }
    );
  };

  applyOnFilterByCategoryList = () => {
    const { modalData, filters } = this.state;
    const newFilters = {
      ...filters,
      filteredByCategoryId: modalData.current_category_id
    };

    this.setState({ filters: { ...newFilters }, searchBar: null }, () => {
      this.fetchData(null, () => this.hideModal());
    });
  };

  onRemoveCategoriesInForm = (item) => {
    const { personalAssignedCategories } = this.state;
    let copy = [...personalAssignedCategories];

    copy = copy?.filter((it) => it !== item);

    this.setState({ personalAssignedCategories: copy });
  };

  onSwitchToggleAssigned = (evt) => {
    const { filters, innerPageIdx } = this.state;
    const { checked } = evt.target;
    let cpy = { ...filters };

    innerPageIdx === 0
      ? (cpy.showAssignedParticipants = checked)
      : (cpy.showAssignedJudges = checked);

    this.setState({
      filters: cpy
    });
  };

  resetFilters = () => {
    const { filters, innerPageIdx } = this.state;
    let cpy = { ...filters };

    if (innerPageIdx === 0) {
      cpy = {
        ...cpy,
        showAssignedParticipants: false,
        archive: false,
        filteredByCategoryId: ''
      };

      this.setState({ filters: cpy }, () => {
        this.fetchData(filters.searchBar);
      });
    } else {
      cpy = { ...cpy, showAssignedJudges: false };

      this.setState({ filters: cpy }, () => {
        this.fetchData(filters.searchBar);
      });
    }
  };

  render() {
    const {
      countries,
      allRegions,
      allRegionsRegistrer,
      savedCoachForm,
      invitationInfo,
      errors,
      success,
      showModal,
      modalInfo,
      sortDirection,
      sortDirectionJudges,
      qdanList,
      participants,
      categoryTypes,
      open,
      pressedIcon,
      selectedCategories,
      selectedCategoriesErrors,
      filters,
      collapsed,
      filtersJudges,
      showError,
      personalAssignedCategories,
      isFilterChecked,
      participantCategories,
      isLoading,
      shouldDeleteParticipant,
      shouldShowTour,
      tournamentData,
      uploadedFiles,
      shouldShowDiscardChanges,
      intermidiateSchoolInfo,
      participantId,
      modalData,
      allJudges,
      innerPageIdx,
      participantsCount,
      page,
      rowsPerPage,
      all_qual_iko,
      all_qual_rf,
      all_sport_rank
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;

    const isTraining = isSchool(tournamentData);
    const openRegistration = !!+tournamentData?.registration_active;
    const openParticipantRegistration =
      openRegistration &&
      !finishedRegistration(
        tournamentData?.registration_date,
        tournamentData?.finished
      );
    const openJudgeRegistration =
      openRegistration &&
      !finishedRegistration(
        tournamentData?.reg_judge_date,
        tournamentData?.finished
      );
    const PART_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';

    const PART_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';

    const FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('firstname')
      : t('lastname');

    const LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('lastname')
      : t('firstname');

    const PART_FIRST_NAME_NATIONAL_VAL = !+tournamentData?.last_name_first
      ? 'first_name_national'
      : 'last_name_national';

    const PART_LAST_NAME_NATIONAL_VAL = !+tournamentData?.last_name_first
      ? 'last_name_national'
      : 'first_name_national';

    const PART_FIRST_NAME_NATIONAL_LABEL = !+tournamentData?.last_name_first
      ? t('firstNameNational')
      : t('lastNameNational');

    const PART_LAST_NAME_NATIONAL_LABEL = !+tournamentData?.last_name_first
      ? t('lastNameNational')
      : t('firstNameNational');
    const FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';

    const LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';

    const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_first_name'
      : 'coach_last_name';

    const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_last_name'
      : 'coach_first_name';

    const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachFirstName')
      : t('coachLastName');

    const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachLastName')
      : t('coachFirstName');

    const dojoInfo = [
      {
        name: FIRST_NAME_VAL,
        label: FIRST_NAME_LABEL,
        required: true
      },
      {
        name: LAST_NAME_VAL,
        label: LAST_NAME_LABEL,
        required: true
      },
      {
        name: 'patronymic',
        label: t('patronymic')
      },
      {
        name: 'email',
        label: t('email'),
        shouldEditCoach: savedCoachForm.id,
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        name: 'branch_chief',
        label: t('nameBranchChief'),
        required: true
      },
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL
      },
      {
        country: true,
        isFilter: true
      },
      allRegionsRegistrer?.length > 0
        ? {
            region: true,
            isFilter: true
          }
        : {
            name: 'region',
            label: t('region')
          },
      {
        name: 'city',
        label: t('city')
      },
      {
        name: 'club',
        label: t('cluborFederationName')
      },
      {
        label: t('phone'),
        name: 'phone'
      },
      { label: t('website'), name: 'website' },
      { save: true }
    ];

    const MODAL_FIRST_NAME = (item) =>
      !+tournamentData?.last_name_first ? item.first_name : item.last_name;

    const MODAL_LAST_NAME = (item) =>
      !+tournamentData?.last_name_first ? item.last_name : item.first_name;

    const assignCategoriesComponent = (
      <AssignCategories
        {...{ categoryTypes }}
        {...{ selectedCategories }}
        {...{ selectedCategoriesErrors }}
        addNewCategory={() => this.addNewCategory(initialStateCategory)}
        removeCategory={this.removeCategory}
        selectCategories={this.selectMultipleCategories}
        selectCategoryType={this.selectMultipleCategoryType}
        selectTeam={this.selectMultipleTeams}
      />
    );

    const CATEGORY_FILTER = (
      <CheckboxBtn
        className={classes.checkboxDefault}
        checked={isFilterChecked}
        onChange={(evt) =>
          this.selectCheckbox(
            evt,
            modalData,
            invitationInfo.tournament_id || tournamentData.id,
            true
          )
        }
        label={t('autoSelectionByAgeSex')}
      />
    );

    let onClick,
      dialogTitle,
      onSwitchBetween,
      dialogContent,
      buttonPurpose,
      modalContent,
      subHeader,
      closeButton,
      closeButtonlabel;

    if (pressedIcon === SUBMIT_APPLICATION) {
      dialogTitle = t('failedToSendRequest');
      dialogContent = t('failedToSendRequestMsg');
    } else if (pressedIcon === ADD_CATEGORIES) {
      onClick = (evt) => {
        if (isTraining) {
          this.setParticipantToSchool(
            evt,
            intermidiateSchoolInfo,
            tournamentData,
            participantId,
            () => {
              //when the update form is opened and we use the icon on the right to assign the same participant, update the form with last changes
              this.triggerUpdate();
            }
          );
        } else {
          this.validateMultipleCategoriesAssignment(() => {
            this.assignCategoriesDirectly(evt);
          });
        }
      };
      dialogTitle = isTraining
        ? t('assignParticipantToTraining')
        : t('assignParticipant');
      dialogContent = !isTraining && CATEGORY_FILTER;
      buttonPurpose = t('apply');

      if (isTraining) {
        modalContent = (
          <Assign2School
            {...{ intermidiateSchoolInfo }}
            {...{ qdanList }}
            selectOption={this.selectOption}
            selectCheckboxSchool={this.selectCheckboxSchool}
          />
        );
      } else {
        modalContent = assignCategoriesComponent;
      }
    } else if (pressedIcon === DELETE_ICON) {
      onClick = (evt) =>
        innerPageIdx === 0
          ? !shouldDeleteParticipant
            ? undefined
            : this.removeParticipantOrPartCategory(evt)
          : this.deleteStaff(modalData, () => {
              this.fetchData(filtersJudges.searchBar);
              this.hideModal();
            });
      dialogTitle = t('deleteRecord', {
        name: t(innerPageIdx === 0 ? 'participant' : '')
      });
      dialogContent =
        innerPageIdx === 0
          ? shouldDeleteParticipant
            ? t('deleteParticipantMsg')
            : t('deleteParticipantWithCategoriesMsg')
          : t('deleteJudgeMsg');
      buttonPurpose = t('deleteRecord', { name: '' });
    } else if (pressedIcon === DOWNLOAD_FILES) {
      dialogTitle = [t('download'), t('tournDoc')].join(' ');
      modalContent = (
        <DownloadFiles
          files={invitationInfo.downloads || uploadedFiles}
          download={this.downloadUplaodedFile}
          userToken={invitationInfo.api_token}
        />
      );
    } else if (pressedIcon === CLEAR_ICON) {
      dialogTitle = t('removeParticipantCateory');
      dialogContent = t('removeParticipantCateoryMsg', {
        name: modalData?.current_category_id
          ? `(${
              modalData?.categories_info?.find(
                (it) => +it.category_id === +modalData?.current_category_id
              )?.category_name
            })`
          : ''
      });
      buttonPurpose = t('remove');
      onClick = this.removeParticipantOrPartCategory;
    } else if (pressedIcon === TEST_ID_DOWNLOAD_DOC) {
      dialogTitle = t('judicialQualifications');
      modalContent = modalData?.preview ? (
        <span className={classes.flex}>
          <img
            src={modalData?.preview}
            alt={t('photo')}
            className={classes.docImg}
          />

          <span
            className={clsx(
              classes.flex,
              classes.centerVertically,
              classes.left
            )}>
            <GetAppIcon
              onClick={() => this.downloadUplaodedFile(modalData?.documentId)}
              className={clsx(classes.downloadIc)}
            />
          </span>
        </span>
      ) : (
        t('noAttachmentsMsg')
      );
    } else if (pressedIcon === TOURN_INFO_IC) {
      dialogTitle = t('eventInfo');
      modalContent = (
        <TournamentForm
          noEvent={true}
          {...{ countries }}
          values={modalData}
          errors={{}}
          specificDetails={{
            className: {
              noEvent: classes.noEvent
            },
            expandBlock: true,
            hideComponent: true,
            hide_regJudgeDate: !+tournamentData?.reg_judge_form
          }}
        />
      );
    } else if (pressedIcon === COACH_MODAL_EDIT_IC) {
      dialogTitle = t('editRep');
      modalContent = (
        <CoachForm
          {...{ tournamentData }}
          param={'modalData'}
          inputs={dojoInfo}
          {...{ countries }}
          changeTxt={this.textChangeHandler}
          values={modalData}
          selectOption={this.selectOption}
          changeImage={this.changeImage}
          {...{ errors }}
          saveCoachData={this.saveCoachData}
          onCoachEdit={this.onCoachEdit}
          {...{ showError }}
          onBlur={this.onBlur}
          changeDate={(date) => {
            this.changeDate(date, 'modalData', errors, true);
          }}
          fetchRegionBySelectedCountry={(val) =>
            this.fetchRegionBySelectedCountry(val, 'allRegionsRegistrer')
          }
          allRegions={allRegionsRegistrer}
        />
      );
    } else if (pressedIcon === MODAL_EDIT_IC) {
      if (modalData.modalEditParticipantSecondTab) {
        dialogTitle = t('assignParticipant');
        onClick = () =>
          this.validateMultipleCategoriesAssignment(
            this.applyCategoriesInsideParticipantForm
          );
        dialogContent = !isTraining && CATEGORY_FILTER;
        buttonPurpose = t('apply');
      } else {
        dialogTitle = modalData?.id
          ? innerPageIdx === 0
            ? t('updateRecord', { name: t('athlete') })
            : t('updateJudge')
          : innerPageIdx === 0
          ? t('addRecord', { name: t('athlete') })
          : t('addJudge');
      }

      modalContent =
        innerPageIdx === 0 ? (
          modalData.modalEditParticipantSecondTab ? (
            assignCategoriesComponent
          ) : (
            <ParticipantForm
              selectCheckboxSchool={this.selectCheckboxSchool}
              openAssignFormInsideParticipantForm={
                this.openAssignFormInsideParticipantForm
              }
              {...{ tournamentData }}
              showModal={this.showModal}
              changeTxt={this.textChangeHandler}
              param={'modalData'}
              {...{ countries }}
              values={modalData}
              selectOption={this.selectOption}
              changeImage={this.changeImage}
              {...{ errors }}
              saveParticipant={this.saveForm}
              {...{ qdanList }}
              changeDate={(date) =>
                this.changeDate(
                  date,
                  'modalData',
                  errors,
                  invitationInfo.api_token || true,
                  tournamentData?.calc_age_date ?? tournamentData?.start_date
                )
              }
              {...{ personalAssignedCategories }}
              openRegistration={openParticipantRegistration}
              fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
              {...{ allRegions }}
              {...{ all_sport_rank }}
              tournamentIdParams={tournamentData?.id}
              partFirstLastNameOrder={{
                firstNameVal: PART_FIRST_NAME_VAL,
                lastNameVal: PART_LAST_NAME_VAL,
                firstNameLabel: FIRST_NAME_LABEL,
                lastNameLabel: LAST_NAME_LABEL,
                firstNameNationalVal: PART_FIRST_NAME_NATIONAL_VAL,
                lastNameNationalVal: PART_LAST_NAME_NATIONAL_VAL,
                firstNameNationalLabel: PART_FIRST_NAME_NATIONAL_LABEL,
                lastNameNationalLabel: PART_LAST_NAME_NATIONAL_LABEL
              }}
              onRemoveCategoriesInForm={this.onRemoveCategoriesInForm}
              componentElems={{ tournamentsList: true }}
            />
          )
        ) : (
          <JudgeForm
            selectDays={this.selectDays}
            {...{ tournamentData }}
            changeTxt={this.textChangeHandler}
            param={'modalData'}
            {...{ countries }}
            values={modalData}
            selectOption={this.selectOption}
            {...{ errors }}
            saveForm={this.saveForm}
            {...{ qdanList }}
            openRegistration={openJudgeRegistration}
            {...{ all_qual_iko }}
            {...{ all_qual_rf }}
            changeDate={(date) =>
              this.changeDate(
                date,
                'modalData',
                errors,
                invitationInfo.api_token || true,
                invitationInfo.tournament_date || tournamentData.start_date
              )
            }
            changeImage={this.changeImage}
            fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
            {...{ allRegions }}
          />
        );

      const currentRecordsData =
        innerPageIdx === 0
          ? filters?.showAssignedParticipants
            ? filters?.assignedParticipants
            : participants
          : filters?.showAssignedJudges
          ? filters?.assignedJudges
          : allJudges;

      onSwitchBetween = modalData?.id && {
        prev: () => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const item = currentRecordsData[nextItemIdx];

            this.showModal(null, item, null, null, nextItemIdx);
          }
        },
        next: () => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const len = currentRecordsData?.length - 1;

          if (len >= nextItemIdx) {
            const item = currentRecordsData[nextItemIdx];

            this.showModal(null, item, null, null, nextItemIdx);
          }
        }
      };
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      onClick = this.applyOnFilterByCategoryList;
      subHeader = modalData.current_category_name;
      modalContent = modalData?.participants?.map((it, idx) => (
        <span className={clsx(classes.flex, classes.marginBottom05)} key={idx}>
          {idx + 1}. {it.participant_name}
          {'   '}(
          {[
            it.gender,
            it.age,
            it.weight && `${it.weight}kg`,
            it.qdan_name
          ].join(', ')}
          )
        </span>
      ));
      dialogTitle = t('allParticipants');
      buttonPurpose = t('proceed');
    } else if (pressedIcon === SEND_FINAL_IC) {
      dialogContent = t('generateEmailToTournamentRegistrar');
      dialogTitle = t('provideThisAction');
      onClick = this.submitFinalApplication;
      buttonPurpose = t('yes');
      subHeader = null;
    }

    return (
      !this.state.shouldRedirect && (
        <>
          <SideModal
            closeModal={this.closeSnackBar}
            {...{ success }}
            show={showModal}
            info={modalInfo}
          />
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              <Modal
                //shouldShowCategoriesSelectors state is used to open the modal window only after the request has completed, thus it doesn't blink
                open={
                  !isTraining && pressedIcon === ADD_CATEGORIES
                    ? open && this.state.shouldShowCategoriesSelectors
                    : open
                }
                close={
                  pressedIcon === MODAL_EDIT_IC &&
                  modalData.modalEditParticipantSecondTab
                    ? this.closeModalEditParticipantSecondTab
                    : this.hideModal
                }
                classNameBtn={
                  pressedIcon === ADD_CATEGORIES && classes.marginTop0
                }
                showCloseBtn={false}
                {...{ onClick }}
                {...{ dialogTitle, onSwitchBetween }}
                {...{ dialogContent }}
                {...{ buttonPurpose }}
                {...{ shouldShowDiscardChanges }}
                {...{ closeButton, closeButtonlabel }}
                subHeader={
                  subHeader
                    ? subHeader
                    : pressedIcon !== TOURN_INFO_IC &&
                      pressedIcon !== MODAL_FILTER_IC &&
                      pressedIcon !== COACH_MODAL_EDIT_IC &&
                      (pressedIcon !== MODAL_EDIT_IC ||
                        (pressedIcon === MODAL_EDIT_IC &&
                          modalData.modalEditParticipantSecondTab)) && (
                        <span className={clsx(classes.flex, classes.column)}>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.marginBottom05,
                              classes.marginTop1
                            )}>
                            {[
                              MODAL_FIRST_NAME(modalData),
                              MODAL_LAST_NAME(modalData),
                              innerPageIdx === 0
                                ? [
                                    modalData.age,
                                    modalData.gender,
                                    modalData.weight &&
                                      `${modalData.weight}${t('kg')}`,
                                    !!+tournamentData?.height_needed &&
                                      modalData.height &&
                                      `${modalData.height}${t('cm')}`,
                                    !!+tournamentData?.show_reg_qdan &&
                                      modalData.qdan_name &&
                                      modalData.qdan_name
                                  ]
                                    .filter(Boolean)
                                    .join(', ')
                                : ''
                            ].join(' ')}
                          </span>
                        </span>
                      )
                }
                discardOrKeepEditing={this.discardOrKeepEditing}>
                {modalContent}
              </Modal>
              {invitationInfo.tournament_id && (
                <PageGuide
                  restart={shouldShowTour}
                  steps={TOUR_STEPS(
                    invitationInfo.api_token,
                    participantCategories && participantCategories.length === 0
                  )}
                />
              )}
              <QuickTournReg
                showModal={this.showModal}
                onSwitchToggleAssigned={this.onSwitchToggleAssigned}
                selectArchive={this.selectArchive}
                onFilterRecordsBySelectedCategory={
                  this.onFilterRecordsBySelectedCategory
                }
                onSort={this.onSort}
                resetFilters={this.resetFilters}
                {...{ participants }}
                {...{ savedCoachForm }}
                {...{ invitationInfo }}
                {...{ tournamentData }}
                exportJudges={() =>
                  this.exportJudges(
                    invitationInfo.tournament_id || tournamentData.id,
                    { personal: 1 },
                    true
                  )
                }
                searchBar={
                  innerPageIdx === 0
                    ? filters.searchBar
                    : filtersJudges.searchBar
                }
                {...{ collapsed }}
                subItemToggle={this.itemToggle}
                searchText={(e) => this.onChangeSearchTxt(e)}
                clearSearch={() => this.onClearSearchInput()}
                pressEnter={(evt) => evt.key === 'Enter' && this.onSearch()}
                {...{ isLoading }}
                exportParticipants={() =>
                  this.exportParticipants(
                    invitationInfo.tournament_id || tournamentData.id,
                    { personal: 1 },
                    true
                  )
                }
                {...{ viewportWidth }}
                onChangeInnerPage={this.onChangeInnerPage}
                {...{ innerPageIdx, filters, filtersJudges }}
                {...{ allJudges }}
                onToggleJudgeStatus={this.onToggleJudgeStatus}
                restoreParticipant={(_, item) =>
                  this.restoreParticipant(item, () =>
                    this.fetchData(filters.searchBar)
                  )
                }
                sortDirection={
                  innerPageIdx === 0 ? sortDirection : sortDirectionJudges
                }
                onSelectSearchOption={(evt, val) => {
                  this.selectOption(
                    evt,
                    val,
                    'filters',
                    'searchByOption',
                    ID,
                    null,
                    this.onSearch()
                  );
                }}
                {...{ rowsPerPage, page }}
              />
              {innerPageIdx === 0 && participants?.length > 0 && (
                <Pagination
                  count={participantsCount}
                  onPageChange={this.onPageChange}
                  onRowsPerPageChange={(evt) =>
                    this.rowsPerPageHandler(evt, this.onSearch)
                  }
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              )}
            </>
          )}
        </>
      )
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(RegisterToTourn))
);
