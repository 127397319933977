import i18n from '../i18n';
import { getUrlByEnvironment } from './actions';
import parse from 'html-react-parser';

import {
  PROD_URL,
  DEV_URL,
  PROD_API_URL,
  DEV_API_URL,
  PROD_AVATARS_PATH,
  DEV_AVATARS_PATH,
  PROD_API_DEFAULT_PATH,
  DEV_API_DEFAULT_PATH,
  DEV_SCHOOL_PATH,
  PROD_SCHOOL_PATH,
  PROD_FEDERATION_PATH,
  DEV_FEDERATION_PATH,
  PROD_V2_PATH,
  DEV_V2_PATH,
  PROD_LOGS_PATH,
  DEV_LOGS_PATH,
  DEV_RECORDER_PATH,
  PROD_RECORDER_PATH
} from './api-constants';

export const apiUrl = getUrlByEnvironment(PROD_API_URL, DEV_API_URL);
export const serverAvatarsImagesPath = getUrlByEnvironment(
  PROD_AVATARS_PATH,
  DEV_AVATARS_PATH
);
export const serverDefaultPath = getUrlByEnvironment(
  PROD_API_DEFAULT_PATH,
  DEV_API_DEFAULT_PATH
);
export const ENVIORNMENT_SCHOOL_PATH = getUrlByEnvironment(
  PROD_SCHOOL_PATH,
  DEV_SCHOOL_PATH
);
export const API_URL_FEDERATION = getUrlByEnvironment(
  PROD_FEDERATION_PATH,
  DEV_FEDERATION_PATH
);

export const API_URL_RECORDER = getUrlByEnvironment(
  PROD_RECORDER_PATH,
  DEV_RECORDER_PATH
);

export const API_URL_V2 = getUrlByEnvironment(PROD_V2_PATH, DEV_V2_PATH);

export const API_URL_LOGS = getUrlByEnvironment(PROD_LOGS_PATH, DEV_LOGS_PATH);

export const ENV_URL = getUrlByEnvironment(PROD_URL, DEV_URL);
export const LOCALE = (lang) =>
  lang === 'en'
    ? 'en-US'
    : lang === 'de'
    ? 'de-DE'
    : lang === 'ru'
    ? 'ru-RU'
    : 'ro-MD';
export const NAME_REG_EXP = /^[{а-яa-z{[ăîșțâ}А-ЯA-Z{ĂÎȘȚÂ} -_}\s]+$/i;
export const SYMBOLS_REG_EXP = /[!$%^&*@[+|~=`{}\]";'<>?_/]/g;

export const TOURNAMENT_NAME_REG_EXP = /[!$%^&*@[+|~={}\]?_/]/g;

export const PASSWORD_REG_EXP =
  /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/;
export const EMAIL_REG_EXP =
  /^[a-zA-Z0-9-!$%^&*#()[_+|~=`{}.]+@[a-zA-Z0-9-!$%^&*#()[_+|~=`{}]+\.[A-Za-z-!$%^&*#()[_+|~=`{}]+$/;
export const USERNAME_REG_EXP =
  /(?:[\`\~\!\#$\%\^\&\*\(\)\+\=\[\{\]\}\|\\\'\<\,\>\?\/\"\;\:\n])/;
export const VALIDATE_SINGLE_EMAIL = /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/;
export const VALIDATE_PAST_EMAILS = /[\w\d.+.-]+@[\w\d.-]+.[\w\d.+.-]+/g;
export const NUMBER_REG_EXP = /[0-9]$/;
export const POSITIVE_NUMBERS = /^\d*[.]?\d*$/;
export const MAX_FILE_SIZE = 10485760;
export const MAX_ROWS_COUNT = 10000;
export const CHAR_4 = 4;
export const CHAR_16 = 16;
export const MIN_AGE = 4;
export const MAX_AGE = 100;
export const MIN_WEIGHT = 10;
export const CHARS_LIMIT = 128;
export const MID_PAGINATION_COUNT = 25;
export const TIME_OUT = 30000;
export const MAX_EXPERIENCE = 99;
export const ENABLED = '1';
export const DISABLED = '0';
export const LINK_STYLE = 'style="color: #0000EE;"';
export const DATE_PLACEHOLDER = 'dd/MM/yyyy';
export const DEFAULT_AVATAR = '/images/avatars/avatar.jpg';
export const NEW_DEFAULT_AVATAR = '/images/avatars/avatar_new_design.jpg';
export const DEFAULT_TOURNAMENT = '/images/avatars/icon_tournament.png';
export const DEFAULT_FEDERATION = '/images/avatars/federation_header.png';
export const PARTICIPATION_DISAPPROVED = 'Participation disapproved';
export const USERS = 'users';
export const CATEGORIES = 'categories';
export const OK = 'OK';
export const NUMERO_SIGN = '№';
export const ID = 'id';
export const NAME = 'name';
export const TATAMI_ID = 'tatami_id';
export const CATEGORY_ID = 'category_id';
export const PARTICIPANT_ID = 'participant_id';
export const TOURN_BANNER = 'tournament_banner';
export const COUNTRY_ID = 'country_id';
export const FILE_DATA = 'fileData';
export const DOWNLOAD_FILES = 'download_files';
export const COACH_ID = 'coach_id';
export const GENDER_LABEL = 'gender';

export const CHOOSE_FROM_LIST = 'CHOOSE_FROM_LIST';

export const ADD_NEW_GROUP_CATEGORY = 'ADD_NEW_GROUP_CATEGORY';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';

export const UPDATE_GROUP_CATEGORY = 'UPDATE_GROUP_CATEGORY';

export const UPDATE_GROUP = 'UPDATE_GROUP';

export const ADD_CATEGORY = 'ADD_CATEGORY';

export const TEST_ID_FORM = 'TEST_ID_FORM';

export const TRANSLITERATION_RU_2_LATIN = {
  Ё: 'Yo',
  Й: 'I',
  Ц: 'Ts',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'Sh',
  Щ: 'Sch',
  З: 'Z',
  Х: 'H',
  Ъ: "'",
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: "'",
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'A',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'Zh',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'Ch',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: "'",
  Б: 'B',
  Ю: 'Yu',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: "'",
  б: 'b',
  ю: 'yu'
};

export const MAP = 'MAP';

export const TEST_ID_DOWNLOAD_DOC = 'TEST_ID_DOWNLOAD_DOC';
export const TEST_ID_INFO_BTN = 'TEST_ID_INFO_BTN';
export const OPEN_STREET_MAP_API = 'https://nominatim.openstreetmap.org';
export const OPEN_STREET_MAP_URL =
  'https://www.openstreetmap.org/export/embed.html';

export const AVATAR = 'profile_pic';
export const QDAN = 'qdan';
export const NEXT_QDAN = 'nextQdan';
export const TRAINING = 'training';
export const INTERMIDIATE_SCHOOL_INFO = 'intermidiateSchoolInfo';
export const QDAN_EXAM = 'next_qdan_id';
export const TYPE = 'type';
export const COACH_FORM = 'coachForm';
export const PARTICIPANT_FORM = 'participantForm';
export const ERROR = 'error';
export const ERRORS = 'errors';
export const TRUE = 'true';
export const FALSE = 'false';
export const SUCCESS = 'success';
export const USER_CREATED_SUCCESSFULLY = 'user created successfully';
export const BRACKET = 'bracket';
export const DELETE_ICON = 'deleteIcon';
export const ADD_ICON = 'addIcon';
export const CLEAR_ICON = 'clearIcon';
export const UPDATE_ICON = 'updateIcon';
export const ADD_CATEGORIES = 'addCategories';
export const SUBMIT_APPLICATION = 'submitApplication';
export const SIGN_UP = 'signUp';
export const ADD_RECIPIENTS = 'addRecipients';
export const PREVIEW = 'preview';
export const NETINO_EMAIL = 'netinfo.tournament@gmail.com';
export const REPLACE_ARRAY = ['name', 'code', 'url_site', 'url_site_reg'];
export const VALUES = 'values';
export const FORM = 'form';
export const LABEL = 'label';
export const EN = 'en';
export const RU = 'ru';
export const RO = 'ro';
export const CATEGORY_NAME = 'category_name';
export const CATEGORY_TYPE = 'category_type';

export const DOWNLOAD_DOCUMENTS_OPTIONS = [
  { id: '2', type: 'document', label: i18n.t('document') },
  { id: '3', type: 'certificate', label: i18n.t('certificate') }
];

export const CLEAR_ICON_PERMISSION_MODAL = 'CLEAR_ICON_PERMISSION_MODAL';

export const TEST_ID_COPY = 'TEST_ID_COPY';

export const TEAM_ID = 'team_id';
export const LANG = 'lang';
export const KEEP_EDITING = 'KEEP_EDITING';
export const CLOSE_DISCARD = 'CLOSE_DISCARD';
export const SAVE = 'SAVE';
export const CLOSE = 'CLOSE';
export const NOT_SET = 'NOT_SET';
export const EMAIL = 'Email';
export const CASTER = 'Match Caster';
export const MANAGER = 'Cast Manager';
export const DIPLOMA = 'Diploma Management System';
export const FIVE_MB = 5000000;
export const PHONE_CHARS = /^[\d\-()+. ]+$/;
export const NAME_EXCEPT_SYMBOLS = /[!$%^&*@#()[+|~=`{}\]:";<>?,/]/g;
export const LATIN_CHARS = /^[A-Za-z](?:_?[A-Za-z0-9? -.']+)*$/i;
export const CYRILLIC_CHARS = /^[ЁёА-я-z](?:_?[ЁёА-я-z0-9? -.']+)*$/i;
export const ANDROID_DOWNLOAD =
  'https://play.google.com/store/apps/details?id=md.netinfo.adminapp';
export const iOS_DOWNLOAD =
  'https://apps.apple.com/us/app/tournament-expert/id1562303575';

export const GENDER = [{ name: 'M' }, { name: 'F' }];
export const RATE = [
  { id: 0, name: '0' },
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' }
];
export const KATAS_GRADES = {
  result: ['Place', 'Sum'],
  grades: [
    'FirstGrade',
    'SecondGrade',
    'ThirdGrade',
    'ForthGrade',
    'FifthGrade'
  ]
};

export const DAYS = [{ id: '1' }, { id: '2' }, { id: '3' }];

export const ALL_TEAMS = [
  { team_id: '1' },
  { team_id: '2' },
  { team_id: '3' },
  { team_id: '4' },
  { team_id: '5' },
  { team_id: '6' },
  { team_id: '7' },
  { team_id: '8' },
  { team_id: '9' }
];

export const PRIZE_PLACES = [
  { label: '2' },
  { label: '3' },
  { label: '4' },
  { label: '8' }
];

export const DB_ROLES = [
  { id: 1, name: i18n.t('administrator') },
  { id: 2, name: i18n.t('master') },
  { id: 3, name: i18n.t('coach') },
  { id: 4, name: i18n.t('recorder') },
  { id: 7, name: i18n.t('operator') }
];

export const DB_STATUSES = [
  { id: 1, name: 'active' },
  { id: 2, name: 'pending' },
  { id: 3, name: 'closed' },
  { id: 4, name: 'banned' },
  { id: 5, name: 'email_pending' },
  { id: 6, name: 'deleted' }
];

export const DB_QUERY_TYPE = [
  { id: 1, name: i18n.t('queryType1') },
  { id: 2, name: i18n.t('queryType2') },
  { id: 3, name: i18n.t('queryType3') }
];

export const FILTERS_CHECKBOXES = (
  categoryTypes,
  ownerCategories,
  tounamentPresence,
  noUnisex,
  reds,
  dropouts,
  role
) => [
  ...(categoryTypes ? [categoryTypes] : []),
  [
    { id: 'M', name: i18n.t('male'), gender: 'M' },
    { id: 'F', name: i18n.t('female'), gender: 'F' },
    ...(!noUnisex ? [{ id: 'U', name: i18n.t('unisex'), gender: 'U' }] : [])
  ],
  ...(ownerCategories
    ? [
        [
          { id: 'DEFAULT', name: i18n.t('systemCategories'), owner: '0' },
          { id: 'CUSTOM', name: i18n.t('customCategories'), owner: '1' }
        ]
      ]
    : []),
  [
    {
      id: 'KIDS',
      name: [i18n.t('kids'), '(4-9)'].join(' '),
      age: [4, 9],
      ageInterval: '4-9'
    },
    {
      id: 'CADETS',
      name: [i18n.t('cadets'), '(10-13)'].join(' '),
      age: [10, 13],
      ageInterval: '10-13'
    },
    {
      id: 'JUNIORS',
      name: [i18n.t('juniors'), '(14-17)'].join(' '),
      age: [14, 17],
      ageInterval: '14-17'
    },
    {
      id: 'ADULTS',
      name: [i18n.t('adults'), '(18+)'].join(' '),
      age: [18, 100],
      ageInterval: '18-100'
    },
    {
      id: 'VETERANS',
      name: [i18n.t('veterans'), '(35+)'].join(' '),
      age: [35, 100],
      ageInterval: '35-100'
    }
  ],
  ...(tounamentPresence
    ? [
        [
          {
            id: 'UNASSIGNED',
            name: i18n.t('unassigned'),
            tournament_presence: '0'
          },
          { id: 'ASSIGNED', name: i18n.t('assigned'), tournament_presence: '1' }
        ]
      ]
    : []),
  ...(reds
    ? [
        [
          {
            id: 'RED',
            name: i18n.t('showRed')
          }
        ]
      ]
    : []),
  ...(dropouts && (role === 'master' || role === 'administrator')
    ? [
        [
          {
            id: 'RETIRED',
            name: i18n.t('showRetired')
          }
        ]
      ]
    : [])
];

export const COACH_MODAL_EDIT_IC = 'COACH_MODAL_EDIT_IC';

export const MODAL_EDIT_IC = 'MODAL_EDIT_IC';

export const MODAL_FILTER_IC = 'MODAL_FILTER_IC';

export const TOURN_INFO_IC = 'TOURN_INFO_IC';

export const SEND_FINAL_IC = 'SEND_FINAL_IC';

export const PERMISSION_PARAMS = [{ label: 'tournament' }];
export const PRODUCT_LANG = [
  { lang: 'en', label: 'EN', labelFull: i18n.t('english') },
  { lang: 'ru', label: 'RU', labelFull: i18n.t('russian') },
  { lang: 'ro', label: 'RO', labelFull: i18n.t('romanian') },
  { lang: 'de', label: 'DE', labelFull: i18n.t('german') }
];
export const TOUR_STEPS = (
  isDojoRegistered,
  addPatricipant,
  canSubmitApplication
) => [
  !isDojoRegistered && {
    target: '#dojo_form',
    content: i18n.t('registerDojo'),
    disableBeacon: true
  }
  // WAITING FOR NEW IDEAS

  // addPatricipant && {
  //     target: '#add_new_button',
  //     content: i18n.t('addPartStep'),
  // },
  // canSubmitApplication && {
  //     target: '#submitApplication',
  //     content: i18n.t('registerTeamStep'),
  // }
];

export const getEventTypes = () => [
  { id: '1', label: i18n.t('regionalEvent') },
  { id: '2', label: i18n.t('internationalEvent') }
  // { id: '3', label: i18n.t('trainingSchool') }
  // This feature is turned off until further notice
];

export const WINDOWS = 'Windows';
export const TOURNAMENT_EXPERT = 'Tournament Expert';
export const CONTACT_NUMBER = '+373 (22) 213-659';
export const CONTACT_EMAIL = 'support@tournament.expert';

export const ALL_SPORT_RANK = '13';

export const ADDITIONAL_INFO = 1;

export const REASSIGN_CATEGORIES_SORT_OPTIONS = [
  { id: 'MFMF', label: i18n.t('MFMF'), tooltip: i18n.t('boyGirl') },
  { id: 'FMFM', label: i18n.t('FMFM'), tooltip: i18n.t('girlBoy') },
  { id: 'MMFF', label: i18n.t('MMFF'), tooltip: i18n.t('boyBoy') },
  { id: 'FFMM', label: i18n.t('FFMM'), tooltip: i18n.t('girlGirl') }
];

export const FONT_TYPES_OPTIONS = [
  { id: 'Times New Roman Cyr' },
  { id: 'Garamond' },
  { id: 'Helvetica' }
];

export const FONT_WEIGHT_OPTIONS = [
  { id: 'bold', name: '700', label: 'B' },
  { id: 'lighter', name: '200', label: 'L' },
  { id: 'normal', name: '400', label: 'N' }
];

export const TEXT_ALIGN_OPTIONS = [
  { id: 'left', label: parse('&#x25C0;') },
  { id: 'center', label: parse('&#x25A1;') },
  { id: 'right', label: parse('&#x25B6;') }
];

export const ADD_ADDITIONAL = 'ADD_ADDITIONAL';
export const ADDITIONAL_FIELD = 'additional_field';
export const TATAMIS_TAB = 'tatamis';
export const CATEGORIES_TAB = 'categories';
export const DISTRIBUTE_PARTICIPANTS_TAB = 'distribute-participants';
export const REASSIGN_CATEGORIES_TAB = 'reassign-categories';
