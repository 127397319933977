import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  exportParticipantList: {
    gridArea: 'export'
  },

  addNewBtn: {
    gridArea: 'addNew'
  },

  filterBtn: {
    gridArea: 'filter'
  },

  filterOptions: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,minmax(6em, 15em))',
    gap: '1rem'
  },

  submitApplication: {
    gridArea: 'submit'
  },

  centerVertically: {
    alignItems: 'center'
  },

  searchFilter:{
    '& .MuiAutocomplete-inputRoot': {
      maxWidth: 'fit-content',
    },
    '& .MuiAutocomplete-input': {
      width: 'inherit',
    },
  },

  radiusIc: {
    borderRadius: '.2rem',
    border: '1px solid #98A5BA'
  },

  checkBoxBorder: {
    marginLeft: 0,
    marginRight: 0,
    padding: '4px',
    border: '1px solid #8996AC',
    borderRadius: '0.3rem'
  },

  filter: {
    position: 'absolute',
    top: '2.7rem',
    left: '5rem'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  fullWidth: {
    width: '100%'
  },

  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  scrollToTopBtn: {
    display: 'none',
    bottom: '3.5rem',
    right: '30px',
    zIndex: 10,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 29, 61, 0.5)',
    width: '3em',
    height: '3em',
    position: 'fixed',
    padding: '5px',
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  searchIpnut: {
    gridArea: 'search',
    height: '3em',
    padding: '0.2em 0.5rem',
    color: '#8996AC',
    border: '1px solid #8996AC',
    borderRadius: '.5rem',
    margin: 0,
    fontSize: 'unset',
    boxShadow: 'none'
  },

  categoryFilter: {
    gridArea: 'category',
    '&&[class*="MuiOutlinedInput-root"] $input': {
      width: '10em',
      margin: '.5em 0',
      padding: 0
    }
  },

  buttonsBorder: {
    border: '1px solid #98A5BA',
    borderRadius: '0.2rem'
  },

  buttonsSize: {
    width: '1.5em',
    height: '1.5em'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },

  numOfCategories: {
    color: '#fff',
    backgroundColor: '#005FEE',
    borderRadius: '0.3rem',
    marginBottom: '0.2rem',
    width: '1.5rem',
    height: '1.5rem',
    textAlign: 'center',
    fontWeight: 500
  },

  clearIc: {
    backgroundColor: '#FFFFFF',
    color: '#8996AC',
    borderRadius: '2px'
  },

  center: {
    textAlign: 'center'
  },

  flexGrow1: {
    flexGrow: 1
  },

  marginLeft04: {
    marginLeft: '0.4rem'
  },

  activFilterWrapper: {
    minHeight: '3em',
    height: '100%',
    padding: '0.5em',
    backgroundColor: '#8996AC',
    borderRadius: '0.3rem',
    color: '#FFF',
    marginBottom: '1rem',
    fontWeight: 600,
    alignItems: 'center',
    '&:hover': {
      color: '#262441'
    },
    '& .MuiButton-label ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  pointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  marginLeft10: {
    marginLeft: '10px'
  },

  selected: {
    backgroundColor: '#8996AC',
    border: 'none',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },

  relative: {
    position: 'relative'
  },

  absoluteRight: {
    position: 'absolute',
    right: 0
  },

  textColor: {
    color: '#2D63EE',
    border: '1px solid #2D63EE'
  }
}));

export const useStylesActionComponent = makeStyles((theme) => ({
  gridAction: (props) => {
    const hasTabs = props.some((it) => it.val.includes('. .'));
    const hasAddNew = props.some((it) => it.val.includes('addNew'));
    const hasSubmit = props.some((it) => it.val.includes('submit'));
    const hasCategory = props.some((it) => it.val.includes('category'));
    let templateAreasTablet = '';

    // Tabs, AddNew, Category and Submit positions are dynamic
    if (hasTabs && hasAddNew && hasSubmit && hasCategory) {
      templateAreasTablet =
        "'. . addNew' 'export filter submit' 'category category category' 'search search search'";
    } else if (!hasTabs && hasAddNew && hasSubmit && hasCategory) {
      templateAreasTablet = "'addNew search category' 'export filter submit'";
    } else if (hasTabs && hasAddNew && !hasSubmit && hasCategory) {
      templateAreasTablet =
        "'. .' 'search search' 'addNew category' 'filter export'";
    } else if (hasTabs && hasAddNew && !hasSubmit && !hasCategory) {
      templateAreasTablet = "'. . addNew' 'search filter export'";
    } else if (hasTabs && !hasAddNew && !hasSubmit && hasCategory) {
      templateAreasTablet =
        "'. .' 'search search' 'category category' 'filter export'";
    } else if (hasTabs && !hasAddNew && hasSubmit && hasCategory) {
      templateAreasTablet =
        "'. .' 'search search' 'category category' 'export filter submit'";
    } else if (!hasTabs && !hasAddNew && !hasSubmit && hasCategory) {
      templateAreasTablet = "'search category' 'export filter'";
    } else if (!hasTabs && hasAddNew && !hasSubmit && hasCategory) {
      templateAreasTablet = "'addNew search category' 'export filter'";
    } else if (!hasTabs && hasAddNew && hasSubmit && !hasCategory) {
      templateAreasTablet = "'addNew' 'export filter submit'";
    } else if (hasTabs && !hasAddNew && hasSubmit && !hasCategory) {
      templateAreasTablet = "'. .' 'search search' 'export filter submit'";
    } else {
      templateAreasTablet = "'. .' 'search search' 'export filter'";
    }

    return {
      display: 'grid',
      gridColumnGap: '1rem',
      '@media (min-width:1024px)': {
        gridTemplateAreas: "'" + props?.map((it) => it.val).join(' ') + "'",
        gridTemplateColumns: '' + props?.map((it) => it.size).join(' ') + ''
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridRowGap: '1rem',
        gridTemplateAreas: templateAreasTablet
      },
      '@media (max-width:767px)': {
        gridRowGap: '1rem',
        gridTemplateAreas: [
          "'search search'",
          ...(hasCategory ? ["'category category'"] : []),
          ...(hasTabs ? ["'. .'"] : []),
          ...(hasAddNew ? ["'addNew addNew'"] : []),
          "'export export'",
          ...(hasSubmit ? ["'submit submit'"] : []),
          "'filter filter'"
        ].join('')
      }
    };
  }
}));
