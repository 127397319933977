import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import { ReactComponent as DropdownUp } from 'assets/img/dropdown_black&white_bg.svg';
import { ReactComponent as InfoMark } from 'assets/img/info_mark.svg';

import { Tooltip } from '@material-ui/core';

import { serverDefaultPath } from '../../helpers/constants';
import {
  fetchCountries,
  textChangeHandler,
  selectOption,
  usersRegisterNewUser,
  changeModal,
  fetchTournaments,
  changeImage,
  onBlur,
  validateForm,
  toggleUserStatus,
  fetchParticipationCandidates,
  fetchParticipantsByTournamentV2,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchQdan,
  changeHead,
  exportParticipationCandidatesReport,
  downloadFile,
  fetchReferenceRegion,
  fetchReference,
  changeDate
} from '../../helpers/util';
import {
  finishedTournament,
  isSchool,
  findSortDirection,
  compareValueForSorting
} from '../../helpers/selectors';

import { AuthContext } from 'AuthContext';
import { SearchInput } from 'components';

import ListItems from '../../components/Table/ListItems';
import SideModal from '../../components/Snackbar/SideModal';
import CoachForm from '../../components/QuickTournRegistration/CoachForm/CoachForm';
import Table from '../../components/Table/CardsList';
import LoadingState from '../../components/LoadingState/LoadingState';
import Pagination from '../../components/TablePagination/TablePagination';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import EmptyState from '../../components/EmptyState/EmptyState';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Button from 'components/Buttons/ActionButtons';
import Modal from '../../components/Modal/ModalNewDesign';

import styles from './styles';

const defaultForm = {
  first_name: '',
  last_name: '',
  email: '',
  country_id: ''
};

const TABLE_BODY_PARAMS = (value, tournamentData, isTraining) => [
  {
    firstName: value?.firstName,
    lastName: value?.lastName,
    ...(!!+tournamentData?.show_reg_patronymic
      ? { patronymic: 'patronymic' }
      : {}),
    photo: 'photo'
  },
  'email',
  ...(!+tournamentData?.no_country ? ['iso3'] : []),
  ...(!!+tournamentData?.show_reg_region ? ['region'] : []),
  ...(!!+tournamentData?.show_reg_city ? ['city'] : []),
  ...(!!+tournamentData?.show_reg_club ? ['club'] : []),
  { dropdown: 'participants_count' },
  !isTraining ? 'categories_count' : 'examine_count'
];

class AddCoach extends Component {
  state = {
    countries: [],
    allRegions: [],
    tournamentId: this.props.match.params.id,
    modalData: {},
    errors: {},
    statistics: {},
    filteredCoaches: [],
    showModal: false,
    modalInfo: '',
    success: false,
    open: false,
    showError: {},
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    tournamentData: {},
    users: [],
    collapsed: [],
    collapsedList: {},
    sortDirection: [],
    usersCount: 0,
    rowsPerPage: 25,
    page: 1,
    qdanList: [],
    filters: { showNotAccepted: true }
  };

  fetchCountries = fetchCountries.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  selectOption = selectOption.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  changeImage = changeImage.bind(this);
  onBlur = onBlur.bind(this);
  validateForm = validateForm.bind(this);
  toggleUserStatus = toggleUserStatus.bind(this);
  fetchParticipationCandidates = fetchParticipationCandidates.bind(this);
  fetchParticipantsByTournamentV2 = fetchParticipantsByTournamentV2.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  fetchQdan = fetchQdan.bind(this);
  changeHead = changeHead.bind(this);
  exportParticipationCandidatesReport =
    exportParticipationCandidatesReport.bind(this);
  downloadFile = downloadFile.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  fetchReference = fetchReference.bind(this);
  changeDate = changeDate.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, sortDirection, shouldUpdateTable, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(() => {
        this.setState({ shouldUpdateTable: false });

        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('approveRegistration'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing =
        finishedTournament(tournamentData) || !!+tournamentData?.finished;
      const isTraining = isSchool(tournamentData);

      this.fetchData(() => {
        const tableBody = TABLE_BODY_PARAMS({}, tournamentData, isTraining);
        const findIdx = tableBody.findIndex(
          (it) => it.dropdown === 'participants_count'
        );

        this.onSort(7, findIdx);
      });

      this.setState({ shouldDisableEditing });

      changeHead(tournamentData, t('approveRegistration'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'coaches_requests'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  fetchData = (cb) => {
    const { tournamentId } = this.state;

    this.fetchParticipationCandidates(tournamentId, () => {
      const data = this.state.users;
      const usersCount = data?.length;
      let statistics = {
        totalRequests: usersCount,
        totalAcceptedRequests: 0,
        approvedParticipants: 0,
        unapprovedParticipants: 0
      };

      data?.map((it) => {
        if (!!+it.application_status) {
          statistics.totalAcceptedRequests += 1;
          statistics.approvedParticipants += +it.participants_count;
        } else {
          statistics.unapprovedParticipants += +it.participants_count;
        }
      });

      this.setState(
        {
          statistics,
          filteredCoaches: data,
          usersCount,
          loading: false
        },
        () => cb && cb()
      );
    });
  };

  onSearch = (evt) => {
    const { value } = evt?.target;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          searchBar: value
        },
        page: 1
      }),
      () => this.onFilterRecords()
    );
  };

  onFilterRecords = () => {
    const { users, filters } = this.state;
    const len = users?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = users[i];
      const bySearch = filters.searchBar
        ? [el.first_name, el.last_name].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const byAssigned = filters.showNotAccepted
        ? !+el?.application_status
        : true;

      if (bySearch && byAssigned) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }
    this.setState({ filteredCoaches: newFilteredRecords }, () => {
      const { filteredCoaches } = this.state;
      const len = filteredCoaches?.length;

      this.setState({ usersCount: len, loading: false });
    });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  onSwitchToggle = (evt, checked) => {
    this.setState(
      (prevState) => ({
        page: 1,
        filters: { ...prevState.filters, showNotAccepted: checked }
      }),
      () => this.onFilterRecords()
    );
  };

  showModalForm = (evt, item, idx) => {
    evt.preventDefault();

    const id = item?.user_id;
    let state = { selectedRow: id, open: true, errors: {} };

    this.fetchCountries();

    if (item?.country_id) {
      this.fetchReferenceRegion(item?.country_id);
    }

    if (id) {
      state = {
        ...state,
        modalData: {
          ...item,
          ...(item.photo
            ? { imagePreview: serverDefaultPath + item.photo }
            : {}),
          phone: item.phone_number,
          currentRecordIdx: idx
        }
      };
    }

    this.setState(state);
  };

  hideModal = () =>
    this.setState({
      open: false,
      selectedRow: null,
      allRegions: [],
      modalData: {}
    });

  closeModalHandler = () => this.setState({ showModal: false });

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { tournamentData, collapsed } = this.state;
    const isTraining = isSchool(tournamentData);
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => it.id === item.user_id
      );

      if (findIndex === -1) {
        this.fetchParticipantsByTournamentV2(
          null,
          null,
          '',
          item.user_id,
          tournamentData && tournamentData.id,
          true,
          (body) => {
            let rowSubTable = {
              id: item.user_id,
              participants: body?.data,
              sortDirection: []
            };

            this.setState((prevState) => ({
              collapsed: [...prevState.collapsed, rowSubTable]
            }));
          },
          null
        );

        //Training camp, find next_qdan_id loopin through all qdans.
        isTraining && this.fetchQdan();
      } else {
        allCollapsedTables.splice(findIndex, 1);
        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { users, sortDirection } = this.state;
    const clone = [...users];

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, sortField, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        users: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  onToggleStatus = (evt, item) => {
    this.toggleUserStatus(evt, item.user_id, () =>
      this.setState({ shouldUpdateTable: true })
    );
  };

  onSortCollapsibleTable = (sortField, idxCell, parent) => {
    const { collapsed } = this.state;
    const clone = [...collapsed];
    const findIdxInArray = clone.findIndex((it) => it.id === parent?.user_id);
    const direction = findSortDirection(
      clone[findIdxInArray].sortDirection,
      sortField
    );
    const sortedData = compareValueForSorting(
      clone[findIdxInArray].participants,
      sortField,
      direction
    );

    clone[findIdxInArray].sortDirection = [direction, idxCell, sortField];
    clone[findIdxInArray].filteredParticipants = sortedData;

    this.setState({ collapsed: clone });
  };

  itemToggle = (key) => {
    this.setState((prevState) => ({
      collapsedList: {
        ...prevState.collapsedList,
        [key]: !this.state.collapsedList[key]
      }
    }));
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onSave = (evt) => {
    const { tournamentData } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          { ...defaultForm },
          form,
          'errors',
          () => {
            this.usersRegisterNewUser(evt, tournamentData?.id, form, () => {
              this.setState({
                modalData: {},
                errors: {},
                imagePreview: null,
                shouldUpdateTable: true,
                loading: true
              });
              this.hideModal();
            });
          },
          () => {
            this.setState({
              showError: { email: true }
            });
          }
        );
      }
    );
  };

  render() {
    const {
      countries,
      allRegions,
      modalData,
      errors,
      filteredCoaches,
      success,
      modalInfo,
      showModal,
      filters,
      open,
      showError,
      shouldDisableEditing,
      loading,
      tournamentData,
      collapsed,
      statistics,
      usersCount,
      page,
      rowsPerPage,
      sortDirection,
      collapsedList
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;
    const isTraining = isSchool(tournamentData);
    const FIRST_NAME = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';
    const LAST_NAME = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';
    const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_first_name'
      : 'coach_last_name';

    const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_last_name'
      : 'coach_first_name';

    const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachFirstName')
      : t('coachLastName');

    const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachLastName')
      : t('coachFirstName');

    const dojoInfo = [
      {
        name: FIRST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('firstname')
          : t('lastname'),
        required: true
      },
      {
        name: LAST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('lastname')
          : t('firstname'),
        required: true
      },
      {
        name: 'patronymic',
        label: t('patronymic')
      },
      {
        name: 'email',
        label: t('email'),
        shouldEditCoach: false,
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        name: 'branch_chief',
        label: t('nameBranchChief')
      },
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL
      },
      {
        country: true,
        isFilter: true
      },
      allRegions?.length > 0
        ? {
            region: true,
            isFilter: true
          }
        : {
            name: 'region',
            label: t('region')
          },
      {
        name: 'city',
        label: t('city')
      },
      {
        name: 'club',
        label: t('cluborFederationName')
      },
      {
        label: t('phone'),
        name: 'phone'
      },
      { label: t('website'), name: 'website' },
      { save: true }
    ];

    const recordDataParams = (item, idx) => {
      return {
        main: {
          label: t('teamRepresentative'),
          hasSort: true,
          sort: 'first_name',
          style: {
            cardMainPart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1
          },
          name: [
            item?.[FIRST_NAME],
            item?.[LAST_NAME],
            ...(!this.tournamentIdParams ||
            !!+tournamentData?.show_reg_patronymic
              ? [item?.patronymic]
              : [])
          ].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          { sort: 'email', value: item?.email, name: t('email') },
          ...(!+tournamentData?.no_country
            ? [{ sort: 'iso3', value: item.iso3, name: t('country') }]
            : []),
          ...(!!+tournamentData?.show_reg_region
            ? [{ sort: 'region', value: item.region, name: t('region') }]
            : []),
          ...(!!+tournamentData?.show_reg_city
            ? [{ sort: 'city', value: item.city, name: t('city') }]
            : []),
          ...(!!+tournamentData?.show_reg_club
            ? [{ sort: 'club', value: item.club, name: t('club') }]
            : []),
          {
            sort: 'participants_count',
            value: (
              <span
                onClick={(evt) => {
                  this.expandTableRow(evt, item);
                }}
                className={clsx(
                  classes.flex,
                  classes.onHover,
                  classes.centerVertically
                )}>
                {item?.participants_count}
                {+item?.participants_count > 0 && (
                  <DropdownUp
                    className={clsx(
                      classes.flex,
                      classes.onHover,
                      classes.marginLeft0dot4,
                      !!collapsed?.find((it) => +it?.id === +item?.user_id) &&
                        classes.rotate180
                    )}
                  />
                )}
              </span>
            ),
            name: t('participants')
          },
          !isTraining
            ? {
                sort: 'categories_count',
                value: item.categories_count,
                name: t('categories')
              }
            : {
                sort: 'examine_count',
                value: item.examine_count,
                name: t('exams')
              }
        ]
      };
    };

    const collapsedRecordDataParams = (item, idx, parent) => {
      return {
        main: {
          label: t('name'),
          hasSort: true,
          sort: !+tournamentData?.last_name_first
            ? 'participant_first_name'
            : 'participant_last_name',
          style: {
            cardMainPart: classes.bgWhite
          },
          rowOrder: {
            value: idx + 1
          },
          name: [
            item?.[FIRST_NAME_PART],
            item?.[LAST_NAME_PART],

            !!+tournamentData?.show_reg_patronymic
              ? [item?.participant_patronymic]
              : []
          ].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          { sort: 'gender', value: item?.gender, name: t('gender') },
          { sort: 'age', value: item?.age, name: t('age') },
          { sort: 'weight', value: item.weight, name: t('weight') },
          ...(!!+tournamentData?.height_needed
            ? [{ value: item?.height, name: t('height') }]
            : []),
          ...(!!+tournamentData?.show_reg_qdan
            ? [{ sort: 'qdan_name', value: item.qdan_name, name: t('kyuDan') }]
            : []),
          ...(!!+tournamentData?.sport_rank_needed
            ? [{ value: item.sport_rank_short, name: t('sportsCategory') }]
            : []),
          ...(isTraining
            ? [
                {
                  sort: 'next_qdan_id',
                  value: item?.next_qdan_id,
                  name: t('nextKyuDan')
                }
              ]
            : []),
          ...(!isTraining
            ? [
                {
                  value:
                    item?.categories_info?.length > 0 ? (
                      <ListItems
                        collapsed={collapsedList}
                        elem={item}
                        elemParamN="categories_info"
                        itemParamN="category_name"
                        subElemParamN="category_id"
                        paramElemName="participant_id"
                        shouldAllowEditing={false}
                      />
                    ) : (
                      '-'
                    ),
                  list: true,
                  name: (
                    <>
                      {t('categories')}
                      {item?.categories_info?.length > 2 && (
                        <span
                          className={clsx(
                            classes.marginLeft0dot4,
                            classes.numOfCategories
                          )}>
                          {item?.categories_info?.length}
                        </span>
                      )}
                      {item?.categories_info?.length > 2 && (
                        <DropdownUp
                          className={clsx(
                            classes.flex,
                            classes.onHover,
                            classes.marginLeft0dot4,
                            !!collapsedList[item.participant_id] &&
                              classes.rotate180
                          )}
                          onClick={() => {
                            this.itemToggle(item.participant_id);
                          }}
                        />
                      )}
                    </>
                  )
                }
              ]
            : []),
          ...(!+tournamentData?.no_country
            ? [
                {
                  sort: 'participant_country',
                  value: item.participant_country,
                  name: t('country')
                }
              ]
            : []),
          { value: item.coach_name, name: [t('coach'), t('club')].join('/') }
        ]
      };
    };

    const FIRST_NAME_PART = !+tournamentData?.last_name_first
      ? 'participant_first_name'
      : 'participant_last_name';
    const LAST_NAME_PART = !+tournamentData?.last_name_first
      ? 'participant_last_name'
      : 'participant_first_name';

    const pageHeaderStatistics = {
      title: t('eventStatistics'),
      info: [
        {
          label: [t('total'), t('requests').toLowerCase()].join(' '),
          name: statistics?.totalRequests
        },
        {
          label: [t('total'), t('accesptedRequests')].join(' '),
          name: statistics?.totalAcceptedRequests
        },
        {
          label: t('approvedAthletes'),
          name: statistics?.approvedParticipants
        },
        {
          label: t('awaitingApproval'),
          name: statistics?.unapprovedParticipants
        }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              dialogTitle={
                modalData.user_id
                  ? t('teamRepresentative')
                  : t('addTeamRepresentative')
              }
              onSwitchBetween={
                modalData?.user_id && {
                  prev: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx - 1;

                    if (nextItemIdx >= 0) {
                      const item = filteredCoaches[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  },
                  next: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx + 1;
                    const len = filteredCoaches?.length - 1;

                    if (len >= nextItemIdx) {
                      const item = filteredCoaches[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  }
                }
              }
              dialogContent={
                <CoachForm
                  inputs={dojoInfo}
                  onBlur={this.onBlur}
                  {...{ showError }}
                  changeTxt={this.textChangeHandler}
                  {...{ countries }}
                  saveCoachData={(evt) => this.onSave(evt)}
                  changeImage={this.changeImage}
                  changeDate={(date) =>
                    this.changeDate(date, 'modalData', errors, true)
                  }
                  values={modalData}
                  param={'modalData'}
                  {...{ errors }}
                  selectOption={this.selectOption}
                  preventEvent={modalData.user_id}
                  fetchRegionBySelectedCountry={
                    this.fetchRegionBySelectedCountry
                  }
                  {...{ allRegions }}
                />
              }
            />
            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
              shouldShowBtn
            />
            <span
              className={clsx(
                classes.gridButtons,
                classes.centerVertically,
                classes.marginBottom1,
                !shouldDisableEditing
                  ? classes.viewAddButton
                  : classes.noviewAddButton
              )}>
              <Button
                onClick={() =>
                  this.exportParticipationCandidatesReport(tournamentData.id)
                }
                isSaveBtn={true}
                label={t('saveList')}
                style={{ gridArea: 'saveList' }}
              />
              <CheckboxBtn
                checked={filters.showNotAccepted}
                onChange={(evt, checked) => {
                  this.onSwitchToggle(evt, checked);
                }}
                label={t('showNotAccepted')}
                style={{ gridArea: 'checkboxNotAccepted' }}
                formControlClassName={classes.marginForRootCheckBox}
              />
              <SearchInput
                className={classes.search}
                onChange={this.onSearch}
                clearSearch={this.clearSearch}
                value={filters.searchBar}
                isSearchInactive={!filters.searchBar}
                style={{ gridArea: 'searchInput' }}
              />
              {!shouldDisableEditing && (
                <Button
                  id="add_new_button"
                  onClick={(evt) => this.showModalForm(evt)}
                  isSaveBtn={true}
                  label={
                    <>
                      <span className={classes.plus}>&#x002B;</span>
                      {t('addNew')}
                    </>
                  }
                  style={{ gridArea: 'add' }}
                />
              )}
            </span>
            {usersCount != 0 ? (
              <>
                <Table
                  data={filteredCoaches.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )}
                  {...{
                    recordDataParams,
                    collapsedRecordDataParams,
                    collapsed
                  }}
                  // {...{ qdanList }}
                  // shouldDisableEditing
                  id="user_id"
                  subTableDataKey="participants"
                  // selected={(item) => selectedRow === item.user_id}
                  onSort={this.onSort}
                  onSortCollapsible={this.onSortCollapsibleTable}
                  {...{ sortDirection }}
                  // shouldAllowEditing={() => true}
                  actionIcons={(user, idx, className) => (
                    <>
                      <Tooltip
                        arrow
                        title={t(
                          user.application_status === '1'
                            ? 'rejectRequest'
                            : 'acceptRequest'
                        )}>
                        <span>
                          <CheckboxBtn
                            checked={!!+user.application_status}
                            styles={{ icon: classes.checkboxSize }}
                            className={clsx(classes.padding0)}
                            formControlClassName={classes.margin0}
                            onChange={(evt) => this.onToggleStatus(evt, user)}
                            disabled={shouldDisableEditing}
                          />
                        </span>
                      </Tooltip>
                      <InfoMark
                        className={clsx(className, classes.marginLeft1)}
                        onClick={(evt) => this.showModalForm(evt, user, idx)}
                      />
                    </>
                  )}
                />

                <Pagination
                  count={usersCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(AddCoach)));
