import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import AutorenewIcon from '@material-ui/icons/Autorenew';

import { ReactComponent as ArrowIc } from 'assets/img/arrow_right_white_bg&grey_border.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as CloseFlagIcon } from '../../assets/img/close_flag.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';

import {
  USERS,
  DELETE_ICON,
  DB_ROLES,
  DB_STATUSES,
  DB_QUERY_TYPE,
  ID,
  serverDefaultPath,
  NAME
} from '../../helpers/constants';
import {
  pageChangeHandler,
  rowsPerPageHandler,
  selectOption,
  onLogout,
  signIn,
  fetchUsersAsAdmin,
  deleteTableRow,
  textChangeHandler,
  fetchReferenceRegion,
  changeDate,
  fetchUsers,
  updateUserDetails,
  validateForm,
  fetchCountries,
  fetchUserRequests,
  changeImage,
  changeModal,
  setPasswordUser
} from '../../helpers/util';
import { changeTitle } from 'helpers/actions';
import { selectedValue, findSortDirection, DDMMYYYY } from 'helpers/selectors';

import { AuthContext } from '../../AuthContext';

import Pagination from '../../components/TablePagination/TablePagination';
import SideModal from '../../components/Snackbar/SideModal';
import Modal from '../../components/Modal/ModalNewDesign';
import Table from '../../components/Table/CardsList';
import Toolbar from '../../components/Table/Toolbar/Toolbar';
import LoadingState from '../../components/LoadingState/LoadingState';
import UserFrom from '../../components/AccountDetails/AccountDetailsForm';

import styles from './Styles';

class Users extends Component {
  constructor(props) {
    super(props);
    this.searchParam = new URLSearchParams(props.location.search)?.get(
      'search'
    );

    this.state = {
      users: [],
      open: false,
      showModal: false,
      success: true,
      filteredUsers: [],
      loading: true,
      filters: {
        statusID: 1,
        searchedValue: this.searchParam,
        searchBar: this.searchParam
      },
      requestsList: [],
      modalData: {},
      countries: [],
      allRegions: [],
      errors: {},
      pressedBtn: null,
      sortDirection: [],
      page: 1,
      rowsPerPage:
        (localStorage.getItem('rowsPerPage') &&
          +localStorage.getItem('rowsPerPage')) ||
        25,
      langOnLoad: localStorage.getItem('i18nextLng')
    };

    this.pageChangeHandler = pageChangeHandler.bind(this);
    this.rowsPerPageHandler = rowsPerPageHandler.bind(this);
    this.selectOption = selectOption.bind(this);
    this.onLogout = onLogout.bind(this);
    this.signIn = signIn.bind(this);
    this.fetchUsersAsAdmin = fetchUsersAsAdmin.bind(this);
    this.deleteTableRow = deleteTableRow.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.fetchReferenceRegion = fetchReferenceRegion.bind(this);
    this.changeDate = changeDate.bind(this);
    this.fetchCountries = fetchCountries.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.updateUserDetails = updateUserDetails.bind(this);
    this.validateForm = validateForm.bind(this);
    this.fetchUserRequests = fetchUserRequests.bind(this);
    this.changeImage = changeImage.bind(this);
    this.changeModal = changeModal.bind(this);
    this.setPasswordUser = setPasswordUser.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeTitle(t('users'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    this.fetchData();
    this.fetchCountries();

    changeTitle(this.props.t('users'));
  }

  fetchData = () => {
    const { filters, page, rowsPerPage, sortDirection } = this.state;

    this.fetchUsersAsAdmin(
      filters,
      page,
      rowsPerPage,
      (data) => {
        const recordCount = data?.length ? 10000 : 0;
        this.setState({
          loading: false,
          users: data,
          filteredUsers: data,
          recordCount
        });
      },
      sortDirection
    );
  };

  triggerTableUpdate = () => {
    this.fetchData();
    this.hideModal();
  };

  newPassword = (user_id, password) => {
    this.setPasswordUser(user_id, password);
  };

  showModal = (evt, item, idx, targetId) => {
    evt.preventDefault();
    if (item?.country) {
      this.fetchReferenceRegion(item?.country);
    }

    this.fetchUsers(item.id, 'modalData', (user) => {
      const modalData = {
        ...user,
        ...(user.photo ? { imagePreview: serverDefaultPath + user.photo } : {}),
        coach_first_name: user.coach_first_name ?? '',
        coach_last_name: user.coach_last_name ?? '',
        region: user.region ?? '',
        currentRecordIdx: idx
      };
      this.setState({ modalData, pressedBtn: targetId, open: true });
    });
  };

  hideModal = () => {
    this.setState({ modalData: {}, pressedBtn: null, open: false });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  editUserDetails = (evt) => {
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const { modalData } = this.state;
        const form = { ...modalData };

        this.validateForm(
          evt,
          {
            first_name: '',
            last_name: ''
          },
          form,
          'errors',
          () => {
            this.updateUserDetails(evt, modalData, () => {
              this.triggerTableUpdate();
            });
          }
        );
      }
    );
  };

  onPageChange = (e, next) => {
    const { isSearching } = this.state;

    this.pageChangeHandler(e, next, () => {
      if (isSearching) {
        this.searchHandler();
      } else {
        this.fetchData();
      }
    });
  };

  searchHandler = () => {
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          searchedValue: this.state.filters?.searchBar
        },
        page: 1
      }),
      () => {
        this.fetchData();
      }
    );
  };

  handleTextChange = (evt) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, searchBar: evt.target.value }
    }));
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.searchHandler()
    );
  };

  pressEnterHandler = (evt) => {
    if (evt.key === 'Enter') {
      this.searchHandler();
    }
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  onSort = (sortField, idxCell) => {
    const { sortDirection } = this.state;
    const defaultDirection = findSortDirection(sortDirection, sortField);
    const direction = defaultDirection === 2 ? 0 : defaultDirection;

    this.setState(
      { sortDirection: [direction, idxCell, sortField], page: 1 },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const {
      success,
      open,
      showModal,
      modalInfo,
      filters,
      modalData,
      errors,
      pressedBtn,
      filteredUsers,
      allRegions,
      countries,
      users,
      page,
      rowsPerPage,
      recordCount,
      sortDirection,
      loading
    } = this.state;
    const { t, classes } = this.props;
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';
    const FIRST_NAME = !isRuLang ? 'first_name' : 'last_name';
    const LAST_NAME = !isRuLang ? 'last_name' : 'first_name';

    const recordDataParams = (item, idx) => {
      const updateDate = item?.update_date?.split(' ');

      return {
        main: {
          label: t('name'),
          hasSort: true,
          sort: 'full_name',
          style: {
            wrapper: classes.marginTop1,
            cardMainPart: classes.cardMainPart,
            cardValuePart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1
          },
          name: [item?.[FIRST_NAME], item?.[LAST_NAME]].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          {
            value: item?.id,
            name: 'ID'
          },
          {
            sort: 'status_name',
            value: item?.status_name,
            name: t('status')
          },
          { sort: 'role_name', value: item?.role_name, name: t('role') },
          { value: item?.language_id, name: <CloseFlagIcon /> },
          {
            sort: 'username',
            value: item.username?.length > 0 ? item.username : '-',
            name: t('username')
          },
          { sort: 'email', value: item?.email, name: t('email') },
          { value: item?.gender, name: t('gender') },
          {
            sort: 'country_name',
            value: item?.country_iso3,
            name: t('countries')
          },
          { sort: 'city', value: item?.city, name: t('city') },
          { sort: 'region', value: item?.region, name: t('region') },
          { sort: 'club', value: item?.club, name: t('club') },
          { sort: 'phone_number', value: item?.phone_number, name: t('phone') },
          { value: item?.requests, name: t('requests') },
          {
            sort: 'update_date',
            value: updateDate && `${DDMMYYYY(updateDate[0])} ${updateDate[1]}`,
            name: <AutorenewIcon />
          }
        ]
      };
    };

    const ALL_FILTERS = [
      {
        options: DB_STATUSES,
        onChange: (e, val) =>
          this.selectOption(
            e,
            val,
            'filters',
            'statusID',
            ID,
            null,
            this.fetchData
          ),
        label: t('status'),
        item: NAME,
        value: selectedValue(DB_STATUSES, ID, filters.statusID, true)
      },
      {
        options: DB_ROLES,
        onChange: (e, val) =>
          this.selectOption(
            e,
            val,
            'filters',
            'roleID',
            ID,
            null,
            this.fetchData
          ),
        label: t('role'),
        item: NAME,
        value: selectedValue(DB_ROLES, ID, filters.roleID, true)
      },
      {
        options: DB_QUERY_TYPE,
        onChange: (e, val) =>
          this.selectOption(
            e,
            val,
            'filters',
            'queryTypeID',
            ID,
            null,
            this.fetchData
          ),
        label: 'Type',
        item: NAME,
        value: selectedValue(DB_QUERY_TYPE, ID, filters.queryTypeID, true)
      }
    ];

    let dialogTitle, onSwitchBetween, dialogContent, onClick, buttonPurpose;

    if (pressedBtn === DELETE_ICON) {
      dialogTitle = t('deleteUser');
      dialogContent = t('deleteUserMsg');
      onClick = () => {
        this.deleteTableRow(USERS, modalData?.id, this.triggerTableUpdate);
      };
      buttonPurpose = t('deleteRecord', { name: '' });
    } else {
      dialogTitle = t('updateRecord', { name: t('user') });
      onSwitchBetween = {
        prev: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx - 1;

          if (nextItemIdx >= 0) {
            const item = filteredUsers[nextItemIdx];

            this.showModal(evt, item, nextItemIdx, 'update');
          }
        },
        next: (evt) => {
          const nextItemIdx = modalData.currentRecordIdx + 1;
          const len = filteredUsers?.length - 1;

          if (len >= nextItemIdx) {
            const item = filteredUsers[nextItemIdx];

            this.showModal(evt, item, nextItemIdx, 'update');
          }
        }
      };

      dialogContent = (
        <UserFrom
          fetchTournamnetInformation={this.fetchUserRequests}
          updateUser={(evt) => this.editUserDetails(evt)}
          values={modalData}
          handleChange={(evt) =>
            this.textChangeHandler(evt, 'modalData', errors)
          }
          dateChange={(date) => this.changeDate(date, 'modalData', errors)}
          {...{ errors, countries, allRegions }}
          selectOption={this.selectOption}
          formName={'modalData'}
          fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
          onChangeImg={this.changeImage}
          specificDetails={{
            requestsList: true
          }}
          newPassword={this.newPassword}
        />
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              {...{
                dialogTitle,
                onSwitchBetween,
                dialogContent,
                onClick,
                buttonPurpose
              }}
            />
            <Toolbar
              filter={[...ALL_FILTERS]}
              search={filters.searchBar}
              pressEnter={this.pressEnterHandler}
              clearSearch={this.clearSearch}
              textChange={this.handleTextChange}
            />
            <Table
              {...{ recordDataParams }}
              data={filteredUsers}
              // onClick={(evt, item, idx) => this.showModal(evt, item, idx)}
              // selected={(item) => +modalData?.id === +item?.id}
              onSort={this.onSort}
              {...{ sortDirection }}
              // shouldAllowEditing={() => true}
              actionIcons={(item, idx, className) => (
                <>
                  <EditIcon
                    className={clsx(className)}
                    onClick={(evt) => this.showModal(evt, item, idx, 'update')}
                  />
                  {item.id !== localStorage.getItem('user_id') && (
                    <DeleteIcon
                      id={DELETE_ICON}
                      className={clsx(className, classes.marginLeft1)}
                      onClick={(evt) =>
                        this.showModal(evt, item, null, DELETE_ICON)
                      }
                    />
                  )}
                  {+item?.status_id === 1 && (
                    <ArrowIc
                      className={clsx(className, classes.marginLeft1)}
                      onClick={(evt) => {
                        const token = localStorage.getItem('token');

                        this.onLogout(this.context, () => {
                          localStorage.setItem('token', token);

                          this.signIn(
                            evt,
                            { values: { user_id: item?.id } },
                            (key) => {
                              this.context.changeAuthState(key);
                            }
                          );
                        });
                      }}
                    />
                  )}
                </>
              )}
            />
            {users?.length > 0 && (
              <Pagination
                count={recordCount}
                onPageChange={this.onPageChange}
                onRowsPerPageChange={(evt) =>
                  this.rowsPerPageHandler(evt, this.searchHandler)
                }
                {...{ page }}
                {...{ rowsPerPage }}
              />
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Users)));
