import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  gridButtons: {
    display: 'grid',
    marginTop: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput searchInput exportButton filterButton'
      `,
      gridGap: '1%'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateAreas: `
        'searchInput searchInput'
        'exportButton filterButton'
      `,
      gridGap: '1em'
    },
    '@media (max-width: 767px)': {
      gridTemplateAreas: `
        'searchInput searchInput'
        'exportButton filterButton'
      `,
      gridGap: '1em'
    }
  },

  margin0: {
    margin: 0
  },

  gap: {
    gridGap: '1%'
  },

  column: {
    flexDirection: 'column'
  },

  paper: {
    backgroundColor: '#FFF',
    borderRadius: '4px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  kumiteWrapper: {
    margin: '0 -10px',
    flexWrap: 'wrap',
    alignItems: 'end'
  },

  kumiteBtns: {
    margin: '20px 6.5px 0 6.5px'
  },

  paddingTop1: {
    paddingTop: '1rem'
  },

  buttons: {
    borderRadius: '10px',
    width: '120px',
    height: '70px',
    '@media (max-width: 767px)': {
      width: '92px'
    }
  },
  marginLeft: {
    marginLeft: '20px',
    '@media (max-width:900px)': {
      marginLeft: '10px'
    }
  },

  text: {
    fontSize: '18px',
    fontWeight: 700
  },

  checkboxIcon: {
    padding: 0,
    marginRight: '10px'
  },
  checkboxItem: {
    margin: 0
  },
  wrap: {
    flexWrap: 'wrap'
  },
  marginRight: {
    marginRight: '20px'
  },

  notSelected: {
    backgroundColor: '#CED4E0',
    border: 'none',
    color: '#262441',
    fontWeight: 600,
    fontSize: '16px'
  },

  selected: {
    backgroundColor: '#8996AC',
    border: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },

  tatamiText: {
    fontSize: '16px',
    fontWeight: 600
  },

  noEvent: {
    pointerEvents: 'none'
  },

  verticallyCentered: {
    alignItems: 'center'
  },

  condensedViewMaxBtn: {
    marginLeft: '-10px'
  },

  kataNamesMargin05: {
    margin: '0 0.2rem'
  },

  kataCustomNameMarginLeftRight: {
    margin: '0 0.5rem'
  },

  fontSize09: {
    fontSize: '0.9rem'
  },

  search: {
    '@media (max-width: 1024px)': {
      marginBottom: 0
    },
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
    height: '3rem'
  },

  relative: {
    position: 'relative'
  },

  time: {
    border: '1px solid #8996AC',
    borderRadius: '7px',
    textAlign: 'center',
    height: '36px',
    width: '120px',
    justifyContent: 'center',
    fontSize: '16px',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      width: '56px',
      height: '50px'
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: 0
    }
  },

  timeInputWrapper: {
    margin: '0.2rem 1rem 0 0',
    marginBottom: 0,
    textAlign: 'center',
    '@media (max-width: 767px)': {
      margin: '0.2rem 0.45rem 0 0'
    }
  },

  input: {
    '& .MuiInputBase-input': {
      width: '2.5rem',
      padding: 0
    }
  },

  badge: {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    padding: '0.35em 0.65em',
    fontSize: '.75em',
    fontWeight: 700,
    lineHeight: 1,
    color: '#fff',
    whiteSpace: 'nowrap',
    borderRadius: '6px',
    left: '85%',
    top: 0,
    zIndex: 1
  },

  backgroundBadgeBlue: {
    background: '#005FEE'
  },

  backgroundBadgeRed: {
    background: '#FF0000'
  },

  categoryWrapper: {
    borderRadius: '10px',
    padding: '10px 15px 20px 15px'
  },

  filterWrapper: {
    borderRadius: '5px',
    padding: '12px',
    margin: '0 10px 10px 0'
  },

  marginRightTop05: {
    marginTop: '0.2rem',
    marginRight: '1em',
    '@media (max-width: 767px)': {
      marginRight: '12px'
    }
  },

  flexGrow1: {
    flexGrow: 1
  },

  center: {
    textAlign: 'center'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  fullWidth: {
    width: '100%'
  },

  radiusIc: {
    borderRadius: '.2rem',
    border: '1px solid #8996AC',
    color: '#8996AC',
    backgroundColor: '#E9ECF6',
    width: '30px',
    height: '30px',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  checkboxesWrapper: {
    flexWrap: 'wrap'
  },

  marginRightAuto: {
    marginRight: 'auto'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginLeft05: {
    marginLeft: '0.5rem'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  margin04_1: {
    margin: '0.4em 1em 0 0'
  },

  marginTop05: {
    marginTop: '0.5em'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  padding0: {
    padding: 0
  },

  justifyContentCenter: {
    justifyContent: 'center'
  },

  update: {
    borderRadius: '.2rem',
    border: '1px solid #FFFFFF',
    color: '#FFFFFF',
    backgroundColor: '#8996AC',
    width: '30px',
    height: '30px',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  marginBottom28: {
    marginBottom: '28px'
  }
}));

export { useStyles };
