import i18n from '../i18n';
import moment from 'moment';
import {
  ERROR,
  ENABLED,
  TRANSLITERATION_RU_2_LATIN,
  MIN_WEIGHT,
  POSITIVE_NUMBERS,
  LOCALE
} from './constants';

export const formatDate = (date) =>
  date !== null
    ? date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    : null;

export const convertMs = (ms, exception, total, shouldShwHoursMinSec) => {
  let sec, min, hrs;

  sec = Math.floor(ms / 1000);
  min = Math.floor(sec / 60) % 60;
  hrs = Math.floor(sec / 3600) % 24;

  if (exception || shouldShwHoursMinSec) {
    sec = sec % 60;
    const min_sec = [
      (min < 10 ? '0' : '') + min,
      (sec < 10 ? '0' : '') + sec
    ].join(':');
    const result = shouldShwHoursMinSec
      ? [(hrs < 10 ? '0' : '') + hrs, min_sec].join(':')
      : min_sec;

    return result;
  } else {
    if (total) {
      return [(hrs < 10 ? '0' : '') + hrs, (min < 10 ? '0' : '') + min].join(
        ':'
      );
    } else {
      return Math.floor(sec / 60);
    }
  }
};

export const resizeUploadedFile = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (evt) {
    const img = new Image();
    img.src = evt.target.result;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width * 0.5;
      canvas.height = img.height * 0.5;
      canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);
      const canvasDataURL = canvas.toDataURL(file.type);
      afterLoad(canvasDataURL);
    };
  };
  const afterLoad = (canvasDataURL) => {
    let base64Encoded = canvasDataURL.substring(canvasDataURL.indexOf(',') + 1);
    let binaryString = window.atob(base64Encoded);
    let len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const newFile = new File([bytes], file.name, { type: file.type });
    cb && cb(newFile);
  };
};
export const filterByKey = (data, column, value) => {
  return data.filter((val) => {
    return val[column].toLowerCase().includes(value.trim());
  });
};

export const categoryTypesPresentOnTournament = (types, categories) => {
  let newArray = [];
  const len = Array.isArray(categories) && categories.length;

  for (let i = 0; i < len; i++) {
    const item =
      Array.isArray(types) &&
      types.find((elem) => elem && +elem.id === +categories[i]);

    if (item) {
      newArray = [...newArray, item];
    }
  }

  return newArray;
};

export const countryLabel = (lang) =>
  lang === 'en' || lang === 'de' ? 'name' : `name_${lang}`;

export const invitationTxtLang = (lang) =>
  lang === '1' ? 'here' : lang === '2' ? 'здесь' : 'aici';

export const selectedValue = (array, key, currentValue, int) =>
  Array.isArray(array) &&
  array.find((item) => {
    if (int) {
      return +item[key] === +currentValue;
    } else {
      return item[key] === currentValue;
    }
  });

export const isSmallScreen = (viewportWidth) =>
  viewportWidth && viewportWidth <= 900;
export const isMobileView = (viewportWidth) =>
  viewportWidth && viewportWidth <= 480;
export const isTabletView = (viewportWidth) =>
  viewportWidth && viewportWidth <= 1023 && viewportWidth >= 481;
export const isDesktopView = (viewportWidth) =>
  viewportWidth && viewportWidth >= 1024;

export const isSchool = (tournament) => tournament && +tournament.type === 3;

export const requestStatus = (status, fetchData, body, text200, text40N) => {
  let notification;
  if (status === 200 || status === 207) {
    notification = text200;
    fetchData && fetchData();
  } else if (status >= 400 && status <= 451) {
    // Client Errors
    notification = text40N;
  } else if (body === ERROR || status === 500)
    notification = i18n.t('serverError');
  return notification;
};

export const finishedTournament = (tournamentData) => {
  if (tournamentData) {
    //add 2 extra weeks to the tournament start date
    const finalDate = moment(tournamentData.start_date, 'YYYY-MM-DD').add(
      7 + (+tournamentData?.duration_days ?? 0),
      'days'
    );

    return finalDate.isBefore(moment().format('YYYY-MM-DD'));
  }
};

export const finishedRegistration = (regDate, finish) => {
  if (regDate) {
    return (
      finish === ENABLED ||
      moment(regDate).isBefore(moment().format('YYYY-MM-DD'))
    );
  }
};

export const replaceTemplateFields = (str, replaceArray, replaceWithArray) => {
  if (str) {
    const regex = replaceArray.map((key) => new RegExp(`{${key}}`, 'gi'));
    for (let i = 0; i < replaceArray.length; i++) {
      str = str.replace(regex[i], replaceWithArray[i]);
    }
    return str;
  }
};

export function compareValueForSorting(data, key, order) {
  //Order ASC = 1, DESC = 2
  return data.sort((a, b) => {
    let comparison = 0;

    if (!a[key]) {
      return -1;
    }
    if (!b[key]) {
      return 1;
    }

    if (isNaN(+a[key])) {
      //sort strings
      const firstVal = a[key].toLowerCase();
      const secondVal = b[key].toLowerCase();

      if (firstVal > secondVal) {
        comparison = 1;
      } else if (firstVal < secondVal) {
        comparison = -1;
      }
      return order === 2 ? comparison * -1 : comparison;
    } else {
      //sort numbers
      if (order === 2) {
        return +a[key] - +b[key];
      } else {
        return +b[key] - +a[key];
      }
    }
  });
}

export function findSortDirection(sortData, currentField, noFirstTimeSort) {
  const direction = !noFirstTimeSort
    ? sortData[2] === currentField && sortData[0] === 1
      ? 2
      : 1
    : sortData[0];

  return direction;
}

export function b64DecodeUnicode(str) {
  return decodeURIComponent(
    window
      .atob(str)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

const age = (item) => [item.age_from, item.age_to].join(item.age_to ? '-' : '');

export const setCategoryGender = (age_from, age_to, gender) => {
  if (gender) {
    if (age_from > 0 && age_to <= 11 && age_from <= age_to) {
      return gender === 'M'
        ? i18n.t('male0To11')
        : gender === 'F'
        ? i18n.t('female0To11')
        : [i18n.t('female0To11'), i18n.t('male0To11')].join('/');
    } else if (age_from > 11 && age_to <= 15 && age_from <= age_to) {
      return gender === 'M'
        ? i18n.t('male11To15')
        : gender === 'F'
        ? i18n.t('female11To15')
        : [i18n.t('female11To15'), i18n.t('male11To15')].join('/');
    } else if (age_from > 15 && age_to <= 17 && age_from <= age_to) {
      return gender === 'M'
        ? i18n.t('male16To17')
        : gender === 'F'
        ? i18n.t('female16To17')
        : [i18n.t('female16To17'), i18n.t('male16To17')].join('/');
    } else if (age_from >= 18 && age_to <= 35 && age_from <= age_to) {
      return gender === 'M'
        ? i18n.t('male18plus')
        : gender === 'F'
        ? i18n.t('female18plus')
        : [i18n.t('female18plus'), i18n.t('male18plus')].join('/');
    } else if (age_from > 35) {
      return gender === 'M'
        ? i18n.t('male35plus')
        : gender === 'F'
        ? i18n.t('female35plus')
        : [i18n.t('female35plus'), i18n.t('male35plus')].join('/');
    } else {
      return gender === 'M'
        ? i18n.t('male18plus')
        : gender === 'F'
        ? i18n.t('female18plus')
        : [i18n.t('female18plus'), i18n.t('male18plus')].join('/');
    }
  } else {
    return '';
  }
};

export const setCategoryName = (item, needG_name) => {
  const g_name = `(${item.g_name})`;
  return [
    +item.type === 3
      ? i18n.t('teamKata')
      : +item.type === 1
      ? i18n.t('kata')
      : '',
    setCategoryGender(item.age_from, item.age_to, item.gender),
    item.age_from || item.age_to
      ? [age(item), i18n.t('years')].join(' ')
      : age(item),
    needG_name && g_name
  ].join(' ');
};

export const organizerName = (tournamentData, shouldUseLang) => {
  let firstN, lastN;

  if (!shouldUseLang) {
    firstN = !+tournamentData?.last_name_first
      ? 'organizer_fname'
      : 'organizer_lname';
    lastN = !+tournamentData?.last_name_first
      ? 'organizer_lname'
      : 'organizer_fname';
  } else {
    const langRu = localStorage.getItem('i18nextLng') === 'ru';
    firstN = !langRu ? 'organizer_fname' : 'organizer_lname';
    lastN = !langRu ? 'organizer_lname' : 'organizer_fname';
  }

  return { firstN: tournamentData?.[firstN], lastN: tournamentData?.[lastN] };
};

export const roleName = (role) => {
  switch (role) {
    case 'administrator':
      return i18n.t('administrator');
    case 'master':
      return i18n.t('master');
    case 'recorder':
      return i18n.t('recorder');
    case 'operator':
      return i18n.t('operator');
    case 'coach':
      return i18n.t('registrar');
  }
};

export const generate6DigitsCode = () => {
  const randomNum = Math.floor(Math.random() * 1000000);
  const result = randomNum.toString().padStart(6, '0');

  return result;
};

export const transliterate = (word) => {
  let result = '';

  for (var i = 0; i < word.length; i++) {
    var char = word.charAt(i);

    result += TRANSLITERATION_RU_2_LATIN[char] || char;
  }

  return result;
};

export function onChangeText(evt, currentVal, errors) {
  const { name, value } = evt.target;
  let cpyErrors = { ...errors };

  switch (name) {
    case 'weight':
      if (value.length === 0) {
        cpyErrors.weight = i18n.t('required');
      } else if (parseInt(value) < MIN_WEIGHT) {
        cpyErrors.weight = i18n.t('minimumAllowableWeight');
      } else if (!POSITIVE_NUMBERS.test(value)) {
        cpyErrors.weight = i18n.t('invalidCharacters');
      } else {
        delete cpyErrors.weight;
      }
      break;
    case 'height':
      if (!!+currentVal.height_needed && value.length === 0) {
        cpyErrors.height = i18n.t('required');
      } else if (!POSITIVE_NUMBERS.test(value)) {
        cpyErrors.height = i18n.t('invalidCharacters');
      } else {
        delete cpyErrors.height;
      }
      break;

    default:
      break;
  }

  return { [name]: value, errors: cpyErrors };
}

export function DDMMYYYY(dateString, name) {
  let newDate;

  if (!isNaN(Date.parse(dateString))) {
    const parts = dateString.split('-');

    if (name) {
      const daysOfWeek = [
        i18n.t('sunday'),
        i18n.t('monday'),
        i18n.t('tuesday'),
        i18n.t('wednesday'),
        i18n.t('thursday'),
        i18n.t('friday'),
        i18n.t('saturday')
      ];

      const date = new Date(dateString);
      const dayOfWeek = daysOfWeek[date.getDay()];
      newDate = `${parts[2]}/${parts[1]}/${parts[0]} (${dayOfWeek})`;
    } else {
      newDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    }
  }

  return newDate;
}
