import i18n, { getCurrentLanguage } from '../i18n';
import { v4 as uuidv4 } from 'uuid';
import {
  apiGetRequest,
  noTokenApiGetRequest,
  apiPostRequest,
  noTokenPostRequest,
  changeTitle
} from './actions';
import {
  requestStatus,
  selectedValue,
  resizeUploadedFile,
  b64DecodeUnicode,
  setCategoryGender,
  transliterate
} from './selectors';
import {
  serverDefaultPath,
  API_URL_LOGS,
  ENVIORNMENT_SCHOOL_PATH,
  TRUE,
  FALSE,
  SYMBOLS_REG_EXP,
  USERNAME_REG_EXP,
  TOURNAMENT_NAME_REG_EXP,
  NUMBER_REG_EXP,
  EMAIL_REG_EXP,
  CHAR_4,
  CHAR_16,
  MIN_WEIGHT,
  MIN_AGE,
  MAX_AGE,
  PARTICIPATION_DISAPPROVED,
  USER_CREATED_SUCCESSFULLY,
  ADD_ICON,
  POSITIVE_NUMBERS,
  CHARS_LIMIT,
  ENABLED,
  DISABLED,
  OK,
  ID,
  CYRILLIC_CHARS,
  LATIN_CHARS,
  EN,
  RO,
  RU,
  CATEGORY_ID,
  FIVE_MB,
  NEXT_QDAN,
  TRAINING,
  API_URL_FEDERATION,
  API_URL_V2,
  API_URL_RECORDER,
  PHONE_CHARS,
  ADDITIONAL_FIELD,
  MAX_EXPERIENCE
} from './constants';

export function changeModal(content, status, exception) {
  this.setState({
    showModal: content,
    modalInfo: content,
    success: (status === 200 || status === 207) && !exception
  });
}
export function pageChangeHandler(_, newPage, action) {
  this.setState({ page: newPage + 1 }, () => {
    action && action();
    window.scroll({
      top: document.querySelector('#pagination').offsetTop,
      behavior: 'smooth'
    });
  });
}
export function rowsPerPageHandler(event, action) {
  const rowsPerPage = event.target.value;
  this.setState({ page: 1, rowsPerPage }, () => {
    typeof action === 'function' && action();
    localStorage.setItem('rowsPerPage', rowsPerPage);
    window.scroll({
      top: document.querySelector('#pagination').offsetTop,
      behavior: 'smooth'
    });
  });
}
export function changeImage(evt, values, img, preview) {
  const file = evt.target.files[0];

  const setImgData = (fileData) => {
    this.setState((prevState) => ({
      [values]: {
        ...prevState[values],
        [img]: fileData,
        [preview]: URL.createObjectURL(fileData)
      }
    }));
  };

  if (!file) return;
  else if (file.size > FIVE_MB) {
    // img size more than 5MB
    resizeUploadedFile(file, (newFile) => setImgData(newFile));
  } else {
    setImgData(file);
  }
}

export function selectOption(
  evt,
  value,
  form,
  param,
  item,
  errors,
  fetchData,
  shouldShowDiscardChanges,
  categories,
  isFilterCategoriesChecked,
  gender,
  weight,
  filterParam,
  filterItem,
  isUpdateForm
) {
  let filteredCategories;

  if (value !== null) {
    if (Array.isArray(categories)) {
      filteredCategories = categories.filter((category) =>
        isFilterCategoriesChecked
          ? +category?.category_type_id === +value?.id &&
            filterCategoriesList(category, gender, weight)
          : +category?.category_type_id === +value?.id
      );
    }

    this.setState(
      (prevState) => ({
        [form]: {
          ...prevState[form],
          ...(param ? { [param]: value[item] } : {})
        },
        ...(shouldShowDiscardChanges ? { isEditing: true } : {}),
        ...(filteredCategories ? { filteredCategories } : {})
      }),
      () => {
        fetchData && fetchData(value[item], value);

        const { [form]: formName } = this.state;

        //below is used to autofill category/group form
        if (!isUpdateForm && formName?.group_category_type) {
          const findCategoryType = this.customCategoryType(
            +formName?.group_category_type
          );
          const assembledKumiteName = (sign) =>
            [
              findCategoryType?.name,
              age(
                formName?.group_category_age_from,
                formName?.group_category_age_to
              ),
              setCategoryGender(
                formName?.group_category_age_from,
                formName?.group_category_age_to,
                formName?.group_category_gender
              ),

              +formName.group_category_type === 2 &&
              formName.category_weight_from
                ? [
                    sign.includes('-') ? sign : '',
                    formName.category_weight_from,
                    sign.includes('+') ? sign : '',
                    ' ',
                    findCategoryType.unit
                  ].join('')
                : '',
              +formName.group_category_type === 2 && formName.isAbsoluteChecked
                ? 'Absolute'
                : ''
            ].join(' ');

          this.setState((prevState) => ({
            [form]: {
              ...prevState[form],
              ...(param === 'group_category_type'
                ? { category_type_name: value.name }
                : {}),
              group_category_name: assembledKumiteName('-'),
              category_name_plus: assembledKumiteName('+'),
              group_category_alt_name: assembledKumiteName('-'),
              category_alt_name_plus: assembledKumiteName('+')
            }
          }));
        }
      }
    );

    if (errors) delete errors[param];
  } else {
    this.setState(
      (prevState) => ({
        [form]: {
          ...prevState[form],
          ...(param ? { [param]: '' } : {})
        }
      }),
      () => {
        fetchData && fetchData();
      }
    );
  }
  //needed to filter table by category or coach
  if (!form) {
    this.setState(
      { page: 1, [filterParam]: value ? value[filterItem] : null },
      () => this.searchHandler(evt)
    );
  }
}

//categories func
export function addCategoryGroup(evt, form, defaultFormState) {
  evt.preventDefault();

  const { state, triggerTableUpdate } = this.props;
  const formData = new FormData();

  formData.append('name', form.group_category_name);
  form.group_category_alt_name &&
    formData.append('alt_name', form.group_category_alt_name);
  formData.append('gender', form.group_category_gender);
  form.group_category_age_from &&
    formData.append('age_from', form.group_category_age_from);
  form.group_category_age_to &&
    formData.append('age_to', form.group_category_age_to);
  formData.append('type', form.group_category_type); // this will be used in the near future

  this.setState({ isSending: true });

  apiPostRequest('/new_category_group', formData, (msg, status) => {
    const callback = () => {
      state && triggerTableUpdate();
      msg.msg === OK &&
        this.setState({
          errors: { ...defaultFormState, group_category_type: '' },
          form: defaultFormState
        });
    };

    this.setState({ isSending: false });

    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        i18n.t('successfullyAdded'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function addCategory(event, defaultFormState) {
  event.preventDefault();

  const { form } = this.state;
  const { state, triggerTableUpdate } = this.props;
  const formData = new FormData();

  formData.append('category_name', form.group_category_name);
  form.group_category_alt_name &&
    formData.append('category_alt_name', form.group_category_alt_name);
  form.category_alt_name_plus &&
    formData.append('category_alt_name_plus', form.category_alt_name_plus);
  form.weighted &&
    form.category_name_plus &&
    formData.append('category_name_plus', form.category_name_plus);
  formData.append('category_type', form.group_category_type);
  formData.append('category_age_from', form.group_category_age_from);
  formData.append('category_age_to', form.group_category_age_to);
  form?.weighted &&
    form.category_weight_from &&
    formData.append('category_weight_from', form.category_weight_from);
  formData.append('category_gender', form.group_category_gender);
  form.group_id && formData.append('group_id', form.group_id);

  this.setState({ isSending: true });

  apiPostRequest('/new_category', formData, (msg, status) => {
    const callback = () => {
      state && triggerTableUpdate();

      this.setState((prevState) => ({
        errors: {},
        form:
          status === 200
            ? {
                ...defaultFormState,
                ...(form?.group_id && form?.weighted
                  ? {
                      group_id: form?.group_id,
                      form_title_name: form?.form_title_name,
                      group_category_gender: form?.group_category_gender,
                      group_category_age_to: form?.group_category_age_to,
                      group_category_age_from: form?.group_category_age_from,
                      group_category_name: form?.form_title_name,
                      category_type_name: form?.category_type_name,
                      weighted: form?.weighted,
                      category_name_plus: form?.form_title_name,
                      group_category_alt_name: form?.form_title_name,
                      category_alt_name_plus: form?.form_title_name,
                      group_category_type: form?.group_category_type
                    }
                  : {})
              }
            : { ...prevState.form }
      }));
    };

    this.setState({ isSending: false });

    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        i18n.t('successfullyAdded'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function updateCategoryGroup(evt, form, callback) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('id', form.group_id);
  formData.append('name', form.group_category_name);
  form.group_category_alt_name &&
    formData.append('alt_name', form.group_category_alt_name);
  formData.append('gender', form.group_category_gender);
  formData.append('age_from', form.group_category_age_from);
  formData.append('age_to', form.group_category_age_to);
  // formData.append('type', form.group_category_type);  //type will be used later in time

  apiPostRequest('/update_category_group', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => callback && callback(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function updateCategory(event, form, callback) {
  event.preventDefault();

  const formData = new FormData();

  formData.append('category_id', form.category_id);
  formData.append('category_name', form.group_category_name);
  form.group_category_alt_name &&
    formData.append('category_alt_name', form.group_category_alt_name);
  form.category_alt_name_plus &&
    formData.append('category_alt_name_plus', form.category_alt_name_plus);
  form.category_name_plus &&
    formData.append('category_name_plus', form.category_name_plus);
  form.category_weight_from &&
    formData.append('category_weight_from', form.category_weight_from);
  form.group_category_age_from &&
    formData.append('category_age_from', form.group_category_age_from);
  form.group_category_age_to &&
    formData.append('category_age_to', form.group_category_age_to);
  formData.append('category_gender', form.group_category_gender);
  formData.append('category_type', form.group_category_type);
  form.group_id && formData.append('group_id', form.group_id);

  apiPostRequest('/update_category', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => callback && callback(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function fetchCategories(
  fetchData,
  tournamentId,
  type,
  page,
  rowsPerPage,
  isFilterActive,
  filterByData,
  typeList,
  genderList,
  ageList,
  ownerList,
  search,
  groupedCategories
) {
  //param type -> used to sort categories by category_type for Dojo users
  //params age, weight, gender -> filter categories to narrow down the list of possible category assignments
  let param;
  if (page) {
    param = {
      current_page: page,
      mirror_mode: 1,
      grouped_mode: groupedCategories,
      rows_count: rowsPerPage,
      ...(type ? { category_type: type } : {}),
      ...(typeList && typeList.length > 0
        ? { category_type_list: JSON.stringify(typeList) }
        : {}),
      ...(genderList && genderList.length > 0
        ? { gender_list: JSON.stringify(genderList) }
        : {}),
      ...(ageList && ageList.length > 0
        ? { age_list: JSON.stringify(ageList) }
        : {}),
      ...(ownerList && ownerList.length > 0
        ? { owner_list: JSON.stringify(ownerList) }
        : {}),
      ...(search ? { search } : {})
    };
  } else if (isFilterActive || type) {
    param = {
      tournament_id: tournamentId,
      ...(type ? { category_type: type } : {}),
      ...(filterByData?.age ? { age: filterByData.age } : {})
    };
  } else {
    param = { tournament_id: tournamentId };
  }

  apiGetRequest('/get_categories', param, (categories, status) => {
    if (Array.isArray(categories)) {
      const filteredCategories = isFilterActive
        ? categories.filter((it) =>
            filterCategoriesList(
              it,
              filterByData?.gender,
              filterByData?.weight,
              filterByData?.height
            )
          )
        : categories;
      this.setState(
        {
          categories,
          filteredCategories
        },
        () => fetchData && fetchData(filteredCategories)
      );
    }
    if (page) {
      this.setState({
        shouldUpdateTable: false,
        categoriesCount:
          categories.length > 0 ? categories[0].categories_count : 0
      });
    }
    this.changeModal(requestStatus(status, null, categories), status);
  });
}

// PARTICIPANTS PAGE
export function addParticipant(
  event,
  form,
  coachForm,
  errorName,
  formName,
  fetchData,
  tourn,
  assingCategoriesList,
  token,
  selectedTournamentInfo
) {
  event.preventDefault();

  const isTraining = tourn && tourn.id && +tourn.type === 3;
  const formData = new FormData();

  formData.append('first_name', form.first_name);
  formData.append('last_name', form.last_name);
  form.patronymic && formData.append('patronymic', form.patronymic);
  formData.append('coach_id', form.coach_id);
  form.profile_pic && formData.append('profile_pic', form.profile_pic);
  form.club && formData.append('club', form.club);
  form.country_id && formData.append('country_id', form.country_id);
  form.region && formData.append('region', form.region);
  form.city && formData.append('city', form.city);
  form.iko && formData.append('iko', form.iko);
  form.qdan && formData.append('qdan', form.qdan);
  form.height && formData.append('height', form.height);
  formData.append('weight', form.weight);
  formData.append('gender', form.gender);
  formData.append('birthday', form.birthday);
  form.age && formData.append('age', form.age);
  form.history && formData.append('history', form.history);
  form.coach_first_name &&
    formData.append('coach_first_name', form.coach_first_name);
  form.coach_last_name &&
    formData.append('coach_last_name', form.coach_last_name);
  form.coach_first_name_2 &&
    formData.append('coach_first_name_2', form.coach_first_name_2);
  form.coach_last_name_2 &&
    formData.append('coach_last_name_2', form.coach_last_name_2);
  form.first_name_national &&
    formData.append('first_name_national', form.first_name_national);
  form.last_name_national &&
    formData.append('last_name_national', form.last_name_national);
  form.sport_rank && formData.append('sport_rank', form.sport_rank);
  form.rate && formData.append('rate', form.rate);

  if (tourn) {
    formData.append('tournament_id', tourn.id ? tourn.id : tourn);

    if (assingCategoriesList) {
      formData.append('category_list', JSON.stringify(assingCategoriesList));
    }

    if (isTraining) {
      formData.append('training', form.training);
      form.training && formData.append('next_qdan_id', form.next_qdan_id);
    }
  }

  this.setState({ isSending: true });

  apiPostRequest(
    '/participants/new',
    formData,
    (msg, status) => {
      const callback = () => {
        this.setState(
          {
            isSending: false,
            [errorName]: {},
            [formName]: {
              coach_id: coachForm.id,
              ...(coachForm.region ? { region: coachForm.region } : {}),
              club: coachForm.club,
              country_id: coachForm.country_id,
              ...(coachForm.c_first_name
                ? {
                    coach_first_name:
                      coachForm.coach_first_name ?? coachForm.c_first_name
                  }
                : {}),
              ...(coachForm.c_last_name
                ? {
                    coach_last_name:
                      coachForm.coach_last_name ?? coachForm.c_last_name
                  }
                : {}),
              ...(coachForm.city ? { city: coachForm.city } : {}),
              ...(selectedTournamentInfo ? { selectedTournamentInfo } : {}),
              ...(isTraining ? { training: true, nextQdan: false } : {})
            }
          },
          () => fetchData && fetchData(msg.id, msg, status)
        );
      };

      if (status !== 200) {
        this.changeModal(
          requestStatus(status, null, msg, null, i18n.t('cannotSaveChanges')),
          status
        );
      } else {
        callback();
      }
    },
    token,
    API_URL_V2
  );
}

export function updateParticipant(
  event,
  form,
  fetchData,
  tourn,
  assignCategoriesList,
  token,
  role,
  shouldApproveParticipation
) {
  event.preventDefault();

  const isRecorder = role === 'recorder';
  const isTraining = tourn && tourn.id && +tourn.type === 3;
  const formData = new FormData();

  formData.append('participant_id', form.id);

  if (!shouldApproveParticipation) {
    formData.append('first_name', form.first_name);
    formData.append('last_name', form.last_name);
    formData.append('patronymic', form.patronymic ?? '');
    formData.append('birthday', form.birthday);
    formData.append('country_id', form.country_id);
    formData.append('coach_id', form.coach_id);
    formData.append('age', form.age);
    formData.append('gender', form.gender);
    formData.append('weight', form.weight);
    form.rate && formData.append('rate', form.rate);
    form.region && formData.append('region', form.region);
    form.city && formData.append('city', form.city);
    form.club && formData.append('club', form.club);
    form.qdan && formData.append('qdan', form.qdan);
    form.height && formData.append('height', form.height);
    form.iko && formData.append('iko', form.iko);
    form.history && formData.append('history', form.history);
    form.profile_pic && formData.append('profile_pic', form.profile_pic);
    form.coach_first_name &&
      formData.append('coach_first_name', form.coach_first_name);
    form.coach_last_name &&
      formData.append('coach_last_name', form.coach_last_name);
    form.coach_first_name_2 &&
      formData.append('coach_first_name_2', form.coach_first_name_2);
    form.coach_last_name_2 &&
      formData.append('coach_last_name_2', form.coach_last_name_2);
    form.first_name_national &&
      formData.append('first_name_national', form.first_name_national);
    form.last_name_national &&
      formData.append('last_name_national', form.last_name_national);

    form.sport_rank && formData.append('sport_rank', form.sport_rank);

    if (assignCategoriesList) {
      formData.append('category_list', JSON.stringify(assignCategoriesList));
    }
  } else {
    formData.append('registered', form.registered);
    formData.append('has_docs', form.has_docs);
    formData.append('has_weighted', form.has_weighted);
    formData.append('has_equipment', form.has_equipment);
    formData.append('has_medic', form.has_medic);
  }

  if (tourn) {
    //tourn is an object or is a number. Using this check bc of invitationInfo in quick registration
    formData.append('tournament_id', tourn.id ? tourn.id : tourn);
    //add to training camp
    if (isTraining) {
      formData.append('training', form.training);
      form.training && formData.append('next_qdan_id', form.next_qdan_id);
    }
  }

  apiPostRequest(
    isRecorder ? '/update_participant' : '/participants/update',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => fetchData && fetchData(),
          msg,
          i18n.t('successfullyUpdated'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    },
    token,
    isRecorder ? API_URL_RECORDER : API_URL_V2
  );
}

export function selectTournament(
  _,
  value,
  form,
  fetchData,
  shouldShowDiscardChanges
) {
  if (value !== null) {
    this.setState(
      (prevState) => ({
        [form]: {
          ...prevState[form],
          selectedTournamentInfo: value
        },
        ...(shouldShowDiscardChanges ? { isEditing: true } : {})
      }),
      () => fetchData && fetchData()
    );
  }
}

const filterCategoriesList = (it, gender, weight, height) => {
  const currentGender = it.gender === gender || it.gender === 'U';
  let currentWeight;

  if (+it.category_type_id === 8) {
    const wh = +weight + +height;

    currentWeight = it.weight_from
      ? wh >= +it.weight_from + 0.499
      : it.weight_to && wh <= +it.weight_to + 0.499;
  } else {
    currentWeight =
      !weight ||
      (weight >= (+it.weight_from || 0) && weight <= (+it.weight_to || 300));
  }

  return currentGender && currentWeight;
};

export function selectCategory(
  _,
  value,
  form,
  errors,
  shouldShowDiscardChanges
) {
  if (value !== null) {
    if (form) {
      this.setState((prevState) => ({
        [form]: {
          ...prevState[form],
          category_id: value.category_id,
          new_category_id: value.category_id,
          category_type: value.category_type_id
        },
        ...(shouldShowDiscardChanges ? { isEditing: true } : {})
      }));
    }
    if (errors) {
      `${value.category_type_id}` === '3' &&
      !(this.state[form] && this.state[form].team_id)
        ? this.setState((prevState) => ({
            errors: { ...prevState.errors, team_id: '' }
          }))
        : delete errors.team_id;
      delete errors.category_id;
      delete errors.category_type;
    }
  }
}
export function fetchParticipants(
  current_page,
  rows_count,
  search,
  coach_id,
  fetchData,
  tournamentId,
  sortType,
  sortField,
  token,
  categoryId,
  searchByOption,
  filters
) {
  const params = {
    ...(current_page ? { current_page } : {}),
    ...(rows_count ? { rows_count } : {}),
    ...(search
      ? {
          [+searchByOption?.id === 0 || !searchByOption
            ? 'search'
            : 'search_coach']: search
        }
      : {}),
    ...(coach_id ? { coach_id } : {}),
    ...(tournamentId ? { tournament_id: tournamentId } : {}),
    ...(categoryId ? { category_id: categoryId } : {}),
    ...(sortField ? { sort_type: sortType } : {}),
    ...(sortField ? { sort_field: sortField } : {}),
    ...(filters?.genderArray?.length > 0
      ? { gender_list: JSON.stringify(filters.genderArray) }
      : {}),
    ...(filters?.ageArray?.length > 0
      ? { age_list: JSON.stringify(filters.ageArray) }
      : {}),
    ...(filters?.archive ? { archive: filters.archive } : {})
  };
  apiGetRequest(
    '/get_participants_v2',
    params,
    (participants, status) => {
      fetchData && fetchData(participants);

      if (status !== 200) {
        this.changeModal(
          requestStatus(
            status,
            null,
            participants,
            null,
            i18n.t('errorBoundary')
          ),
          status
        );
      }
    },
    token
  );
}

export function fetchSingleParticipant(partID, cb) {
  apiGetRequest(`/get_participant_by_id/${partID}`, '', (body, status) => {
    this.changeModal(
      requestStatus(status, () => cb && cb(body), body),
      status
    );
  });
}

export function fetchParticipantsByTournamentV2(
  page,
  rowsPerPage,
  search,
  coachId,
  tournament,
  inactive,
  fetchData,
  category,
  sortDirection,
  sortField,
  searchByOption,
  filters
) {
  const formData = new FormData();

  formData.append('tournament_id', tournament);
  formData.append('current_page', page);
  formData.append('rows_count', rowsPerPage);
  search &&
    formData.append(
      +searchByOption?.id === 0 ? 'search' : 'search_coach',
      search
    );
  coachId && formData.append('coach_id', coachId);
  inactive && formData.append('inactive_participants', inactive);
  category && formData.append('category_id', category);
  sortDirection && formData.append('sort_direction', sortDirection);
  sortField && formData.append('sort_field', sortField);
  filters?.issues && formData.append('issues', filters.issues);
  filters?.isRetiredParticipantsChecked &&
    formData.append('inactive_only', filters.isRetiredParticipantsChecked);
  filters?.participant_id &&
    formData.append('participant_id', filters.participant_id);
  filters?.typeArray?.length > 0 &&
    formData.append('category_type_list', JSON.stringify(filters.typeArray));
  filters?.genderArray?.length > 0 &&
    formData.append('gender_list', JSON.stringify(filters.genderArray));
  filters?.ageArray?.length > 0 &&
    formData.append('age_list', JSON.stringify(filters.ageArray));

  apiPostRequest(
    '/get_participants_by_tournament_v2',
    formData,
    (participants, status) => {
      fetchData && fetchData(participants);

      this.changeModal(
        requestStatus(
          status,
          null,
          participants,
          null,
          i18n.t('noTournamentSpecified')
        ),
        status
      );
    }
  );
}

export function distributeParticipantByCategory(
  tournID,
  partList,
  newCategory,
  newCategoryType,
  oldCategory,
  action,
  newNotification2Show
) {
  const formData = new FormData();

  formData.append('tournament_id', tournID);
  `${newCategoryType}` !== '3'
    ? formData.append('participant_ids', JSON.stringify(partList))
    : formData.append('participant_id', partList?.participant_id);
  formData.append('new_category_id', newCategory);
  oldCategory && formData.append('old_category_id', oldCategory);

  `${newCategoryType}` === '3' &&
    formData.append('team_id', partList?.team_id || 1);

  apiPostRequest('/set_participant_category', formData, (msg, status) => {
    action && action(msg); //distribute multiple participants to categories,
    // some may succeed, some may fail, so the callback is outside to handle both outcomes

    if (!newNotification2Show) {
      this.changeModal(
        requestStatus(
          status,
          null,
          msg,
          i18n.t('successfullyUpdated'),
          i18n.t('assignParticipantError')
        ),
        status
      );
    }
  });
}

export function setCategory(evt) {
  evt.persist();

  const {
    participantId,
    selectedCategories,
    tournamentWeight,
    modalData, //data of the participant on showModal
    form
  } = this.state;
  const formData = new FormData();

  formData.append('participant_id', participantId || form.id);
  if (selectedCategories) {
    formData.append(
      'tournament_id',
      selectedCategories.selectedTournamentInfo
        ? selectedCategories.selectedTournamentInfo.id
        : selectedCategories.tournament_id
    );
    selectedCategories.old_category_id &&
      formData.append('old_category_id', selectedCategories.old_category_id);
    formData.append(
      'new_category_id',
      selectedCategories.new_category_id
        ? selectedCategories.new_category_id
        : `${selectedCategories.category_type}` === '3' &&
            selectedCategories.old_category_id
    );
    `${selectedCategories.category_type}` === '3' &&
      formData.append('team_id', selectedCategories.team_id);
  }
  //if the participant has already been assigned on the tournament category, use the tournament weight
  formData.append(
    'weight',
    !tournamentWeight ? modalData.weight : tournamentWeight
  );

  apiPostRequest('/set_participant_category', formData, (msg, status) => {
    const callback = () => {
      if (
        Object.values(msg).some((it) => typeof it === 'boolean' && it === true)
      ) {
        this.setState({ isAttemptingToEditModalFields: false }, () => {
          this.triggerTableUpdate();
          this.hideModal();
        });
      }
    };

    const failToAssignParticipant = Object.values(msg).every(
      (it) => it === FALSE
    );
    const assignParticipantMsg = failToAssignParticipant
      ? `${selectedCategories.category_type}` === '3'
        ? i18n.t('threeParticipantsTeamKata')
        : i18n.t('assignParticipantError')
      : i18n.t('successfullyUpdated');

    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        assignParticipantMsg,
        i18n.t('assignParticipantError')
      ),
      status,
      failToAssignParticipant
    );
  });
}

export function fetchAllCategoryTypes(action, tournID) {
  apiGetRequest(
    `/get_category_types`,
    { ...(tournID ? { tournament_id: tournID } : {}) },
    (msg, status) => {
      action && action(msg);
    }
  );
}

export function fetchAllCategoryTypesV2(values, action) {
  const payload = JSON.stringify({
    tournament_id: values?.tournament_id,
    user_id: values?.user_id
  });
  apiPostRequest(
    `/categories/get_category_types`,
    payload,
    (msg, status) => {
      action && action(msg);
    },
    null,
    API_URL_V2
  );
}

export function fetchTournamentList(participantId, tournamentId, fetchData) {
  const { pressedIcon } = this.state;

  apiGetRequest(
    `/get_tournament_applications/${participantId}${
      tournamentId ? `/${tournamentId}` : ''
    }`,
    '',
    (msg, status) => {
      const tournamentApplications =
        typeof msg === 'object' && Array.isArray(msg.tournaments)
          ? msg.tournaments
          : [];

      pressedIcon !== ADD_ICON && this.setState({ tournamentApplications });

      this.setState(
        {
          processing: false,
          tournamentWeight:
            tournamentApplications.length > 0
              ? tournamentApplications[0].participant_weight
              : null
        },
        () => fetchData && fetchData(msg)
      );

      this.changeModal(requestStatus(status, null, msg), status);
    }
  );
}

export function fetchTournaments(
  tournament,
  type,
  searching,
  fetchData,
  exception,
  type2,
  tournamentFilters
) {
  apiGetRequest(
    '/get_tournaments',
    {
      ...(tournament ? { tournament_id: tournament } : {}),
      ...(type === 1 ? { future: 1 } : type === 2 ? { past: 1 } : {}),
      ...(type2 === 1 ? { is_owner: 1 } : {}),
      ...(tournamentFilters ? tournamentFilters : {})
    },
    (tournaments, status) => {
      Array.isArray(tournaments) &&
        this.setState(
          {
            tournamentsList: tournaments,
            filteredTournaments: tournaments,
            ...(!exception ? { tournamentData: tournaments[0] } : {}),
            shouldUpdateTable: false
          },
          () => {
            fetchData && fetchData();
            searching
              ? this.setState({
                  filteredTournaments: tournaments.filter(
                    (tournament) => {
                      return tournament.tournament_name
                        .toLowerCase()
                        .includes(searching.toLowerCase().trim());
                    },
                    () =>
                      this.setState({
                        tournamentsCount: this.state.filteredTournaments.length
                      })
                  )
                })
              : this.setState({ tournamentsCount: tournaments.length });
          }
        );

      if (status !== 200) {
        this.changeModal(
          requestStatus(
            status,
            null,
            tournaments,
            null,
            i18n.t('actionFailed')
          ),
          status
        );
      }
    }
  );
}
export function fetchCoaches(tournament, cb) {
  apiGetRequest(
    '/get_coaches_list',
    { ...(tournament ? { tournament_id: tournament } : {}) },
    (coaches, status) => {
      if (Array.isArray(coaches)) {
        this.setState(
          {
            coachesList: coaches,
            tournament_id: tournament,
            filteredCoaches: coaches
          },
          () => cb && cb(coaches)
        );
      }
      this.changeModal(requestStatus(status, null, coaches), status);
    }
  );
}

export function fetchUsers(id, values, fetchData, token, cb, dontShowSnackbar) {
  const param = !id ? '' : id;

  apiGetRequest(
    `/get_users/${param}`,
    '',
    (users, status) => {
      if (Array.isArray(users)) {
        if (!id && !token) {
          this.setState({ users, filteredUsers: users }, () => cb && cb(users));
        } else {
          this.setState(
            {
              [values]: {
                ...(users[0]?.photo
                  ? { imagePreview: serverDefaultPath + users[0].photo }
                  : {}),
                ...(users[0]?.logo
                  ? { logoPreview: serverDefaultPath + users[0].logo }
                  : {}),
                ...(users[0]?.stamp
                  ? { stampPreview: serverDefaultPath + users[0].stamp }
                  : {}),
                ...(users[0]?.signature
                  ? { signaturePreview: serverDefaultPath + users[0].signature }
                  : {}),
                ...users[0]
              },
              selectedDate: users[0]?.birthday
            },
            () => users[0] && fetchData && fetchData(users[0])
          );
        }
      }
      (!dontShowSnackbar || status !== 200) &&
        this.changeModal(requestStatus(status, null, users), status);
    },
    token
  );
}

export function fetchUsersAsAdmin(
  values,
  pageNum,
  rowsPerPage,
  cb,
  sortData,
  withCount
) {
  const formData = JSON.stringify({
    orderBy: sortData?.[2],
    orderAscDirection: sortData?.[0] === 1 ? true : false,

    queryType: values.queryTypeID,
    role: values.roleID,
    status: values.statusID,
    search: values.searchedValue,
    page: pageNum,
    limit: rowsPerPage,
    withCount
  });

  apiPostRequest(
    '/users/get',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(body),
          body,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function fetchUserRequests(payload, cb) {
  const formData = JSON.stringify({ id: payload?.id });

  apiPostRequest(
    '/users/get_requests',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(body),
          body,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function updateUserDetails(e, values, fetchData, token) {
  e.preventDefault();

  const formData = new FormData();

  formData.append('first_name', values.first_name);
  formData.append('last_name', values.last_name);
  formData.append('email', values.email);
  formData.append('birthday', values.birthday);
  formData.append('user_id', values.id);
  formData.append('country_id', values.country_id);
  formData.append('patronymic', values.patronymic ?? '');
  formData.append('phone', values.phone);
  formData.append('website', values.website);
  values.gender && formData.append('gender', values.gender);
  formData.append('club', values.club);
  formData.append('region', values.region);
  formData.append('city', values.city);
  values.profile_pic && formData.append('profile_pic', values.profile_pic);
  values.branch_chief && formData.append('branch_chief', values.branch_chief);
  values.logo && formData.append('logo', values.logo);
  values.stamp && formData.append('stamp', values.stamp);
  values.signature && formData.append('signature', values.signature);
  formData.append('coach_first_name', values.coach_first_name);
  formData.append('coach_last_name', values.coach_last_name);

  apiPostRequest(
    '/update_user',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => fetchData && fetchData(),
          msg,
          i18n.t('successfullyUpdated')
        ),
        status
      );
    },
    token
  );
}

export function addUser(e, form, fetchData, tournamentId, token) {
  e.preventDefault();
  const formData = new FormData();

  formData.append('first_name', form.first_name);
  formData.append('last_name', form.last_name);
  formData.append('email', form.email);
  form.country_id && formData.append('country_id', form.country_id);
  form.club && formData.append('club', form.club);
  form.region && formData.append('region', form.region);
  form.city && formData.append('city', form.city);
  !token && formData.append('tournament_id', tournamentId);
  token &&
    form.branch_chief &&
    formData.append('branch_chief', form.branch_chief);
  form.profile_pic && formData.append('profile_pic', form.profile_pic);

  const apiRequest = token ? noTokenPostRequest : apiPostRequest;

  apiRequest(
    `/new_coach${token ? `/${token}` : ''}`,
    formData,
    (msg, status) => {
      this.setState({ isSending: false });
      this.changeModal(
        requestStatus(
          status,
          () => fetchData && fetchData(msg),
          msg,
          !token && i18n.t('successfullyAdded'),
          i18n.t('userExists')
        ),
        status
      );
    }
  );
}
export function fetchCountries(cb) {
  noTokenApiGetRequest('/get_countries', '', (countries, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => {
          this.setState({ countries });
          cb && cb();
        },
        countries
      ),
      status
    );
  });
}
export function fetchQdan() {
  noTokenApiGetRequest('/get_qdan_list', '', (qdanList, status) => {
    this.changeModal(
      requestStatus(status, () => this.setState({ qdanList }), qdanList),
      status
    );
  });
}

const age = (age_from, age_to) => {
  let myAge;

  if (age_from && !age_to) {
    myAge = `${age_from}+`;
  } else if (!age_from && age_to) {
    myAge = `${age_to}-`;
  } else if (+age_from === +age_to) {
    myAge = age_from;
  } else {
    myAge = [age_from, age_to].join('-');
  }

  return myAge;
};

export function customCategoryType(type) {
  const { categoryTypes } = this.state;
  const categoryTypeName = categoryTypes?.find((it) => type === +it?.id);

  return categoryTypeName;
}

function removeCategoryNameWeight(str) {
  const strRemovedSpaces = str.trim().toLowerCase();
  let modifiedStr = str;

  if (strRemovedSpaces.endsWith('kg') || strRemovedSpaces.endsWith('кг')) {
    const items = str.split(' ');
    modifiedStr = items.slice(0, -2);
    modifiedStr = modifiedStr.join(' ');
  }

  return modifiedStr;
}

export function textChangeHandler(
  event,
  values,
  errors,
  typeNumber,
  passwordForm,
  shouldDisplayConfirmPass,
  tournamentData,
  isCityRequired,
  action,
  formData,
  isUpdateForm
) {
  const { name, value } = event.target;

  switch (name) {
    case 'first_name':
      if (value.length === 0) {
        errors.first_name = i18n.t('required');
      } else if (value.length > 32) {
        errors.first_name = i18n.t('cannotHaveMoreThan32Chars');
      } else if (
        tournamentData &&
        tournamentData.input_language === RU &&
        !new RegExp(CYRILLIC_CHARS).test(value)
      ) {
        errors.first_name = i18n.t('useCyrillicChars');
      } else if (
        tournamentData &&
        (tournamentData.input_language === EN ||
          tournamentData.input_language === RO) &&
        !new RegExp(LATIN_CHARS).test(value)
      ) {
        errors.first_name = i18n.t('useLatinChars');
      } else {
        if (!isUpdateForm) {
          if (new RegExp(CYRILLIC_CHARS).test(value)) {
            const transliteratedVal = transliterate(value);

            this.setState((prevState) => ({
              [values]: {
                ...prevState[values],
                first_name_national: transliteratedVal
              }
            }));
          } else {
            this.setState((prevState) => ({
              [values]: {
                ...prevState[values],
                first_name_national: value
              }
            }));
          }

          delete errors.first_name_national;
        }

        delete errors.first_name;
      }
      break;
    case 'last_name':
      if (value.length === 0) {
        errors.last_name = i18n.t('required');
      } else if (value.length > 32) {
        errors.last_name = i18n.t('cannotHaveMoreThan32Chars');
      } else if (
        tournamentData &&
        tournamentData.input_language === RU &&
        !new RegExp(CYRILLIC_CHARS).test(value)
      ) {
        errors.last_name = i18n.t('useCyrillicChars');
      } else if (
        tournamentData &&
        (tournamentData.input_language === EN ||
          tournamentData.input_language === RO) &&
        !new RegExp(LATIN_CHARS).test(value)
      ) {
        errors.last_name = i18n.t('useLatinChars');
      } else {
        if (!isUpdateForm) {
          if (new RegExp(CYRILLIC_CHARS).test(value)) {
            const transliteratedVal = transliterate(value);

            this.setState((prevState) => ({
              [values]: {
                ...prevState[values],
                last_name_national: transliteratedVal
              }
            }));
          } else {
            this.setState((prevState) => ({
              [values]: {
                ...prevState[values],
                last_name_national: value
              }
            }));
          }

          delete errors.last_name_national;
        }

        delete errors.last_name;
      }
      break;

    case 'first_name_national':
      if (tournamentData && +tournamentData.type === 2 && value.length === 0) {
        errors.first_name_national = i18n.t('required');
      } else if (value && !new RegExp(LATIN_CHARS).test(value)) {
        errors.first_name_national = i18n.t('useLatinChars');
      } else {
        delete errors.first_name_national;
      }
      break;

    case 'last_name_national':
      if (tournamentData && +tournamentData.type === 2 && value.length === 0) {
        errors.last_name_national = i18n.t('required');
      } else if (value && !new RegExp(LATIN_CHARS).test(value)) {
        errors.last_name_national = i18n.t('useLatinChars');
      } else {
        delete errors.last_name_national;
      }
      break;

    case 'email':
      this.setState((prevState) => ({
        showError: { ...prevState.showError, [name]: false }
      }));
      if (value.length === 0) {
        errors.email = i18n.t('required');
      } else if (value.length > 100) {
        errors.email = i18n.t('maxChars', { max: 100 });
      } else if (!EMAIL_REG_EXP.test(value)) {
        errors.email = i18n.t('invalidEmail');
      } else {
        delete errors.email;
      }
      break;
    case 'username':
      if (value.length === 0) {
        errors.username = i18n.t('required');
      } else if (value.length > 128) {
        errors.username = i18n.t('maxChars', { max: 128 });
      } else if (value.match(USERNAME_REG_EXP)) {
        errors.username = i18n.t('invalidCharacters');
      } else {
        delete errors.username;
      }
      break;
    case 'name':
      if (value.length === 0) {
        errors.name = i18n.t('required');
      } else if (value.length > 500) {
        errors.name = i18n.t('maxChars', { max: 500 });
      } else if (value.match(SYMBOLS_REG_EXP)) {
        errors.name = i18n.t('invalidCharacters');
      } else {
        delete errors.name;
      }
      break;
    case 'tournament_name':
      if (value.length === 0) {
        errors.tournament_name = i18n.t('required');
      } else if (value.length > 500) {
        errors.tournament_name = i18n.t('maxChars', { max: 500 });
      } else if (value.match(TOURNAMENT_NAME_REG_EXP)) {
        errors.tournament_name = i18n.t('invalidCharacters');
      } else {
        delete errors.tournament_name;
      }
      break;
    case 'address':
      if (value.length === 0) {
        errors.address = i18n.t('required');
      } else if (value.length > 128) {
        errors.address = i18n.t('maxChars', { max: 128 });
      } else {
        delete errors.address;
      }
      break;
    case 'gender':
      if (value.length === 0) {
        errors.gender = i18n.t('required');
      } else {
        delete errors.gender;
      }
      break;
    case 'weight':
      if (value.length === 0) {
        errors.weight = i18n.t('required');
      } else if (parseInt(value) < MIN_WEIGHT) {
        errors.weight = i18n.t('minimumAllowableWeight');
      } else if (value.match(POSITIVE_NUMBERS)) {
        delete errors.weight;
      }
      break;
    case 'height':
      if (!!+tournamentData?.height_needed && value.length === 0) {
        errors.height = i18n.t('required');
      } else if (value.match(POSITIVE_NUMBERS)) {
        delete errors.height;
      }
      break;
    case 'patronymic':
      if (
        formData?.patronymic_required &&
        !!+tournamentData?.show_reg_patronymic &&
        value.length === 0
      ) {
        errors.patronymic = i18n.t('required');
      } else {
        delete errors.patronymic;
      }
      break;
    case 'age':
      if (value.length === 0) {
        errors.age = i18n.t('required');
      } else if (
        parseInt(value) < MIN_AGE ||
        parseInt(value) > MAX_AGE ||
        value.includes('.')
      ) {
        errors.age = i18n.t('invalidValue');
      } else if (NUMBER_REG_EXP.test(value)) {
        const newDate = `${
          (tournamentData && tournamentData.start_date
            ? new Date(tournamentData.start_date).getFullYear()
            : new Date().getFullYear()) - value
        }-01-01`;
        this.setState((prevState) => ({
          [values]: {
            ...prevState[values],
            birthday: newDate,
            birthdayInputDate: newDate
          }
        }));
        delete errors.birthday;
        delete errors.age;
      }
      break;
    case 'previous_password':
      if (value.length === 0) {
        errors.previous_password = i18n.t('required');
      } else {
        delete errors.previous_password;
      }
      break;
    case 'password':
      this.setState((prevState) => ({
        showError: { ...prevState.showError, [name]: false }
      }));
      if (!shouldDisplayConfirmPass) {
        passwordForm.confirm_password === value
          ? delete errors.confirm_password
          : (errors.confirm_password = i18n.t('passwordsDoNotMatch'));
      }
      if (value.length < CHAR_4 || value.length > CHAR_16) {
        errors.password = i18n.t('requiredPasswordLength');
      } else if (
        passwordForm.confirm_password &&
        passwordForm.confirm_password !== value
      ) {
        errors.password = i18n.t('passwordsDoNotMatch');
      } else {
        delete errors.password;
      }
      break;
    case 'confirm_password':
      this.setState((prevState) => ({
        showError: { ...prevState.showError, [name]: false }
      }));
      if (passwordForm.password !== value) {
        errors.confirm_password = i18n.t('passwordsDoNotMatch');
        if (passwordForm.password) {
          passwordForm.password.length >= CHAR_4 &&
            passwordForm.password.length <= CHAR_16 &&
            (errors.password = i18n.t('passwordsDoNotMatch'));
        }
      } else {
        value.length >= CHAR_4 &&
          value.length <= CHAR_16 &&
          delete errors.password;
        delete errors.confirm_password;
      }
      break;

    case 'city':
      if (isCityRequired && value.length === 0) {
        errors.city = i18n.t('required');
      } else if (value.length > 128) {
        errors.city = i18n.t('maxChars', { max: 128 });
      } else if (
        value &&
        tournamentData &&
        tournamentData.input_language === RU &&
        !new RegExp(CYRILLIC_CHARS).test(value)
      ) {
        errors.city = i18n.t('useCyrillicChars');
      } else if (
        value &&
        tournamentData &&
        (tournamentData.input_language === EN ||
          tournamentData.input_language === RO) &&
        !new RegExp(LATIN_CHARS).test(value)
      ) {
        errors.city = i18n.t('useLatinChars');
      } else delete errors.city;
      break;

    case 'club_federation':
      if (value.length === 0) {
        errors.club_federation = i18n.t('required');
      } else if (value.length > 255) {
        errors.club_federation = i18n.t('maxChars', { max: 255 });
      } else delete errors.club_federation;
      break;

    case 'club':
      if (
        value &&
        tournamentData &&
        tournamentData.input_language === RU &&
        !new RegExp(CYRILLIC_CHARS).test(value)
      ) {
        errors.club = i18n.t('useCyrillicChars');
      } else if (
        value &&
        tournamentData &&
        (tournamentData.input_language === EN ||
          tournamentData.input_language === RO) &&
        !new RegExp(LATIN_CHARS).test(value)
      ) {
        errors.club = i18n.t('useLatinChars');
      } else if (value.length > 255) {
        errors.club = i18n.t('maxChars', { max: 255 });
      } else delete errors.club;
      break;
    case 'region':
      if (
        value &&
        tournamentData &&
        tournamentData.input_language === RU &&
        !new RegExp(CYRILLIC_CHARS).test(value)
      ) {
        errors.region = i18n.t('useCyrillicChars');
      } else if (
        value &&
        tournamentData &&
        (tournamentData.input_language === EN ||
          tournamentData.input_language === RO) &&
        !new RegExp(LATIN_CHARS).test(value)
      ) {
        errors.region = i18n.t('useLatinChars');
      } else if (value.length > 64) {
        errors.region = i18n.t('maxChars', { max: 64 });
      } else delete errors.region;
      break;
    case 'main_judge':
      if (value.length > 200) {
        errors.main_judge = i18n.t('maxChars', { max: 200 });
      } else delete errors.main_judge;
      break;
    case 'duration_days':
      if (value.length > 2) {
        errors.duration_days = i18n.t('maxChars', { max: 2 });
      } else delete errors.duration_days;
      break;
    case 'secretary':
      if (value.length > 200) {
        errors.secretary = i18n.t('maxChars', { max: 200 });
      } else delete errors.secretary;
      break;
    case 'iko':
      if (value.length > 128) {
        errors.iko = i18n.t('maxChars', { max: 128 });
      } else delete errors.iko;
      break;
    case 'history':
      if (value.length > 1024) {
        errors.history = i18n.t('maxChars', { max: 1024 });
      } else delete errors.history;
      break;
    case 'phone':
      if (value.length === 0) {
        delete errors.phone;
      } else if (value.length > 20) {
        errors.phone = i18n.t('maxChars', { max: 20 });
      } else if (value.length <= 4) {
        errors.phone = i18n.t('minChars', { num: 5 });
      } else if (!value.match(PHONE_CHARS)) {
        errors.phone = i18n.t('digitsOnly');
      } else {
        delete errors.phone;
      }
      break;
    case 'website':
      if (value.length > 256) {
        errors.website = i18n.t('maxChars', { max: 256 });
      } else delete errors.website;
      break;
    case 'category_name':
      if (value.length === 0) {
        errors.category_name = i18n.t('required');
      } else if (value.length > 128) {
        errors.category_name = i18n.t('maxChars', { max: 128 });
      } else {
        delete errors.category_name;
      }
      break;
    case 'group_category_name':
      if (value.length === 0) {
        errors.group_category_name = i18n.t('required');
      } else if (value.length > 128) {
        errors.group_category_name = i18n.t('maxChars', { max: 128 });
      } else {
        delete errors.group_category_name;
      }
      break;
    case 'category_name_plus':
      if (value.length > 128) {
        errors.category_name_plus = i18n.t('maxChars', { max: 128 });
      } else {
        delete errors.category_name_plus;
      }
      break;
    case 'category_weight_from':
      if (parseInt(value) < MIN_WEIGHT) {
        errors.category_weight_from = i18n.t('minimumAllowableWeight');
      } else if (value.match(POSITIVE_NUMBERS)) {
        if (!isUpdateForm && formData?.group_category_type) {
          const findCategoryType = this.customCategoryType(
            +formData?.group_category_type
          );

          const assembledKumiteName = (sign) =>
            [
              removeCategoryNameWeight(formData?.group_category_name),
              formData?.weighted && value
                ? [
                    sign.includes('-') ? sign : '',
                    value,
                    sign.includes('+') ? sign : '',
                    ' ',
                    findCategoryType?.unit
                  ].join('')
                : ''
            ].join(' ');

          const assembledKumiteNameAlt = (sign) =>
            [
              removeCategoryNameWeight(formData?.group_category_alt_name),
              formData?.weighted && value
                ? [
                    sign.includes('-') ? sign : '',
                    value,
                    sign.includes('+') ? sign : '',
                    ' ',
                    findCategoryType?.unit
                  ].join('')
                : ''
            ].join(' ');

          this.setState((prevState) => ({
            [values]: {
              ...prevState[values],
              group_category_name: assembledKumiteName('-'),
              category_name_plus: assembledKumiteName('+'),
              group_category_alt_name: assembledKumiteNameAlt('-'),
              category_alt_name_plus: assembledKumiteNameAlt('+')
            }
          }));

          delete errors.group_category_name;
          delete errors.category_name_plus;
        }

        delete errors.category_weight_from;
      }
      break;
    case 'group_category_age_from':
      if (value.length === 0 && !formData.group_category_age_to) {
        errors.group_category_age_from = i18n.t('required');
      } else if (
        parseInt(value) < MIN_AGE ||
        parseInt(value) > MAX_AGE ||
        value.includes('.')
      ) {
        errors.group_category_age_from = i18n.t('invalidValue');
      } else if (value.match(POSITIVE_NUMBERS)) {
        if (!isUpdateForm && formData?.group_category_type) {
          const findCategoryType = this.customCategoryType(
            +formData?.group_category_type
          );
          const assembledKumiteName = (sign) =>
            [
              findCategoryType?.name,
              age(value, formData?.group_category_age_to),
              setCategoryGender(
                value,
                formData?.group_category_age_to,
                formData?.group_category_gender
              ),
              formData?.weighted && formData.category_weight_from
                ? [
                    sign.includes('-') ? sign : '',
                    formData.category_weight_from,
                    sign.includes('+') ? sign : '',
                    ' ',
                    findCategoryType?.unit
                  ].join('')
                : '',
              +formData.group_category_type === 2 && formData.isAbsoluteChecked
                ? 'Absolute'
                : ''
            ].join(' ');

          this.setState((prevState) => ({
            [values]: {
              ...prevState[values],
              group_category_name: assembledKumiteName('-'),
              category_name_plus: assembledKumiteName('+'),
              group_category_alt_name: assembledKumiteName('-'),
              category_alt_name_plus: assembledKumiteName('+')
            }
          }));

          delete errors.group_category_name;
          delete errors.category_name_plus;
        }

        delete errors.group_category_age_from;
        !formData.group_category_age_to && delete errors.group_category_age_to;
      }
      break;

    case 'group_category_age_to':
      if (value.length === 0 && !formData.group_category_age_from) {
        errors.group_category_age_to = i18n.t('required');
      } else if (
        parseInt(value) < MIN_AGE ||
        parseInt(value) > MAX_AGE ||
        value.includes('.')
      ) {
        errors.group_category_age_to = i18n.t('invalidValue');
      } else if (value.match(POSITIVE_NUMBERS)) {
        if (!isUpdateForm && formData?.group_category_type) {
          const assembledKumiteName = (sign) =>
            [
              this.customCategoryType(+formData?.group_category_type)?.name,
              age(formData?.group_category_age_from, value),
              setCategoryGender(
                formData?.group_category_age_from,
                value,
                formData?.group_category_gender
              ),
              formData?.weighted && formData.category_weight_from
                ? [
                    sign.includes('-') ? sign : '',
                    formData.category_weight_from,
                    sign.includes('+') ? sign : '',
                    ' ',
                    i18n.t('kg')
                  ].join('')
                : ''
            ].join(' ');

          this.setState((prevState) => ({
            [values]: {
              ...prevState[values],
              group_category_name: assembledKumiteName('-'),
              category_name_plus: assembledKumiteName('+'),
              group_category_alt_name: assembledKumiteName('-'),
              category_alt_name_plus: assembledKumiteName('+')
            }
          }));

          delete errors.group_category_name;
          delete errors.category_name_plus;
        }

        delete errors.group_category_age_to;
        !formData.group_category_age_from &&
          delete errors.group_category_age_from;
      }
      break;
    case 'attachment_name':
      if (value.length > CHARS_LIMIT) {
        errors.attachment_name = i18n.t('certificateNameLimitExceed');
      } else delete errors.attachment_name;
      break;
    case 'coach_first_name':
      if (value.length > 32) {
        errors.coach_first_name = i18n.t('maxChars', { max: 32 });
      }
      break;
    case 'coach_last_name':
      if (value.length > 32) {
        errors.coach_last_name = i18n.t('maxChars', { max: 32 });
      }
      break;
    case 'amount':
      if (value.length === 0) {
        errors.amount = i18n.t('required');
      } else {
        delete errors.amount;
      }
    case 'branch_chief':
      if (value.length === 0) {
        errors.branch_chief = i18n.t('required');
      } else if (value.length > 32) {
        errors.branch_chief = i18n.t('maxChars', { max: 32 });
      } else if (
        tournamentData &&
        tournamentData.input_language === RU &&
        !new RegExp(CYRILLIC_CHARS).test(value)
      ) {
        errors.branch_chief = i18n.t('useCyrillicChars');
      } else if (
        tournamentData &&
        (tournamentData.input_language === EN ||
          tournamentData.input_language === RO) &&
        !new RegExp(LATIN_CHARS).test(value)
      ) {
        errors.branch_chief = i18n.t('useLatinChars');
      } else delete errors.branch_chief;
      break;
    case 'experience':
      if (value > MAX_EXPERIENCE) {
        errors.experience = i18n.t('maxCharsForm', { max: 2 });
      } else {
        delete errors.experience;
      }
    default:
      break;
  }
  action && action();
  if (
    (typeNumber &&
      (value === '' || POSITIVE_NUMBERS.test(value)) &&
      value.indexOf('.') !== 0) ||
    !typeNumber
  ) {
    this.setState((prevState) => ({
      [values]: { ...prevState[values], [name]: value },
      isEditing: true
    }));
  }
}
export function clearSearch(searched, action) {
  this.setState({ searchBar: '' }, () => !!searched && action && action());
}
export function handleTextChange(event) {
  this.setState({ searchBar: event.target.value });
}

export function fetchTournamentTatamisOld(tourId, catType, cb) {
  // REMOVE THIS FUNC AFTER DEPLOYING NEW BRACKET DESIGN
  apiGetRequest(
    '/get_tournament_tatamis',
    { tournament_id: tourId, ...(catType ? { category_type: catType } : {}) },
    (tourTatamis, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => this.setState({ tourTatamis }, () => cb && cb()),
          tourTatamis
        ),
        status
      );
    }
  );
}

export function fetchTournamentTatamis(payload, cb) {
  apiGetRequest(
    '/get_tournament_tatamis',
    {
      tournament_id: payload.tournamentId,
      ...(payload.typeId ? { category_type: payload.typeId } : {})
    },
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    }
  );
}

export function fetchTatamis(tourId, cb) {
  apiGetRequest(
    '/get_all_tatamis',
    tourId ? { tournament_id: tourId } : '',
    (tatamis, status) => {
      if (status === 200) {
        const selectedTatamis = tatamis.filter((key) => !!+key.is_picked);

        this.setState({ tatamis, selectedTatamis });

        cb && cb(tatamis, selectedTatamis);
      }

      status !== 200 &&
        this.changeModal(requestStatus(status, null, tatamis), status);
    }
  );
}

export function getTatamisTheme() {
  apiGetRequest('/get_tatami_themes', '', (data, status) => {
    this.changeModal(
      requestStatus(status, () => this.setState({ tatamisTheme: data }), data),
      status
    );
  });
}

export function attachParticipants(
  evt,
  allAssignedCategories,
  coach,
  tourn,
  fetchData,
  part,
  selectedPartCategories
) {
  evt.persist();
  const { type } = this.state;
  let array = [];
  let assignTeamKataError = null;
  if (!part) {
    array = allAssignedCategories.map((it) => ({
      participant_id: it.participant_id,
      categories: [
        ...(it.categories
          ? it.categories.map((key) => {
              if (parseInt(key.category_type_id) === 3) {
                if (!key.team_id)
                  return (assignTeamKataError = i18n.t('incompleteData'));
                return {
                  id: parseInt(key.category_id),
                  team_id: parseInt(key.team_id)
                };
              } else return { id: parseInt(key.category_id) };
            })
          : [])
      ]
    }));
  } else {
    array = [
      {
        participant_id: part,
        categories: selectedPartCategories
      },
      ...(allAssignedCategories
        ? allAssignedCategories
            .filter((it) => it.participant_id !== part)
            .map((it) => {
              return {
                participant_id: it.participant_id,
                categories: it.categories.map((cat) =>
                  !it.team_id || it.team_id === 0
                    ? { id: cat.id }
                    : { id: cat.id, team_id: cat.team_id }
                )
              };
            })
        : [])
    ];
  }

  const body = JSON.stringify({
    tournament_id: tourn,
    coach_id: coach,
    categories_types: type,
    registrations: array
  });

  if (assignTeamKataError) {
    this.changeModal(assignTeamKataError);
  } else {
    apiPostRequest('/register_to_tournament_v2', body, (msg, status) => {
      //check if resgistration truly successed
      const additionFailed =
        Array.isArray(
          msg.additional_data && msg.additional_data.participants
        ) &&
        msg.additional_data.participants.some((it) => it.includes('failed'));

      const success = [
        i18n.t('participantRequestSent'),
        additionFailed ? i18n.t('additionToCategoryFailed') : null
      ].join('. ');

      this.changeModal(
        requestStatus(
          status,
          () => fetchData && fetchData(),
          msg,
          success,
          i18n.t('tooManyRequests')
        ),
        status,
        additionFailed
      );
    });
  }
}

export function reassignCategories(payload) {
  const categoriesLen = payload.categories?.length;
  let newCategories = [];

  for (let i = 0; i < categoriesLen; i++) {
    const category = payload.categories[i];

    newCategories = [
      ...newCategories,
      {
        category_id: +category.category_id,
        category_order: i,
        kata_final: category.kata_final
      }
    ];
  }

  const body = JSON.stringify({
    tournament_id: payload?.tournament_id,
    tatamis: [{ tatami_id: payload.tatami_id, categories: newCategories }]
  });

  apiPostRequest('/reassign_categories', body, (res, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => this.fetchTatmisCategories(payload?.tournament_id),
        res,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function generateCategoriesOnTatami(evt, tourn) {
  evt.preventDefault();

  const formData = new FormData();
  formData.append('tournament_id', tourn);

  apiPostRequest('/generate_categories_on_tatami', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => {
          this.fetchTatmisCategories(tourn, (categories) =>
            this.fetchData(categories)
          );
          this.setState({ selectedCheckboxes: [] });
        },
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function fetchTatmisCategories(
  tournamentId,
  fetchData,
  shouldShowAllCategories,
  showRealTatami
) {
  this.setState({ loading: true });

  apiGetRequest(
    `/get_tatamis_categories/${tournamentId}`,
    showRealTatami ? { real_tatami: 1 } : {},
    (tatamisCategories, status) => {
      if (status === 200) {
        let reassignedCategories = [];

        if (!shouldShowAllCategories) {
          if (tatamisCategories && tatamisCategories.tatamis) {
            const tatamiLen = tatamisCategories.tatamis.length;

            for (let i = 0; i < tatamiLen; i++) {
              const tatami = tatamisCategories.tatamis[i];
              const categoriesLen = tatami.categories.length;

              reassignedCategories = [
                ...reassignedCategories,
                { ...tatami, categories: [] }
              ];

              for (let j = 0; j < categoriesLen; j++) {
                const category = tatami.categories[j];

                if (category.participants_count > 0) {
                  const index = reassignedCategories.findIndex(
                    (it) => it.tatami_id === tatami.tatami_id
                  );

                  reassignedCategories[index].categories.push({ ...category });
                }
              }
            }
          }
        } else {
          reassignedCategories = [...tatamisCategories.tatamis];
        }

        fetchData && fetchData(reassignedCategories);
      }

      this.setState({ loading: false });

      if (status !== 200) {
        this.changeModal(
          requestStatus(status, null, tatamisCategories),
          status
        );
      }
    }
  );
}

export function fetchParticipantsByTournamentCategories(
  tournID,
  values,
  action,
  searchByOption
) {
  const params = {
    ...(values?.empty_categories
      ? { empty_categories: values.empty_categories }
      : {}),
    ...(values.search
      ? {
          [+searchByOption?.id === 0 ? 'search' : 'coach_search']: values.search
        }
      : {})
  };

  apiGetRequest(
    `/get_participants_by_tournament_categories/${tournID}`,
    params,
    (body, status) => {
      if (status === 200) {
        action && action(body);
      } else {
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('error')),
          status
        );
      }
    }
  );
}

export function addTournament(evt, form) {
  evt.preventDefault();

  const { selectedCheckboxes } = this.state;

  const { navigate } = this.props;
  const formData = new FormData();

  formData.append('name', form.tournament_name);
  form.description && formData.append('description', form.description);
  formData.append('date', form.date);
  formData.append('tour_time', form.tour_time);
  formData.append('registration_date', form.registration_date);
  formData.append('city', form.city);
  formData.append('address', form.address);
  formData.append('country_id', form.country_id);
  formData.append('type', form.type);
  form.places_number && formData.append('places_number', form.places_number);
  form.scoreboard_theme &&
    formData.append('scoreboard_theme', form.scoreboard_theme);
  form.kata_final && formData.append('kata_final', form.kata_final);
  formData.append('tg_order_type', form.tg_order_type);

  const checkboxFields = [
    'tameshiwari',
    'bronze_fight_enabled',
    'published',
    'no_participant_number',
    'no_country',
    'circle_round',
    'last_name_first',
    'registration_active',
    'section_import_on',
    'limited_reg_on',
    'is_finished',
    'height_needed',
    'reg_judge_form',
    'report_head',
    'sport_rank_needed',
    'use_rep_stamp',
    'use_rep_signature',
    'use_qr',
    'use_notification_win',
    'show_reg_iko',
    'show_reg_qdan',
    'show_reg_patronymic',
    'use_reg_online',
    'show_reg_coach_2',
    'show_reg_city',
    'use_alt_category_name',
    'show_reg_club',
    'show_reg_region'
  ];

  checkboxFields.forEach((key) => {
    formData.append(
      key,
      key === 'registration_active'
        ? +!selectedCheckboxes.includes(key)
        : +selectedCheckboxes.includes(key)
    );
  });

  form.main_judge && formData.append('main_judge', form.main_judge);
  form.secretary && formData.append('secretary', form.secretary);
  form.duration_days && formData.append('duration_days', form.duration_days);
  form.calc_age_date && formData.append('calc_age_date', form.calc_age_date);
  form.tournament_banner &&
    formData.append('tournament_banner', form.tournament_banner);
  form.participant_up_color &&
    formData.append('participant_up_color', form.participant_up_color);
  form.participant_down_color &&
    formData.append('participant_down_color', form.participant_down_color);
  form.reg_time && formData.append('reg_time', form.reg_time);
  form.reg_date && formData.append('reg_date', form.reg_date);
  form.reg_judge_date && formData.append('reg_judge_date', form.reg_judge_date);
  form.region && formData.append('region', form.region);
  form.reg_address && formData.append('reg_address', form.reg_address);
  form.chairman && formData.append('chairman', form.chairman);
  form.org_address && formData.append('org_address', form.org_address);
  form.org_phone && formData.append('org_phone', form.org_phone);
  form.org_email && formData.append('org_email', form.org_email);
  form.param_template_name &&
    formData.append('param_template_name', form.param_template_name);

  this.setState({ isSending: true });

  apiPostRequest(
    '/tournaments/new',
    formData,
    (msg, status) => {
      this.setState({ isSending: false });

      this.changeModal(
        requestStatus(
          status,
          () => {
            navigate('/event/' + msg?.id, {
              state: { prevUrl: location.pathname }
            });
          },
          msg,
          null,
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function updateTournament(event, form) {
  event.preventDefault();

  const { selectedCheckboxes } = this.state;
  const { state, triggerTableUpdate } = this.props;
  this.setState({ isSending: true });

  const formData = new FormData();

  formData.append('id', form.tournament_id);
  formData.append('name', form.tournament_name);
  form.description && formData.append('description', form.description);
  formData.append('date', form.date);
  formData.append('tour_time', form.tour_time);
  formData.append('registration_date', form.registration_date);
  formData.append('city', form.city);
  formData.append('address', form.address);
  formData.append('country_id', form.country_id);
  formData.append('type', form.type);
  form.tg_order_type && formData.append('tg_order_type', form.tg_order_type);
  form.places_number && formData.append('places_number', form.places_number);
  form.scoreboard_theme &&
    formData.append('scoreboard_theme', form.scoreboard_theme);
  form.kata_final && formData.append('kata_final', form.kata_final);

  const checkboxFields = [
    'tameshiwari',
    'bronze_fight_enabled',
    'published',
    'no_participant_number',
    'no_country',
    'circle_round',
    'last_name_first',
    'registration_active',
    'section_import_on',
    'limited_reg_on',
    'is_finished',
    'height_needed',
    'reg_judge_form',
    'report_head',
    'sport_rank_needed',
    'use_rep_stamp',
    'use_rep_signature',
    'use_qr',
    'use_notification_win',
    'show_reg_iko',
    'show_reg_qdan',
    'show_reg_patronymic',
    'use_reg_online',
    'show_reg_coach_2',
    'show_reg_city',
    'use_alt_category_name',
    'show_reg_club',
    'show_reg_region'
  ];

  checkboxFields.forEach((key) => {
    formData.append(
      key,
      key === 'registration_active' || key === 'report_head'
        ? +!selectedCheckboxes.includes(key)
        : +selectedCheckboxes.includes(key)
    );
  });

  form.main_judge && formData.append('main_judge', form.main_judge);
  form.secretary && formData.append('secretary', form.secretary);
  form.duration_days && formData.append('duration_days', form.duration_days);
  form.calc_age_date && formData.append('calc_age_date', form.calc_age_date);
  form.tournament_banner &&
    formData.append('tournament_banner', form.tournament_banner);
  form.participant_up_color &&
    formData.append('participant_up_color', form.participant_up_color);
  form.participant_down_color &&
    formData.append('participant_down_color', form.participant_down_color);
  form.reg_time && formData.append('reg_time', form.reg_time);
  form.reg_date && formData.append('reg_date', form.reg_date);
  form.reg_judge_date && formData.append('reg_judge_date', form.reg_judge_date);
  form.region && formData.append('region', form.region);
  form.reg_address && formData.append('reg_address', form.reg_address);
  form.chairman && formData.append('chairman', form.chairman);
  form.org_address && formData.append('org_address', form.org_address);
  form.org_phone && formData.append('org_phone', form.org_phone);
  form.org_email && formData.append('org_email', form.org_email);
  formData.append('rep_head_v', form.rep_head_v);
  formData.append('rep_head_h', form.rep_head_h);
  form.signature_1 && formData.append('signature_1', form.signature_1);
  form.signature_2 && formData.append('signature_2', form.signature_2);

  apiPostRequest(
    '/tournaments/update',
    formData,
    (msg, status) => {
      this.setState({ isSending: false });

      this.changeModal(
        requestStatus(
          status,
          state && triggerTableUpdate,
          msg,
          i18n.t('successfullyUpdated'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function randomPosterTournament(cb) {
  apiPostRequest(
    '/tournaments/random_poster',
    '',
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          cb && cb(msg),
          msg,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function fetchExcelFields(cb) {
  apiPostRequest(
    '/import/field_list',
    null,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(msg),
          msg,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function fetchExcelImport(payload, cb) {
  const formData = new FormData();

  formData.append('file', payload?.file);
  formData.append('fields', payload?.fields);

  apiPostRequest(
    '/import/xls',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(msg),
          msg,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function importExcel(payload, cb) {
  const body = JSON.stringify({
    userId: payload?.userId,
    tournamentId: payload?.tournamentId,
    table: payload?.data
  });

  apiPostRequest(
    '/import/save',
    body,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(msg),
          msg,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function fetchTournamentSchemeOld() {
  // REMOVE THIS FUNC AFTER DEPLOYING NEW BRACKET DESIGN
  const { tournamentId, bracketForm, categoryTypes } = this.state;
  const { selectedTatami, category_type } = bracketForm;
  this.setState({ currentRounds: null });
  apiGetRequest(
    '/get_bracket',
    { tournament_id: tournamentId, category_type },
    (msg, status) => {
      const callback = () => {
        const bracket = JSON.parse(b64DecodeUnicode(msg.data));
        const tatami =
          bracket.Tatamies &&
          selectedTatami &&
          selectedValue(bracket.Tatamies, 'Id', selectedTatami.tatami_id);
        const initialCategoriesArrayWithIndexes =
          tatami && Array.isArray(tatami.Categories)
            ? tatami.Categories.map(() => 0)
            : [];
        const bracketCategoryType =
          category_type && selectedValue(categoryTypes, ID, category_type).name;
        this.setState({
          bracketTatami: tatami,
          bracketCategoryType,
          currentRounds: initialCategoriesArrayWithIndexes
        });
        this.setState({ isAttemptingToEditModalFields: false }, () =>
          this.hideModal()
        );
      };
      this.changeModal(
        requestStatus(status, callback, msg, null, i18n.t('noBracketMsg')),
        status
      );
    }
  );
}

export function fetchBracketOld(payload) {
  this.setState({ currentRounds: null });

  apiGetRequest(
    '/get_bracket',
    {
      tournament_id: payload.tournamentId,
      category_type: payload?.categoryType
    },
    (msg, status) => {
      const callback = () => {
        const bracket = JSON.parse(b64DecodeUnicode(msg.data));
        const tatami =
          bracket.Tatamies &&
          payload.tatamiId &&
          selectedValue(bracket.Tatamies, 'Id', payload.tatamiId, true);
        const initialCategoriesArrayWithIndexes =
          tatami && Array.isArray(tatami.Categories)
            ? tatami.Categories.map(() => 0)
            : [];

        this.setState({
          gridData: tatami,
          bracketCategoryType: `${i18n.t('typeOfCategories')}: ${
            payload?.categoryType
          } `,
          currentRounds: initialCategoriesArrayWithIndexes,
          loading: false
        });
      };

      this.changeModal(
        requestStatus(status, callback, msg, null, i18n.t('noBracketMsg')),
        status
      );
    }
  );
}

export function fetchBracket(payload, cb) {
  const body = JSON.stringify({
    tournamentId: payload.tournamentId,
    typeId: payload?.categoryType,
    block: payload.block,
    tatamiId: payload.tatamiId,
    categoryId: payload.categoryId
  });

  apiPostRequest(
    '/tournament_grid/get',
    body,
    (msg, status) => {
      const callback = () => {
        this.setState({ loading: false, ...(msg ? { gridData: msg } : {}) });
        cb && cb(msg);
      };
      this.changeModal(
        requestStatus(status, callback, msg, null, i18n.t('noBracketMsg')),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function swapParticipants(payload, cb) {
  const body = JSON.stringify({
    tournamentId: payload.tournamentId,
    categoryId: payload.categoryId,
    participantId1: payload?.participantId1,
    participantId2: payload.participantId2
  });

  apiPostRequest(
    '/tournament_grid/swap_participants',
    body,
    (msg, status) => {
      const callback = () => {
        cb && cb();
      };
      this.changeModal(
        requestStatus(status, callback, msg, null, i18n.t('errorBoundary')),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function renumberBracket(payload, cb) {
  const body = JSON.stringify({
    tournamentId: payload.tournamentId
  });

  apiPostRequest(
    '/tournament_grid/renumber_participants',
    body,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(),
          msg,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function fetchAllTBKS(tournID, catType, cb) {
  apiGetRequest(
    '/get_tbks',
    { tournament_id: tournID, category_type: catType },
    (body, status) => {
      if (status === 200) {
        this.setState({ allTBKS: body }, () => cb && cb());
      } else {
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('error')),
          status
        );
      }
    }
  );
}

export function fetchTBK(evt, item) {
  apiGetRequest(
    `/get_tbk`,
    { id: item.id },
    (body, status, fileName) => {
      const blob = new Blob([JSON.stringify(body, null, 2)], {
        type: 'application/json'
      });

      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(blob, fileName),
          body,
          null,
          i18n.t('notFound')
        ),
        status
      );
    },
    null,
    null,
    true
  );
}

export function closeTournament(tournId, action) {
  apiGetRequest(`/finish_tournament/${tournId}`, '', (body, status) => {
    const callback = () => {
      this.fetchTournaments(tournId, null, null, () => action && action());
      this.hideModal();
    };
    this.changeModal(requestStatus(status, callback, body), status);
  });
}

export function removeTBK(evt, tournId, type, tbkID, action) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('tournament_id', tournId);

  if (tbkID) {
    formData.append('tbk_id', tbkID);
  }

  if (type) {
    formData.append('category_type', type);
  }

  apiPostRequest('/remove_tbk', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => action && action(),
        msg,
        i18n.t('deletedSuccessfully'),
        i18n.t('actionFailed')
      ),
      status
    );
  });
}

export function setRegistrationStatus(evt, tournId, status, action) {
  evt.preventDefault();
  const formData = new FormData();
  formData.append('tournament_id', tournId);
  formData.append(
    'status',
    status.includes('SWITCH_REGISTRATION') ? DISABLED : ENABLED
  );

  apiPostRequest(
    '/set_tournament_registration_status',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () =>
            this.fetchTournaments(
              tournId,
              null,
              null,
              () => action && action()
            ),
          msg,
          i18n.t('successfullyUpdated'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    }
  );
}
export function fetchTournamentWinners(id, cb) {
  const { selectedCheckboxes, searchBar, filterWinnersValue, searchByOption } =
    this.state;

  let genderList = [];
  let ageList = [];
  let categoryTypes = [];

  selectedCheckboxes.forEach((item) => {
    if (item?.gender) {
      genderList.push(item.id);
    } else if (item?.age) {
      ageList.push(item.ageInterval);
    } else if (item?.type) {
      categoryTypes.push(item.id);
    }
  });

  let requestData = {
    tournament_id: id
  };

  genderList.length && (requestData.gender_list = JSON.stringify(genderList));
  ageList.length && (requestData.age_list = JSON.stringify(ageList));
  categoryTypes.length &&
    (requestData.category_types = JSON.stringify(categoryTypes));

  searchBar &&
    (searchByOption?.id === '0'
      ? (requestData.participant_search = searchBar)
      : searchByOption?.id === '1'
      ? (requestData.coach_search = searchBar)
      : null);

  filterWinnersValue?.categoryId &&
    (requestData.category_id = filterWinnersValue.categoryId);

  apiGetRequest('/get_tournament_winners', requestData, (winners, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => this.setState({ winners }, () => cb && cb()),
        winners
      ),
      status
    );
  });
}

export function addTatamisToTournament(evt, tournId, tatamis, cb) {
  evt.preventDefault();

  const body = JSON.stringify({
    tournament_id: tournId,
    tournament_tatamis: tatamis
  });

  apiPostRequest('/add_tatami_to_tournament', body, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

//delete func
export function deleteTournament(payload, cb) {
  const formData = new FormData();

  formData.append('id', payload?.tournament_id);
  payload?.force && formData.append('force', payload?.force);
  payload?.hash && formData.append('hash', payload?.hash);

  apiPostRequest(
    '/tournaments/delete',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(msg),
          msg,
          !payload?.shouldGetHash && i18n.t('deletedSuccessfully'),
          i18n.t('error')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function deleteCategoryGroup(id) {
  const formData = new FormData();

  formData.append('id', id);

  apiPostRequest('/delete_category_group', formData, (msg, status) => {
    const callback = () => {
      this.setState({ shouldShowForm: false });
      this.triggerTableUpdate();
      this.hideModal();
    };

    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        i18n.t('deletedSuccessfully'),
        status === 405
          ? i18n.t('deleteGroupCategoryErrorsBcOfCategories')
          : i18n.t('error')
      ),
      status
    );
  });
}

export function deleteCategory() {
  const { categoryId } = this.state;
  const formData = new FormData();

  formData.append('category_id', categoryId);

  apiPostRequest('/remove_category', formData, (msg, status) => {
    const callback = () => {
      this.setState({ shouldShowForm: false });
      this.triggerTableUpdate();
      this.hideModal();
    };

    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        i18n.t('deletedSuccessfully'),
        status === 405
          ? i18n.t('deleteCategoryErrorsBcOfTourn', { name: msg.t_names })
          : i18n.t('error')
      ),
      status
    );
  });
}

export function deleteParticipant(
  fetchData,
  tournament,
  token,
  shouldRemoveAllCategories,
  categoryId,
  isTraining
) {
  const { participantId } = this.state;
  const formData = new FormData();

  formData.append('participant_id', participantId);
  if (tournament) {
    formData.append('tournament_id', tournament);
    if (!isTraining) {
      formData.append(
        'category_id',
        shouldRemoveAllCategories ? null : categoryId
      );
    }
  }
  const requestApi =
    !isTraining && tournament ? '/kick_from_tournament' : '/remove_participant';

  apiPostRequest(
    requestApi,
    formData,
    (msg, status) => {
      const callback = () => {
        this.setState({ showParticipantForm: false });
        fetchData && fetchData();
      };
      const successfulyRemovedMsg = tournament
        ? i18n.t('participantKicked')
        : i18n.t('deletedSuccessfully');
      this.changeModal(
        requestStatus(
          status,
          callback,
          msg,
          successfulyRemovedMsg,
          i18n.t('failToRemoveParticipant')
        ),
        status
      );
    },
    token,
    isTraining && ENVIORNMENT_SCHOOL_PATH
  );
}

export function restoreParticipant(values, cb) {
  const formData = JSON.stringify({
    id: values.id
  });

  apiPostRequest(
    '/participants/restore',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(body),
          body,
          null,
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function deleteTableRow(tableName, id, fetchData) {
  apiGetRequest(
    '/delete_from_table',
    { table_name: tableName, id },
    (msg, status) => {
      const callback = () => {
        fetchData && fetchData();
        this.hideModal();
      };
      this.changeModal(
        requestStatus(
          status,
          callback,
          msg,
          i18n.t('deletedSuccessfully'),
          i18n.t('error')
        ),
        status
      );
    }
  );
}

export function toggleShow(event) {
  const { isPasswordVisible } = this.state;
  const { id } = event.target;
  this.setState((prevState) => ({
    isPasswordVisible: {
      ...prevState.isPasswordVisible,
      [id]: !isPasswordVisible[id]
    }
  }));
}
export function onBlur(evt) {
  const { name } = evt.target;
  this.setState((prevState) => ({
    showError: {
      ...prevState.showError,
      [name]: true
    }
  }));
}
export function updatePassword(evt, form) {
  evt.preventDefault();
  const formData = new FormData();
  formData.append('previous_password', form.previous_password);
  formData.append('new_password_1', form.password);
  formData.append('new_password_2', form.confirm_password);
  formData.append('user_id', localStorage.getItem('user_id'));
  apiPostRequest('/new_password', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => {
          this.setState({ isAttemptingToEditModalFields: false }, () =>
            this.hideModal()
          );
        },
        msg,
        i18n.t('updatedPasswordMsg'),
        i18n.t('wrongCurrentPasswordMsg')
      ),
      status
    );
  });
}

export function submitForm(event, form, action) {
  event.preventDefault();

  const formData = new FormData();

  for (let key in form) {
    if (key === 'club_federation') {
      formData.append('club', form[key]);
    } else {
      formData.append(key, form[key]);
    }
  }

  if (localStorage.getItem('invitation_token')) {
    formData.append('request_token', localStorage.getItem('invitation_token'));
  }

  formData.append('lang', localStorage.getItem('lang') || 'en');

  this.setState({ isSending: true });

  noTokenPostRequest('/register_new_user', formData, (msg, status) => {
    const success = msg.msg === USER_CREATED_SUCCESSFULLY;

    this.setState({ isSending: false });
    const callback = () => {
      this.setState({ singUpForm: {} });
      localStorage.setItem('showSideModal', 'Registration');
      sessionStorage.setItem('successfullyEmailSent', true);
      action && action();
    };

    this.changeModal(
      requestStatus(
        status,
        success && callback,
        msg,
        !success && i18n.t('emailAlreadyUsed'),
        i18n.t('cannotSaveChanges')
      ),
      status,
      success ? null : msg
    );
  });
}

export function resetPassword(event, action) {
  event.preventDefault();

  const { verificationToken, form } = this.state;
  const formData = new FormData();

  formData.append('token', verificationToken);
  for (let key in form) formData.append(key, form[key]);

  this.setState({ isLoading: true });

  noTokenPostRequest('/reset_password', formData, (msg, status) => {
    this.setState({ isLoading: false });
    const callback = () => {
      localStorage.setItem('showSideModal', 'Password'), action && action();
    };
    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('updateFailed')
      ),
      status
    );
  });
}
export function dashboardInfo() {
  apiGetRequest('/get_dashboard_info', '', (res, status) => {
    const callback = () => {
      const { finished_tournaments, upcoming_tournaments, counts } = res;
      this.setState({
        finishedTournaments: finished_tournaments,
        upcomingTournaments: upcoming_tournaments,
        counts
      });
    };
    this.setState({ loading: false });
    this.changeModal(requestStatus(status, callback, res), status);
  });
}
export function fetchParticipationCandidates(tournamentId, cb) {
  apiGetRequest(
    '/get_participation_candidates',
    { tournament_id: tournamentId },
    (body, status) => {
      this.setState(
        { users: Array.isArray(body) ? body : [] },
        () => cb && cb()
      );

      this.changeModal(
        requestStatus(
          status,
          null,
          body,
          null,
          status === 404
            ? i18n.t('noTournamentSpecified')
            : i18n.t('errorBoundary')
        ),
        status
      );
    }
  );
}
//Invite Coaches
export function fetchTemplates(type, fetchData) {
  apiGetRequest('/get_templates', { type: type }, (body, status) => {
    if (status === 200) {
      this.setState({ templates: body }, () => fetchData && fetchData());
    }
    this.changeModal(
      requestStatus(status, null, body, null, i18n.t('error')),
      status
    );
  });
}
export function updateTemplate() {
  const { templateId, templateTxt, type } = this.state;
  const formData = new FormData();
  formData.append('id', templateId);
  formData.append('template', templateTxt);
  apiPostRequest('/update_template', formData, (body, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => this.fetchTemplates(type),
        body,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}
export function sendInvitation(initialState) {
  const { tournamentId, subject, recipients, previewTemplate } = this.state;
  const body = JSON.stringify({
    tournament_id: tournamentId,
    subject: subject,
    message: previewTemplate.replace(/(?:\r\n|\r|\n)/g, '<br>'),
    addresses: recipients
  });
  this.setState({ isSending: true });
  apiPostRequest('/send_invitations', body, (msg, status) => {
    const notSentTo = Array.isArray(msg)
      ? msg.map((it) => [
          ...(it.includes('not sent to') ? [it.split(' ')[3]] : [])
        ])
      : [];
    const failedDelivery = notSentTo.length > 0;
    this.changeModal(
      requestStatus(
        status,
        () => {
          this.setState({ ...initialState, isSending: false });
        },
        msg,
        failedDelivery
          ? [i18n.t('notSentTo'), notSentTo.map((it, idx) => ` ${it}`)].join(
              ' '
            )
          : i18n.t('invitationsSent'),
        i18n.t('error')
      ),
      status,
      failedDelivery
    );
  });
}

export function toggleUserStatus(evt, userId, cb, typeID) {
  evt.preventDefault();

  const { tournamentId } = this.state;

  const formData = new FormData();

  formData.append('tournament_id', tournamentId);
  formData.append('coach_id', userId);
  formData.append('type', typeID);

  apiPostRequest('/toggle_participation_status', formData, (msg, status) => {
    const success =
      msg.msg === PARTICIPATION_DISAPPROVED
        ? i18n.t('participationDisapproved')
        : i18n.t('participationApproved');

    this.changeModal(
      requestStatus(status, () => cb && cb(), msg, success),
      status
    );
  });
}

export function fetchCategoriesPerTourn(tournamentId, fetchData, successMsg) {
  apiGetRequest(
    '/get_categories_pre_tournament',
    { tournament_id: tournamentId },
    (body, status) => {
      const callback = () => {
        this.setState(
          {
            categories: body,
            filteredCategories: body
          },
          () => fetchData && fetchData()
        );
      };

      this.changeModal(
        requestStatus(status, callback, body, successMsg),
        status
      );
    }
  );
}

export function updateTournamentCategoryTime(
  evt,
  tournId,
  list,
  time,
  shouldSetBronze,
  enableBronzeFight,
  cb
) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('tournament_id', tournId);
  formData.append('category_list', JSON.stringify(list));

  if (shouldSetBronze)
    formData.append('bronze_fight_enabled', enableBronzeFight);

  if (time) {
    formData.append('main_time', time.main_time_ms);
    formData.append('extra_time', time.extra_time_ms);
    formData.append('final_main_time', time.final_main_time_ms);
    formData.append('final_extra_time', time.final_extra_time_ms);
  }

  apiPostRequest(
    '/update_tournament_category_time',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          cb && cb(),
          msg,
          i18n.t('successfullyUpdated'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    }
  );
}

export function updateTournamentCategoryKataFinal(evt, tournId, item, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('tournament_id', tournId);
  formData.append('category_id', item.category_id);
  formData.append('value', item.kata_final);

  apiPostRequest(
    '/update_tournament_category_kata_final',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          cb && cb(),
          msg,
          i18n.t('successfullyUpdated'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    }
  );
}

export function toggleTatamiCategoryV2(values, action) {
  const payload = JSON.stringify({
    tournament_id: values?.tournament_id,
    category_presence_list: values?.category_presence_list
    //   ...(values?.category_presence_list
    //     ? { category_id: values?.category_id }
    //     : { category_presence_list: values?.category_presence_list }),
    //   presence: values.presence
  });
  apiPostRequest(
    `/tournaments/category_presence`,
    payload,
    (msg, status) => {
      const success =
        msg.participants_remaining && msg.participants_remaining.length > 0
          ? i18n.t('participantsRemainingMsg')
          : i18n.t('successfullyUpdated');

      this.changeModal(
        requestStatus(
          status,
          () => {
            action && action(msg);
          },
          msg,
          success,
          i18n.t('noAttachedTatamisMsg')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function addCategoriesToTourn(body, action) {
  this.setState({ isSending: true });

  apiPostRequest('/add_categories_to_tournament', body, (msg, status) => {
    const success =
      msg.participants_remaining && msg.participants_remaining.length > 0
        ? i18n.t('participantsRemainingMsg')
        : i18n.t('successfullyUpdated');

    this.setState({ isSending: false });

    if (status === 200) {
      action && action(success);
    } else {
      this.changeModal(
        requestStatus(
          status,
          null,
          msg,
          success,
          i18n.t('noAttachedTatamisMsg')
        ),
        status
      );
    }
  });
}
export function fetchTokenForQuickRegitration(token, fetchData, cb) {
  noTokenApiGetRequest(
    `/get_simple_request_info/${token}`,
    '',
    (body, status) => {
      const callback = () => {
        this.setState(
          { invitationInfo: { ...body } },
          () => fetchData && fetchData()
        );
      };
      status !== 200 && cb && cb();
      this.changeModal(
        requestStatus(status, callback, body, null, i18n.t('error')),
        status
      );
    }
  );
}
export function submitTournApplication(requestToken, tournId, token) {
  const apiRequest = requestToken
    ? `/submit_tournament_application/${requestToken}`
    : '/submit_tournament_application';
  apiGetRequest(
    apiRequest,
    { ...(!requestToken ? { tournament_id: tournId } : {}) },
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          null,
          msg,
          i18n.t('participantRequestSent'),
          i18n.t('error')
        ),
        status
      );
    },
    token
  );
}

export function checkToken(cb) {
  const formData = new FormData();

  formData.append('echo', TRUE);

  apiPostRequest('/check_token', formData, (body, status) => {
    if (status === 200 && localStorage.getItem('token')) {
      this.setState({ authState: { role: localStorage.getItem('role') } });

      body.photo &&
        localStorage.setItem('avatar', serverDefaultPath + body.photo);
      localStorage.setItem('name', [body.first_name, body.last_name].join(' '));
      localStorage.setItem('first_name', body.first_name);
      localStorage.setItem('last_name', body.last_name);
    } else if (status === 401) {
      const lang = localStorage.getItem('i18nextLng');

      localStorage.clear();
      localStorage.setItem('logout-event', `logout_${Math.random()}`);
      localStorage.setItem('i18nextLng', lang);
    }

    cb && cb(body, status);
  });
}

export function sentEmailToResetPassword(evt, form, action) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('email', form.email);

  this.setState({ isSending: true });

  const callback = (msg) => {
    localStorage.setItem('showSideModal', msg.msg);
    sessionStorage.setItem('successfullyEmailSent', true);
    action && action();
  };

  noTokenPostRequest(
    '/reset_password_notification',
    formData,
    (msg, status) => {
      let failedMsg;

      if (status === 404) {
        failedMsg = i18n.t('emailVerficationNoUserMsg');
      } else if (status === 400) {
        failedMsg = i18n.t('emailVerficationFailedMsg');
      }

      this.setState({ isSending: false });

      this.changeModal(
        requestStatus(status, () => callback(msg), msg, null, failedMsg),
        status
      );
    }
  );
}

export function activateAccount() {
  const { userToken } = this.state;
  noTokenApiGetRequest(`/verify_user/${userToken}`, '', (msg, status) => {
    this.setState({ isLoading: false });
    this.changeModal(
      requestStatus(status, () => this.setState({ success: true }), msg),
      status
    );
  });
}

export function signIn(event, form, fetchData) {
  event.preventDefault();

  const formData = new FormData();

  form?.values?.email && formData.append('login', form.values.email);
  form?.values?.user_id && formData.append('user_id', form.values.user_id);
  form?.values?.password && formData.append('password', form.values.password);

  localStorage.getItem('invitation_token') &&
    formData.append('request_token', localStorage.getItem('invitation_token'));

  apiPostRequest('/login', formData, (body, status) => {
    const callback = () => {
      if (
        body.tournament_id &&
        (body.role === 'coach' || body.role === 'master')
      ) {
        localStorage.setItem('invitation_tournId', body.tournament_id);
      }

      localStorage.setItem('name', body.name);
      localStorage.setItem('first_name', body.first_name);
      localStorage.setItem('last_name', body.last_name);
      body.photo &&
        localStorage.setItem('avatar', serverDefaultPath + body.photo);

      localStorage.setItem('token', body.token);
      localStorage.setItem('user_id', body.id);
      localStorage.setItem('role', body.role);

      // Сохраняем support_on в localStorage
      if (body.support_on !== undefined) {
        localStorage.setItem('support_on', JSON.stringify(body.support_on));
      }

      fetchData && fetchData(body.role);
    };

    this.changeModal(
      requestStatus(
        status,
        body.token && callback,
        body,
        !body.token && i18n.t('actionFailed'),
        body.reason === 'LOGIN'
          ? i18n.t('wrongEmail')
          : i18n.t('invalidPassword')
      ),
      status
    );
  });
}

export function onLogout(authContext, cb) {
  const lang = localStorage.getItem('i18nextLng');

  apiPostRequest('/logout', '', (msg, status) => {
    if (status === 200) {
      localStorage.clear();
      localStorage.setItem('logout-event', `logout_${Math.random()}`);
      localStorage.setItem('i18nextLng', lang);
      authContext.changeAuthState('');

      return cb && cb();
    }
  });
}

export function changeDate(date, form, errors, token, tournamentDate) {
  let copyErrors = { ...errors };
  let copyForm = {};

  if (!date) {
    if (this.state?.[form]?.date_required) {
      copyErrors.birthday = i18n.t('required');
    } else {
      delete copyErrors.birthday;
    }
  } else if (
    isNaN(Date.parse(date)) ||
    date > (tournamentDate ? new Date(tournamentDate) : new Date())
  ) {
    copyErrors.birthday = i18n.t('invalidDate');
  } else {
    const today = tournamentDate ? new Date(tournamentDate) : new Date();
    const dateDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const dateMonth =
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const dateYear = date.getFullYear();
    let age =
      (tournamentDate
        ? new Date(tournamentDate).getFullYear()
        : new Date().getFullYear()) - dateYear;
    if (
      today.getMonth() < date.getMonth() ||
      (today.getMonth() === date.getMonth() && today.getDate() < dateDay)
    )
      age--;
    if (age < MIN_AGE || age > MAX_AGE) {
      copyErrors.age = i18n.t('invalidValue');
      if (token) copyErrors.birthday = i18n.t('invalidValue');
    } else {
      delete copyErrors.age;
      delete copyErrors.birthday;
    }
    !token && delete copyErrors.birthday;

    copyForm = {
      age: age,
      birthday: `${dateYear}-${dateMonth}-${dateDay}`,
      birthdayInputDate: new Date(date)
    };
  }

  this.setState((prevState) => ({
    errors: copyErrors,
    [form]: {
      ...prevState[form],
      ...copyForm
    }
  }));
}

export function exportTournamentResultsReport(tournID) {
  const {
    selectedCheckboxesMedalBronze,
    selectedRadioButtonMedalBronze,
    selectedOrientation
  } = this.state;
  const formData = new FormData();

  const orientation =
    selectedOrientation['selectedCheckboxesMedalBronze'] === 'landscape'
      ? 2
      : 1;

  selectedCheckboxesMedalBronze.length > 0 &&
    formData.append(
      'category_types',
      JSON.stringify(selectedCheckboxesMedalBronze)
    );
  formData.append('target', selectedRadioButtonMedalBronze);
  formData.append('orientation', orientation);

  const buttonLockOn = [...selectedCheckboxesMedalBronze, 'downloadFile'];
  this.setState({ selectedCheckboxesMedalBronze: buttonLockOn });

  apiPostRequest(
    `/get_results_report/${tournID}`,
    formData,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesMedalBronze: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportTournamentStatisticReport(tournamentId) {
  const { selectedCheckboxesTournamentStatistic, selectedOrientation } =
    this.state;

  const orientation =
    selectedOrientation['selectedCheckboxesTournamentStatistic'] === 'landscape'
      ? 2
      : 1;

  const params = {
    tournament_id: tournamentId,
    show_country: selectedCheckboxesTournamentStatistic.includes('countries'),
    show_region: selectedCheckboxesTournamentStatistic.includes('regions'),
    show_city: selectedCheckboxesTournamentStatistic.includes('cities'),
    show_club: selectedCheckboxesTournamentStatistic.includes('clubs'),
    show_coach: selectedCheckboxesTournamentStatistic.includes('coaches'),
    orientation: orientation
  };

  const buttonLockOn = [
    ...selectedCheckboxesTournamentStatistic,
    'downloadFile'
  ];
  this.setState({ selectedCheckboxesTournamentStatistic: buttonLockOn });

  apiGetRequest(
    `/get_tournament_statistic_report/${tournamentId}`,
    params,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesTournamentStatistic: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportReportsOfAllTypes(
  tournID,
  typeOfReport,
  selectedCheckboxes
) {
  const { selectedOrientation } = this.state;
  const selectedCheckboxesState = this.state[selectedCheckboxes] || [];

  const orientation =
    selectedOrientation['selectedCheckboxesTournamentStatistic'] === 'landscape'
      ? 2
      : 1;

  const param = {
    orientation:
      selectedOrientation[selectedCheckboxes] === 'landscape' ? 2 : 1,
    show_country: selectedCheckboxesState?.includes('countries'),
    show_region: selectedCheckboxesState?.includes('regions'),
    show_city: selectedCheckboxesState?.includes('cities'),
    show_club: selectedCheckboxesState?.includes('clubs'),
    show_coach: selectedCheckboxesState?.includes('coaches')
  };

  const buttonLockOn = [...selectedCheckboxesState, 'downloadFile'];
  this.setState({ [selectedCheckboxes]: buttonLockOn });

  apiGetRequest(
    `/get_tournament_grid_report/${tournID}/${typeOfReport}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ [selectedCheckboxes]: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportReportsJudgesComposition(tournID, selectedCheckboxes) {
  const { selectedOrientation } = this.state;

  const param = {
    orientation:
      selectedOrientation[selectedCheckboxes] === 'landscape' ? 2 : 1,
    show_country: this.state[selectedCheckboxes]?.includes('countries'),
    show_region: this.state[selectedCheckboxes]?.includes('regions'),
    show_city: this.state[selectedCheckboxes]?.includes('cities'),
    show_club: this.state[selectedCheckboxes]?.includes('club'),
    show_coach: this.state[selectedCheckboxes]?.includes('coach')
  };

  const buttonLockOn = [
    ...(this.state?.[selectedCheckboxes] ?? []),
    'downloadFile'
  ];
  this.setState({ [selectedCheckboxes]: buttonLockOn });

  apiGetRequest(
    `/get_judges_composition_report/${tournID}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ [selectedCheckboxes]: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportReportsJudges(tournID, selectedCheckboxes) {
  const { selectedOrientation } = this.state;

  const param = {
    ...(selectedOrientation
      ? {
          orientation:
            selectedOrientation[selectedCheckboxes] === 'landscape' ? 2 : 1
        }
      : {}),
    ...(this.state[selectedCheckboxes]
      ? {
          show_country: this.state[selectedCheckboxes]?.includes('countries'),
          show_region: this.state[selectedCheckboxes]?.includes('regions'),
          show_city: this.state[selectedCheckboxes]?.includes('cities'),
          show_club: this.state[selectedCheckboxes]?.includes('club'),
          show_coach: this.state[selectedCheckboxes]?.includes('coach')
        }
      : {})
  };

  const buttonLockOn = [
    ...(this.state[selectedCheckboxes] ?? []),
    'downloadFile'
  ];
  this.setState({ [selectedCheckboxes]: buttonLockOn });

  apiGetRequest(
    `/get_judges_report/${tournID}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ [selectedCheckboxes]: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportParticipationCandidatesReport(tournID) {
  const { selectedCheckboxesReportApplicants, selectedOrientation } =
    this.state;

  const param = {
    ...(selectedOrientation
      ? {
          orientation:
            selectedOrientation['selectedCheckboxesReportApplicants'] ===
            'landscape'
              ? 2
              : 1
        }
      : {}),
    ...(selectedCheckboxesReportApplicants
      ? {
          show_country:
            selectedCheckboxesReportApplicants?.includes('countries'),
          show_region: selectedCheckboxesReportApplicants?.includes('regions'),
          show_city: selectedCheckboxesReportApplicants?.includes('cities'),
          show_club: selectedCheckboxesReportApplicants?.includes('clubs'),
          show_coach: selectedCheckboxesReportApplicants?.includes('coaches')
        }
      : {})
  };

  const buttonLockOn = [
    ...(selectedCheckboxesReportApplicants ?? []),
    'downloadFile'
  ];
  this.setState({ selectedCheckboxesReportApplicants: buttonLockOn });

  apiGetRequest(
    `/get_participation_candidates_report/${tournID}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesReportApplicants: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportTournamentCategories(tournID, tatamiPage) {
  let param = {};

  let buttonLockOn = ['downloadFile'];

  if (!tatamiPage) {
    const { selectedCheckboxesTournamentCategories, selectedOrientation } =
      this.state;

    const orientation =
      selectedOrientation['selectedCheckboxesTournamentCategories'] ===
      'landscape'
        ? 2
        : 1;

    param = {
      orientation: orientation
    };

    buttonLockOn = [
      ...(selectedCheckboxesTournamentCategories ?? []),
      'downloadFile'
    ];

    this.setState({ selectedCheckboxesTournamentCategories: buttonLockOn });
  }

  apiGetRequest(
    `/get_tournament_categories_report/${tournID}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );

      this.setState({ selectedCheckboxesTournamentCategories: buttonLockOff });

      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportParticipants(tourn, filters, hideComponentsinExcel) {
  const {
    selectedCheckboxesCoachClub,
    selectedRadioButtonCoachClub,
    selectedOrientation
  } = this.state;

  const param = {
    ...(!hideComponentsinExcel
      ? {
          //It's used on Reports page
          orientation:
            selectedOrientation['selectedCheckboxesCoachClub'] === 'landscape'
              ? 2
              : 1,
          target: selectedRadioButtonCoachClub,
          show_country: selectedCheckboxesCoachClub?.includes('countries'),
          show_region: selectedCheckboxesCoachClub?.includes('regions'),
          show_city: selectedCheckboxesCoachClub?.includes('cities'),
          show_club: selectedCheckboxesCoachClub?.includes('clubs'),
          show_coach: selectedCheckboxesCoachClub?.includes('coaches')
        }
      : {}),
    ...(filters
      ? {
          ...(filters.searchedValue
            ? {
                [+filters?.searchByOption?.id === 0
                  ? 'search_participant'
                  : 'search_coach']: filters.searchedValue
              }
            : {}),
          ...(filters.isInactiveParticipantsChecked
            ? { inactive_participants: filters.isInactiveParticipantsChecked }
            : {}),
          ...(filters.isRetiredParticipantsChecked
            ? { inactive_only: filters.isRetiredParticipantsChecked }
            : {}),
          ...(filters.filteredByCategoryId
            ? { category_id: filters.filteredByCategoryId }
            : {}),
          ...(filters.issues ? { issues: filters.issues } : {}),
          ...(filters.personal ? { personal: filters.personal } : {})
        }
      : {})
  };

  const buttonLockOn = [...(selectedCheckboxesCoachClub ?? []), 'downloadFile'];
  this.setState({ selectedCheckboxesCoachClub: buttonLockOn });

  apiGetRequest(
    `/get_participants_report/${tourn}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesCoachClub: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportJudges(tourn, filters, hideComponentsinExcel) {
  const { selectedCheckboxesCoachClub, selectedRadioButtonCoachClub } =
    this.state;

  let { selectedOrientation } = this.state;

  if (!selectedOrientation) {
    console.warn('selectedOrientation is undefined, setting default value');
    selectedOrientation = 'portrait';
  }

  const param = {
    ...(!hideComponentsinExcel
      ? {
          orientation:
            selectedOrientation['selectedCheckboxesCoachClub'] === 'landscape'
              ? 2
              : 1,
          target: selectedRadioButtonCoachClub,
          show_country: selectedCheckboxesCoachClub?.includes('countries'),
          show_region: selectedCheckboxesCoachClub?.includes('regions'),
          show_city: selectedCheckboxesCoachClub?.includes('cities'),
          show_club: selectedCheckboxesCoachClub?.includes('clubs'),
          show_coach: selectedCheckboxesCoachClub?.includes('coaches')
        }
      : {}),
    ...(filters
      ? {
          ...(filters.searchedValue
            ? {
                [+filters?.searchByOption?.id === 0
                  ? 'search_participant'
                  : 'search_coach']: filters.searchedValue
              }
            : {}),
          ...(filters.isInactiveParticipantsChecked
            ? { inactive_participants: filters.isInactiveParticipantsChecked }
            : {}),
          ...(filters.isRetiredParticipantsChecked
            ? { inactive_only: filters.isRetiredParticipantsChecked }
            : {}),
          ...(filters.filteredByCategoryId
            ? { category_id: filters.filteredByCategoryId }
            : {}),
          ...(filters.issues ? { issues: filters.issues } : {}),
          ...(filters.personal ? { personal: filters.personal } : {})
        }
      : {})
  };

  const buttonLockOn = [...(selectedCheckboxesCoachClub ?? []), 'downloadFile'];
  this.setState({ selectedCheckboxesCoachClub: buttonLockOn });

  apiGetRequest(
    `/get_judges_report/${tourn}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesCoachClub: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function exportCategories(tourn, hideComponentsinExcel) {
  const { selectedCheckboxesCategories, selectedOrientation } = this.state;

  const param = {
    ...(!hideComponentsinExcel
      ? {
          show_country: selectedCheckboxesCategories?.includes('countries'),
          show_region: selectedCheckboxesCategories?.includes('regions'),
          show_city: selectedCheckboxesCategories?.includes('cities'),
          show_club: selectedCheckboxesCategories?.includes('clubs'),
          show_coach: selectedCheckboxesCategories?.includes('coaches'),
          show_history: selectedCheckboxesCategories?.includes('show_history'),
          orientation:
            selectedOrientation['selectedCheckboxesCategories'] === 'landscape'
              ? 2
              : 1
        }
      : {})
  };

  const buttonLockOn = [
    ...(selectedCheckboxesCategories ?? []),
    'downloadFile'
  ];
  this.setState({ selectedCheckboxesCategories: buttonLockOn });

  apiGetRequest(
    `/get_categories_report/${tourn}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesCategories: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}
export function exportWinners(tourn) {
  const { selectedCheckboxesWinners } = this.state;

  const param = {
    show_country: selectedCheckboxesWinners.includes('countries'),
    show_region: selectedCheckboxesWinners.includes('regions'),
    show_city: selectedCheckboxesWinners.includes('cities'),
    show_club: selectedCheckboxesWinners.includes('clubs'),
    show_coach: selectedCheckboxesWinners.includes('coaches')
  };

  apiGetRequest(
    `/get_winners_report/${tourn}`,
    param,
    (body, status, fileName) => {
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}
export function exportRegistration(tourn) {
  const { selectedCheckboxesRegistration, selectedOrientation } = this.state;

  const orientation =
    selectedOrientation['selectedCheckboxesRegistration'] === 'landscape'
      ? 2
      : 1;

  const param = {
    orientation: orientation,
    show_country: selectedCheckboxesRegistration?.includes('countries'),
    show_region: selectedCheckboxesRegistration?.includes('regions'),
    show_city: selectedCheckboxesRegistration?.includes('cities'),
    show_club: selectedCheckboxesRegistration?.includes('clubs'),
    show_coach: selectedCheckboxesRegistration?.includes('coaches')
  };

  const buttonLockOn = [...selectedCheckboxesRegistration, 'downloadFile'];
  this.setState({ selectedCheckboxesRegistration: buttonLockOn });

  apiGetRequest(
    `/get_registrations_report/${tourn}`,
    param,
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesRegistration: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}
export function exportRunner(tourn) {
  const { selectedCheckboxesRunners } = this.state;

  const param = {
    tournament_id: tourn
  };

  const buttonLockOn = [...selectedCheckboxesRunners, 'downloadFile'];
  this.setState({ selectedCheckboxesRunners: buttonLockOn });

  apiGetRequest('/recorder/get_runner', param, (body, status, fileName) => {
    const buttonLockOff = buttonLockOn.filter(
      (item) => item !== 'downloadFile'
    );
    this.setState({ selectedCheckboxesRunners: buttonLockOff });
    this.changeModal(
      requestStatus(
        status,
        () => this.downloadFile(body, fileName),
        body,
        null,
        i18n.t('noReport')
      ),
      status
    );
  });
}
export function exportRating(tourn) {
  const { selectedCheckboxesRatting, selectedOrientation } = this.state;

  const orientation =
    selectedOrientation['selectedCheckboxesRatting'] === 'landscape' ? 2 : 1;

  const param = {
    tournament_id: tourn,
    as_excel: 1,
    orientation,
    show_country: selectedCheckboxesRatting?.includes('countries'),
    show_region: selectedCheckboxesRatting?.includes('regions'),
    show_city: selectedCheckboxesRatting?.includes('cities'),
    show_club: selectedCheckboxesRatting?.includes('clubs'),
    show_coach: selectedCheckboxesRatting?.includes('coaches')
  };

  const buttonLockOn = [...selectedCheckboxesRatting, 'downloadFile'];
  this.setState({ selectedCheckboxesRatting: buttonLockOn });

  apiGetRequest('/skirmish/get_rating', param, (body, status, fileName) => {
    const buttonLockOff = buttonLockOn.filter(
      (item) => item !== 'downloadFile'
    );
    this.setState({ selectedCheckboxesRatting: buttonLockOff });
    this.changeModal(
      requestStatus(
        status,
        () => this.downloadFile(body, fileName),
        body,
        null,
        i18n.t('noReport')
      ),
      status
    );
  });
}
export function exportParticipantNumbers(tourn) {
  const { selectedCheckboxesParticipants } = this.state;

  const buttonLockOn = [...selectedCheckboxesParticipants, 'downloadFile'];
  this.setState({ selectedCheckboxesParticipants: buttonLockOn });

  apiGetRequest(
    `/pdf_participant_numbers/${tourn}`,
    '',
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesParticipants: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function downloadFile(blob, fileName, actions) {
  let url = URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
  actions && actions();
}

export function getCertificates(payload, cb) {
  const params = {
    ...(payload?.tournament_id ? { tournament_id: payload.tournament_id } : {})
  };
  apiGetRequest('/get_certificates', params, (body, status) => {
    cb && cb(body);
    this.changeModal(requestStatus(status, null, body), status);
  });
}

export function uploadCertificate(evt, payload, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('tournament_id', payload?.tournament_id);
  formData.append('certificate_id', payload.certificate_id);
  formData.append('certificate_type', payload.certificate_locale_id);

  const scaleX = payload.scaleX;
  const scaleY = payload.scaleY;
  const scale = Math.min(scaleX, scaleY);

  payload?.textFields.map((field) => {
    if (field.name !== ADDITIONAL_FIELD) {
      field?.params?.map((it) => {
        if (it?.isActive) {
          const result = [
            Math.round(it.x / scaleX),
            Math.round(it.y / scaleY),
            Math.round(it.fontSize / scale),
            it?.width,
            '',
            it.fontFamily
          ].join('|');

          return formData.append(`${[field.name]}`, result);
        }
      });
    } else {
      const multipleVal = field?.params?.map((it) => {
        if (it?.isActive) {
          return [
            Math.round(it.x / scaleX),
            Math.round(it.y / scaleY),
            Math.round(it.fontSize / scale),
            it?.width,
            it.label ? it.label : '',
            it.fontFamily
          ].join('|');
        }
      });

      multipleVal[0] &&
        formData.append('additional_field', JSON.stringify(multipleVal));
    }
  });

  apiPostRequest('/upload_certificate', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function fetchUploadedFiles(id, tourn, token, action) {
  const param = {
    ...(id ? { id: id } : {}),
    ...(tourn ? { tournament_id: tourn } : {})
  };
  apiGetRequest(
    '/uploaded_files',
    param,
    (body, status) => {
      this.setState(
        { uploadedFiles: [...(body ?? [])] },
        () => action && action()
      );
      this.changeModal(requestStatus(status, null, body), status);
    },
    token
  );
}
export function downloadUplaodedFile(id, token, fetchData, dontShowSnackbar) {
  apiGetRequest(
    '/download_file',
    { id },
    (body, status, fileName) => {
      if (status === 200) {
        fetchData ? fetchData(body) : this.downloadFile(body, fileName);
      }

      (!dontShowSnackbar || status !== 200) &&
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('failed2Load')),
          status
        );
    },
    token
  );
}
export function uploadFile(evt, fileData, tournamentId, action) {
  evt.preventDefault();
  const formData = new FormData();
  Array.isArray(fileData.files) &&
    fileData.files[0] &&
    formData.append('file', fileData.files[0]);
  formData.append('type', fileData.type);
  formData.append('tournament_id', tournamentId);
  fileData.id && formData.append('id', fileData.id);
  fileData.attachment_name && formData.append('name', fileData.attachment_name);
  fileData.tournament_file_type &&
    formData.append('tour_file_type', fileData.tournament_file_type);
  if (fileData.allowUser) {
    formData.append('allow_all', fileData.allowUser === '1');
    formData.append('allow_recorder_in', fileData.allowUser === '2');
    formData.append('allow_coach_in', fileData.allowUser === '3');
  }
  apiPostRequest('/upload_file', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => action && action(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}
export function deleteUploadedFile(evt, fileId, action) {
  evt.preventDefault();
  const formData = new FormData();
  formData.append('id', fileId);
  apiPostRequest('/delete_file', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => action && action(),
        msg,
        i18n.t('deletedSuccessfully'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function newRegistrarCoach(evt, form, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('first_name', form.first_name);
  formData.append('last_name', form.last_name);
  formData.append('username', form.username);
  formData.append('password', form.password);
  formData.append('role', form.role_id);

  apiPostRequest('/new_slave_user', formData, (msg, status) => {
    const currentUsername = form.username;

    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('fail2AddUser', { name: currentUsername })
      ),
      status
    );
  });
}

export function updateRegistarCoach(evt, form, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('id', form.id);
  formData.append('first_name', form.first_name);
  formData.append('last_name', form.last_name);
  formData.append('username', form.username);
  form.password && formData.append('password', form.password);
  formData.append('role', form.role_id);
  form.status_id && formData.append('status', form.status_id);

  apiPostRequest('/update_slave_user', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function fetchRegistrarsCoaches(role, cb) {
  apiGetRequest(
    '/get_slave_users',
    { ...(role ? { role } : {}) },
    (body, status) => {
      this.setState(
        {
          loading: false,
          users: body,
          filteredUsers: body
        },
        () => cb && cb()
      );

      if (status !== 200) {
        this.changeModal(requestStatus(status, null, body), status);
      }
    }
  );
}

export function deleteRegistrarsCoaches(evt, id, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('id', id);

  apiPostRequest('/delete_slave_user', formData, (msg, status) => {
    const callback = () => {
      this.setState({ shouldShowForm: false });
      cb && cb();
    };

    this.changeModal(
      requestStatus(
        status,
        callback,
        msg,
        i18n.t('deletedSuccessfully'),
        i18n.t('error')
      ),
      status
    );
  });
}

export function setPermissions(evt, id, permissions, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('id', id);
  formData.append('name_value_list', JSON.stringify(permissions));

  apiPostRequest('/save_slave_user_permissions', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function setPermissionRegistrarCoach(evt, id, setPermission, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('id', id);
  formData.append('name', setPermission.name);
  setPermission.value && formData.append('value', setPermission.value);

  apiPostRequest('/set_slave_user_permission', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function fetchPermissionRegistrarCoach(
  id,
  shouldShowPermissionsModal,
  cb
) {
  apiGetRequest('/get_slave_user_permissions', { id }, (body, status) => {
    this.setState(
      {
        shouldShowPermissionsModal,
        allPermissions: body
      },
      cb && cb(body)
    );

    if (status !== 200) {
      this.changeModal(
        requestStatus(status, null, body, null, i18n.t('cannotSaveChanges')),
        status
      );
    }
  });
}

export function resetPermissionRegistrarCoach(evt, permissionId, cb) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('permission_id', permissionId);

  apiPostRequest('/reset_slave_user_permissions', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function getApps(cb) {
  apiGetRequest('/get_apps', '', (body, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => this.setState({ apps: body }, () => cb && cb()),
        body
      ),
      status
    );
  });
}

export function getApp(item, hasValue) {
  this.setState({
    isDownloading: true,
    appId: item.id
  });

  apiGetRequest(
    '/get_app',
    hasValue ? { id: item.id } : { type: item.app_type },
    (body, status, fileName) => {
      const failedMsg =
        status === 404
          ? i18n.t('appNotFound')
          : status === 410
          ? i18n.t('deletedApp')
          : i18n.t('noRights');

      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          [i18n.t('actionFailed'), failedMsg].join('. ')
        ),
        status
      );

      this.setState({ isDownloading: false });
    }
  );
}

export function getInvitationToken(tournId, cb) {
  const formData = new FormData();

  formData.append('tournament_id', tournId);

  apiPostRequest('/get_invitation_token', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () =>
          this.setState(
            { invitationToken: msg.token },
            () => cb && cb(msg.token)
          ),
        msg
      ),
      status
    );
  });
}

export function onScroll() {
  const scrollToTopBtn = document.getElementById('scroll_to_top');
  let lastKnownScrollPosition = window.scrollY;

  if (lastKnownScrollPosition > 20 && window.innerWidth < 901) {
    scrollToTopBtn && (scrollToTopBtn.style.display = 'block');
  } else {
    scrollToTopBtn && (scrollToTopBtn.style.display = 'none');
  }
}

export function validateForm(
  evt,
  defaultForm,
  currentForm,
  errorsObjName,
  action,
  showAllErrors
) {
  evt.persist();

  const len = Object.keys(defaultForm).length;
  let errors = {};

  for (let i = 0; i < len; i++) {
    const param = Object.keys(defaultForm)[i];

    if (
      !currentForm[param] ||
      (Array.isArray(currentForm?.[param]) && currentForm?.[param].length === 0)
    ) {
      errors[param] = i18n.t('required');
    }
  }

  this.setState(
    (prevState) => ({
      [errorsObjName]: {
        ...prevState[errorsObjName],
        ...errors
      }
    }),
    () => {
      if (Object.keys(this.state[errorsObjName]).length === 0) {
        action && action();
      } else {
        //for some fields, errors are displayed after onBlur, you set onBlur to true for all empty required fields
        showAllErrors && showAllErrors();
      }
    }
  );
}

//multiple categories
export function applyCategories(form, action, noToken) {
  const { selectedCategories, participantId, categoriesFitsParticipant } =
    this.state;
  //here the categories are filtered again to identify the issue for the categories that were assigned erroneously
  const personalAssignedCategories = selectedCategories
    .filter(
      (val, idx, array) =>
        idx === array.findIndex((it) => `${it.id}` === `${val.id}`) &&
        val.id !== ''
    )
    .map((it) => ({
      ...it,
      ...{
        issue: !selectedValue(
          categoriesFitsParticipant,
          CATEGORY_ID,
          `${it.id}`
        )
      }
    }));

  action && action(personalAssignedCategories);

  if (!noToken || (form && form.id === participantId)) {
    this.setState({
      personalAssignedCategories: [...personalAssignedCategories]
    });
  }

  this.setState({ isAttemptingToEditModalFields: false }, () =>
    this.hideModal()
  );
}

export function selectCheckbox(evt, form, tourn, shouldShowDiscardChanges) {
  const { checked } = evt.target;
  const { selectedCategories } = this.state;

  this.setState({ isFilterChecked: checked }, () => {
    this.fetchCategories(
      () => {
        const filteredCategories = selectedCategories.map((it) => ({
          ...it,
          filteredCategories:
            Array.isArray(this.state.filteredCategories) &&
            (!it.type
              ? this.state.filteredCategories
              : this.state.filteredCategories.filter(
                  (cat) => `${cat.category_type_id}` === `${it.type}`
                ))
        }));

        this.setState({
          selectedCategories: filteredCategories,
          ...(shouldShowDiscardChanges ? { isEditing: true } : {})
        });
      },
      tourn,
      null,
      null,
      null,
      this.state.isFilterChecked,
      form
    );
  });
}

export function addNewCategory(initialStateCategory) {
  this.setState((prevState) => ({
    selectedCategories: [
      ...prevState.selectedCategories,
      {
        ...initialStateCategory,
        filteredCategories: this.state.filteredCategories
      }
    ]
  }));
}

export function removeCategory(key) {
  const { selectedCategories } = this.state;
  let copySelectedCategories = [...selectedCategories];

  if (copySelectedCategories?.length > 1) {
    copySelectedCategories = copySelectedCategories.filter((it) => it !== key);
  } else {
    copySelectedCategories[0] = {
      ...copySelectedCategories[0],
      id: '',
      team_id: '',
      type: ''
    };
  }

  this.setState({ selectedCategories: copySelectedCategories });
}

export function selectMultipleCategories(_, value, key, form, error) {
  if (value !== null) {
    const len = form.length;
    let assignCategories = [];
    for (let i = 0; i < len; i++) {
      const item = form[i];
      if (i === key) {
        assignCategories = [
          ...assignCategories,
          {
            ...item,
            id: value.category_id,
            type: value.category_type_id,
            name: value.category_name
          }
        ];
      } else {
        assignCategories = [...assignCategories, item];
      }
      this.setState({
        selectedCategories: assignCategories,
        isEditing: true
      });
    }
    if (error) {
      delete error.id;
      delete error.type;
    }
  }
}

export function selectMultipleCategoryType(_, value, key, form, error) {
  if (value !== null) {
    const len = form?.length;
    let assignCategoryTypes = [];

    for (let i = 0; i < len; i++) {
      const item = form[i];

      if (i === key) {
        assignCategoryTypes = [
          ...assignCategoryTypes,
          {
            ...item,
            type: value.id,
            filteredCategories:
              Array.isArray(this.state.filteredCategories) &&
              this.state.filteredCategories.filter(
                (it) => +it?.category_type_id === +value?.id
              )
          }
        ];
      } else {
        assignCategoryTypes = [...assignCategoryTypes, item];
      }

      this.setState({
        selectedCategories: assignCategoryTypes,
        isEditing: true
      });
    }

    if (error) delete error.type;
  }
}

export function selectMultipleTeams(_, value, key, form, error) {
  if (value !== null) {
    const len = form.length;
    let assignTeams = [];
    for (let i = 0; i < len; i++) {
      const item = form[i];
      if (i === key) {
        assignTeams = [...assignTeams, { ...item, team_id: value.team_id }];
      } else {
        assignTeams = [...assignTeams, item];
      }
      this.setState({
        selectedCategories: assignTeams,
        isEditing: true
      });
    }
    if (error) delete error.team_id;
  }
}

export function validateMultipleCategoriesAssignment(action) {
  const { selectedCategories } = this.state;
  const len = selectedCategories.length;
  let errors = [];

  for (let i = 0; i < len; i++) {
    const item = selectedCategories[i];

    errors = [
      ...errors,
      {
        ...(item.id ? {} : { id: i18n.t('required') }),
        ...(item.type ? {} : { type: i18n.t('required') }),
        ...(item.type !== '3' || item.team_id
          ? {}
          : { team_id: i18n.t('required') })
      }
    ];
  }

  this.setState({ selectedCategoriesErrors: errors }, () => {
    if (errors.every((it) => Object.keys(it).length === 0)) {
      action && action();
    }
  });
}

export function selectCheckboxSchool(
  evt,
  formObj,
  formParam,
  item,
  shouldShowDiscardChanges
) {
  const { checked } = evt.target;

  this.setState((prevState) => ({
    [formParam]: {
      ...prevState[formParam],
      [item]: checked,
      ...(item === TRAINING && !checked
        ? { next_qdan_id: null, nextQdan: false }
        : {}),
      ...(item === NEXT_QDAN
        ? checked
          ? {
              next_qdan_id: formObj.qdan ? `${+formObj.qdan + 1}` : '0'
            }
          : { next_qdan_id: null }
        : {})
    },
    ...(shouldShowDiscardChanges ? { isEditing: true } : {})
  }));
}

export function setTatamiConfig(
  evt,
  tournID,
  tatamiConfig,
  cb,
  shouldSetBlockName
) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('tournament_id', tournID);

  if (shouldSetBlockName) {
    formData.append('block_id', tatamiConfig.tatami_block);
    formData.append('name', tatamiConfig.block_name);
    formData.append('day', tatamiConfig.day);
  } else {
    formData.append('tatami_id', tatamiConfig.tatami_id);
    formData.append('theme_id', tatamiConfig.theme_id);
    formData.append('link_1', tatamiConfig.translation_link_1);
  }

  apiPostRequest('/set_tatami_config', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => {
          cb && cb();
        },
        msg,
        i18n.t('successfullyUpdated')
      ),
      status
    );
  });
}

export function setParticipantToSchool(evt, form, tourn, partId, cb) {
  evt.preventDefault();

  const formData = new FormData();
  if (tourn && tourn.id) {
    formData.append('participant_id', partId);
    formData.append('tournament_id', tourn.id);
    form.next_qdan_id && formData.append('qdan_id', form.next_qdan_id);
  }

  apiPostRequest(
    '/set_participant',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => {
            this.setState({ isAttemptingToEditModalFields: false }, () => {
              cb && cb();
              this.hideModal();
            });
          },
          msg,
          i18n.t('successfullyUpdated')
        ),
        status
      );
    },
    null,
    ENVIORNMENT_SCHOOL_PATH
  );
}

export function fetchTrainingSchoolParticipants(tournId) {
  apiGetRequest(
    '/get_participants',
    { tournament_id: tournId },
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => this.setState({ apps: body }), body),
        status
      );
    },
    null,
    ENVIORNMENT_SCHOOL_PATH
  );
}

export function fetchFederation(cb) {
  apiPostRequest(
    '/get_federation',
    '',
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    },
    null,
    API_URL_FEDERATION
  );
}

export function fetchMembers(cb) {
  apiPostRequest(
    '/get_members',
    '',
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    },
    null,
    API_URL_FEDERATION
  );
}

export function fetchPayments(
  partID,
  startDate,
  endDate,
  search,
  sortDirection,
  sortField,
  cb
) {
  const formData = JSON.stringify({
    participantId: partID,
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
    ...(search ? { search } : {}),
    ...(sortDirection ? { sortDirection } : {}),
    ...(sortField ? { sortField } : {})
  });

  apiPostRequest(
    '/get_payments',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    },
    null,
    API_URL_FEDERATION
  );
}

export function addPayment(partID, paymentForm, cb) {
  const formData = JSON.stringify({
    participantId: partID,
    payType: paymentForm.payType,
    amount: paymentForm.amount,
    currency: paymentForm.currency,
    operationDate: paymentForm.operationDate,
    comment: paymentForm.comment
  });

  apiPostRequest(
    '/add_payment',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => this.setState({ paymentForm: {} }, () => cb && cb()),
          body
        ),
        status
      );
    },
    null,
    API_URL_FEDERATION
  );
}

export function deletePayment(evt, partID, action) {
  evt.preventDefault();
  const formData = JSON.stringify({ id: partID });
  apiPostRequest(
    '/delete_payment',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => action && action(),
          msg,
          null,
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    },
    null,
    API_URL_FEDERATION
  );
}

export function updateTournKataNames(evt, tournID, selectedKataNames, action) {
  evt.preventDefault();

  const formData = new FormData();

  formData.append('tournament_id', tournID);
  formData.append('category_id', selectedKataNames.category_id);
  formData.append('round_1_list', JSON.stringify(selectedKataNames.round_1));
  formData.append('round_2_list', JSON.stringify(selectedKataNames.round_2));

  if (+selectedKataNames.category_type === 5) {
    formData.append('round_3_list', JSON.stringify(selectedKataNames.round_3));
  }

  apiPostRequest(
    '/update_tournament_category_kata_name',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => {
            this.setState({ isAttemptingToEditModalFields: false });
            action && action();
          },
          msg,
          null,
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    }
  );
}

export function changeHead(tournamentData, title) {
  changeTitle(
    [title, tournamentData ? ` - ${tournamentData.tournament_name}` : ''].join(
      ' '
    )
  );
}

export function fetchKataNames() {
  apiGetRequest('/get_kata_names', '', (body, status) => {
    this.changeModal(
      requestStatus(status, () => this.setState({ kata_names: body }), body),
      status
    );
  });
}

export function fetchReference(alias, cb, info, lang) {
  const params = {
    info: info ? 1 : 0,
    ...(lang && { lang: lang })
  };

  apiGetRequest(`/get_reference/${alias}`, params, (body, status) => {
    this.changeModal(
      requestStatus(status, () => cb && cb(body), body),
      status
    );
  });
}

export function fetchReferenceRegion(id, otherParam, cb) {
  apiGetRequest(`/get_reference/${id}`, '', (body, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => {
          this.setState({ [otherParam ? otherParam : 'allRegions']: body });
          cb && cb(body);
        },
        body
      ),
      status
    );
  });
}

export function fetchTournRegistrar(tournID, typeID, cb) {
  const formData = JSON.stringify({
    tournamentId: tournID,
    type: typeID
  });

  apiPostRequest(
    '/tournaments/get_registrars',
    formData,
    (body, status) => {
      cb && cb(Array.isArray(body) ? body : []);

      if (status !== 200) {
        this.changeModal(
          requestStatus(
            status,
            null,
            body,
            null,
            status === 404
              ? i18n.t('noTournamentSpecified')
              : i18n.t('errorBoundary')
          ),
          status
        );
      }
    },
    null,
    API_URL_V2
  );
}

export function fetchTournInvitations(payload, cb) {
  const body = JSON.stringify({
    tournament_id: payload?.tournamentId
  });

  apiPostRequest(
    '/tournaments/get_invitations',
    body,
    (body, status) => {
      cb && cb(Array.isArray(body) ? body : []);

      if (status !== 200) {
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('errorBoundary')),
          status
        );
      }
    },
    null,
    API_URL_V2
  );
}

export function saveTournInvitations(payload, cb) {
  const emailList =
    payload?.isMultiInvitations &&
    payload?.selectedCheckboxes?.map((it) => it?.id);

  const body = JSON.stringify({
    tournament_id: payload?.tournamentId,
    ...(payload?.isMultiInvitations
      ? { user_ids: emailList }
      : { user_id: payload?.id })
  });

  apiPostRequest(
    '/tournaments/save_invitations',
    body,
    (body, status) => {
      cb && cb(Array.isArray(body) ? body : []);

      if (status !== 200) {
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('errorBoundary')),
          status
        );
      }
    },
    null,
    API_URL_V2
  );
}

export function deleteTournInvitations(payload, cb) {
  const body = JSON.stringify({
    tournament_id: payload?.tournamentId,
    id: payload?.id
  });

  apiPostRequest(
    '/tournaments/delete_invitations',
    body,
    (body, status) => {
      if (status !== 200) {
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('errorBoundary')),
          status
        );
      } else {
        cb && cb();
      }
    },
    null,
    API_URL_V2
  );
}

export function fetchTournamnetInformation(payload, cb, onCustomError) {
  const formData = JSON.stringify({
    id: payload.id
  });

  apiPostRequest(
    '/participants/results',
    formData,
    (body, status) => {
      if (status === 200) {
        cb && cb(body);
      } else {
        if (onCustomError) {
          onCustomError();
        } else {
          changeModal(requestStatus(status, null, body), status);
        }
      }
    },
    null,
    API_URL_V2
  );
}

export function usersRegisterNewUser(evt, tournID, values, cb) {
  evt.preventDefault();

  const formData = JSON.stringify({
    request_token: localStorage.getItem('token'),
    tournament_id: tournID,
    first_name: values.first_name,
    last_name: values.last_name,
    patronymic: values.patronymic,
    email: values.email,
    birthday: values.birthday,
    gender: values.gender,
    branch_chief: values.branch_chief,
    coach_first_name: values.coach_first_name,
    coach_last_name: values.coach_last_name,
    country_id: values.country_id,
    region: values.region,
    city: values.city,
    club: values.club,
    phone_number: values.phone,
    website: values.website
  });

  apiPostRequest(
    '/users/register_new_user',
    formData,
    (body, status) => {
      const errorMsg =
        body?.msg === 'already exists'
          ? i18n.t('emailAlreadyUsed')
          : i18n.t('cannotSaveChanges');

      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(body),
          body,
          i18n.t('successfullyAdded'),
          errorMsg
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function fetchStaff(tournID, userID, cb, shouldShowSnackbar, filters) {
  const formData = JSON.stringify({
    tournament_id: tournID,
    search: filters?.search,
    approved: filters?.approved,
    personal: filters?.personal,
    ...(userID ? { user_id: userID } : {})
  });

  apiPostRequest(
    '/judges/get',
    formData,
    (body, status) => {
      if (status === 200 && !shouldShowSnackbar) {
        cb && cb(body);
      } else {
        this.changeModal(
          requestStatus(
            status,
            cb && cb(body),
            body,
            i18n.t('successfullyUpdated'),
            status === 404
              ? i18n.t('noTournamentSpecified')
              : i18n.t('errorBoundary')
          ),
          status
        );
      }
    },
    null,
    API_URL_V2
  );
}

export function deleteStaff(values, cb) {
  const formData = JSON.stringify({ id: values?.id });

  apiPostRequest(
    '/judges/delete',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(
          status,
          cb && cb(body),
          body,
          i18n.t('deletedSuccessfully'),
          i18n.t('errorBoundary')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function addNewStaff(evt, values, cb) {
  evt.preventDefault();

  const formData = JSON.stringify({
    tournamentId: values.tournament_id,
    userId: values.registrar_id,
    firstName: values.first_name,
    lastName: values.last_name,
    ...(values.patronymic ? { patronymic: values.patronymic } : {}),
    ...(values.first_name_national
      ? { firstNameInt: values.first_name_national }
      : {}),
    ...(values.last_name_national
      ? { lastNameInt: values.last_name_national }
      : {}),
    gender: values.gender,
    birthday: values.birthday,
    ...(values.qdan ? { qdanId: values.qdan } : {}),
    countryId: values.country_id,
    ...(values.region ? { region: values.region } : {}),
    ...(values.city ? { city: values.city } : {}),
    ...(values.phone ? { phone: values.phone } : {}),
    ...(values.email ? { email: values.email } : {}),
    ...(values.club ? { club: values.club } : {}),
    ...(values.positionId ? { positionId: values.positionId } : {}),
    ...(values.qual_rf ? { qualificationRfRid: values.qual_rf } : {}),
    ...(values.experience ? { experience: values.experience } : {}),
    ...(values.qual_iko ? { qualificationIkoRid: values.qual_iko } : {}),
    ...(values.history ? { history: values.history } : {}),
    ...(values.file ? { file: values.file } : {}),
    ...(values.days ? { days: values.days.map((it) => +it?.id) } : {})
  });

  apiPostRequest(
    '/judges/new',
    formData,
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => cb && cb(msg),
          msg,
          i18n.t('successfullyAdded'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function updateStaff(evt, values, cb, shouldHideSnackbar) {
  evt.preventDefault();

  const formData = JSON.stringify({
    id: values.id,
    tournamentId: values.tournament_id,
    userId: values.registrar_id,
    firstName: values.first_name,
    lastName: values.last_name,
    ...(values.patronymic ? { patronymic: values.patronymic } : {}),
    ...(values.first_name_national
      ? { firstNameInt: values.first_name_national }
      : {}),
    ...(values.last_name_national
      ? { lastNameInt: values.last_name_national }
      : {}),
    birthday: values.birthday,
    ...(values.qdan ? { qdanId: values.qdan } : {}),
    gender: values.gender,
    countryId: values.country_id,
    ...(values.region ? { region: values.region } : {}),
    ...(values.city ? { city: values.city } : {}),
    ...(values.phone ? { phone: values.phone } : {}),
    ...(values.email ? { email: values.email } : {}),
    ...(values.club ? { club: values.club } : {}),
    positionId: values.positionId,
    status: values.status ?? 0,
    ...(values.qual_rf ? { qualificationRfRid: values.qual_rf } : {}),
    ...(values.experience ? { experience: values.experience } : {}),
    ...(values.qual_iko ? { qualificationIkoRid: values.qual_iko } : {}),
    ...(values.history ? { history: values.history } : {}),
    ...(values.file ? { file: values.file } : {}),
    ...(values.days ? { days: values.days.map((it) => +it?.id) } : {})
  });

  apiPostRequest(
    '/judges/update',
    formData,
    (msg, status) => {
      if (status === 200 && shouldHideSnackbar) {
        cb && cb(msg);
      } else {
        this.changeModal(
          requestStatus(
            status,
            () => cb && cb(msg),
            msg,
            i18n.t('successfullyUpdated'),
            i18n.t('cannotSaveChanges')
          ),
          status
        );
      }
    },
    null,
    API_URL_V2
  );
}

export function addStaffTeam(tjId, teamID, cb, shouldHideSnackbar) {
  const formData = JSON.stringify({
    tj_id: tjId,
    team_n: teamID
  });

  apiPostRequest(
    '/judges/add_team',
    formData,
    (body, status) => {
      if (status === 200 && shouldHideSnackbar) {
        cb && cb(body);
      } else {
        this.changeModal(
          requestStatus(status, () => cb && cb(body), body),
          status
        );
      }
    },
    null,
    API_URL_V2
  );
}

export function delStaffTeam(teamID, cb) {
  const formData = JSON.stringify({ id: teamID });

  apiPostRequest(
    '/judges/del_team',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function addStaffTatami(tjId, tatamiID, cb) {
  const formData = JSON.stringify({
    tj_id: tjId,
    tatami_id: tatamiID
  });

  apiPostRequest(
    '/judges/add_tatami',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function delStaffTatami(id, cb) {
  const formData = JSON.stringify({ id: id });

  apiPostRequest(
    '/judges/del_tatami',
    formData,
    (body, status) => {
      this.changeModal(
        requestStatus(status, () => cb && cb(body), body),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function SeeModal(message, changeModal) {
  if (localStorage.getItem('showSideModal')) {
    changeModal(message, 200);
    localStorage.removeItem('showSideModal');
  }
}

export function exportTournamentTatamiReport(tournID) {
  const { selectedCheckboxesCategoryDistribution, selectedOrientation } =
    this.state || {};

  const orientation =
    selectedOrientation &&
    selectedOrientation['selectedCheckboxesCategoryDistribution'] ===
      'landscape'
      ? 2
      : 1;

  const param = {
    orientation: orientation
  };

  const buttonLockOn = [
    ...(selectedCheckboxesCategoryDistribution ?? []),
    'downloadFile'
  ];
  this.setState({ selectedCheckboxesCategoryDistribution: buttonLockOn });

  apiGetRequest(
    `/get_tournament_tatami_report/${tournID}`,
    selectedCheckboxesCategoryDistribution ? param : '',
    (body, status, fileName) => {
      const buttonLockOff = buttonLockOn.filter(
        (item) => item !== 'downloadFile'
      );
      this.setState({ selectedCheckboxesCategoryDistribution: buttonLockOff });
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function getJudgesCandidatesReport(payload) {
  apiGetRequest(
    `/get_judge_candidates_report/${payload?.tournament_id}`,
    '',
    (body, status, fileName) => {
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function updateReference(evt, id, changedValues, lang, cb, addName) {
  evt.preventDefault();

  const formData = JSON.stringify({
    id,
    lang,
    ...(changedValues.active !== undefined && { active: changedValues.active }),
    ...(changedValues.order !== undefined && { order: changedValues.order }),
    ...(changedValues.langs !== undefined && { langs: changedValues.langs }),
    ...(changedValues.specId !== undefined && { specId: changedValues.specId }),
    ...(changedValues.permission !== undefined && {
      permission: changedValues.permission
    }),
    ...(changedValues.active !== undefined && { active: changedValues.active }),
    ...(changedValues[`name_${lang}`] !== undefined && {
      name: changedValues[`name_${lang}`]
    }),
    ...(changedValues[`shortName_${lang}`] !== undefined && {
      short_name: changedValues[`shortName_${lang}`]
    }),
    ...(changedValues[`altName_${lang}`] !== undefined && {
      alt_name: changedValues[`altName_${lang}`]
    }),
    // to edit the name of the first reference
    ...(!addName && {
      ...(changedValues['name'] !== undefined && {
        name: changedValues['name']
      })
    })
  });

  apiPostRequest('/update_reference', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => cb && cb(msg),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function newReference(
  evt,
  id,
  parentID,
  changedValues,
  lang,
  cb,
  addName
) {
  evt.preventDefault();
  const formData = JSON.stringify({
    ...(id !== undefined && { id: id }),
    ...(parentID !== undefined && { ref_id: parentID }),
    lang: lang,
    ...(changedValues.langs !== undefined && { langs: changedValues.langs }),
    ...(changedValues.active !== undefined && { active: changedValues.active }),
    ...(changedValues.order !== undefined && { order: changedValues.order }),
    ...(changedValues.specId !== undefined && { specId: changedValues.specId }),
    ...(changedValues.permission !== undefined && {
      permission: changedValues.permission
    }),
    ...(changedValues.active !== undefined && { active: changedValues.active }),
    ...(changedValues[`name_${lang}`] !== undefined && {
      name: changedValues[`name_${lang}`]
    }),
    ...(changedValues[`shortName_${lang}`] !== undefined && {
      short_name: changedValues[`shortName_${lang}`]
    }),
    ...(changedValues[`altName_${lang}`] !== undefined && {
      alt_name: changedValues[`altName_${lang}`]
    }),
    // to add the name of the first reference
    ...(addName && {
      ...(changedValues['name'] !== undefined && {
        name: changedValues['name']
      })
    })
  });
  apiPostRequest('/new_reference', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => {
          cb && cb(msg);
        },
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function importSettings(event, settingsAreTaken, settingsAreApplied) {
  event.preventDefault();

  const formData = new FormData();

  formData.append('src_id', settingsAreTaken);
  formData.append('dest_id', settingsAreApplied);

  apiPostRequest('/apply_tournament_configuration', formData, () => {
    window.location.reload();
  });
}

export function fetchLogs(action) {
  apiGetRequest(
    `/get_logs`,
    '',
    (msg, status) => {
      if (status === 200) {
        action && action(msg);
      } else {
        this.changeModal(
          requestStatus(status, null, msg, null, i18n.t('error')),
          status
        );
      }
    },
    null,
    API_URL_LOGS
  );
}

export function fetchLog(fileName) {
  apiGetRequest(
    `/get_logfile/${fileName}`,
    '',
    (body, status, fileName) => {
      if (status === 200) {
        this.downloadFile(body, fileName);
      } else {
        this.changeModal(
          requestStatus(status, null, body, null, i18n.t('notFound')),
          status
        );
      }
    },
    null,
    API_URL_LOGS
  );
}

export function deleteLog(fileName, action) {
  apiGetRequest(
    `/delete_log_file/${fileName}`,
    '',
    (msg, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => {
            action && action(msg);
          },
          msg,
          i18n.t('deletedSuccessfully'),
          i18n.t('actionFailed')
        ),
        status
      );
    },
    null,
    API_URL_LOGS
  );
}

export function fetchLogsStatistics(action) {
  apiGetRequest(
    `/get_info`,
    '',
    (msg, status) => {
      if (status === 200) {
        action && action(msg);
      } else {
        this.changeModal(
          requestStatus(status, null, msg, null, i18n.t('error')),
          status
        );
      }
    },
    null,
    API_URL_LOGS
  );
}

export function exportAwardSequenceReport(tourn) {
  const { selectedOrientation } = this.state;

  const orientation =
    selectedOrientation['selectedCheckboxesAwardSequenceReport'] === 'landscape'
      ? 2
      : 1;

  const param = {
    orientation: orientation
  };

  apiGetRequest(
    `/get_award_sequence_report/${tourn}`,
    param,
    (body, status, fileName) => {
      this.changeModal(
        requestStatus(
          status,
          () => this.downloadFile(body, fileName),
          body,
          null,
          i18n.t('noReport')
        ),
        status
      );
    }
  );
}

export function changeTatamisName(evt, tournId, tatamisName, action) {
  evt.preventDefault();
  const formData = new FormData();
  formData.append('tournament_id', tournId);
  formData.append('tatami_set', tatamisName);
  apiPostRequest('/change_tatami_set', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => action && action(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}
export function handleSendMessage(
  message,
  setMessages,
  setMessage,
  fetchMessagesAndResponses,
  replyToId
) {
  const userId = localStorage.getItem('user_id');
  const userName = localStorage.getItem('name');
  const currentUrl = window.location.href;
  const userRole = localStorage.getItem('role');

  const messageId = uuidv4();
  const messageType = userRole === 'administrator' ? 2 : 1;

  if (userRole === 'administrator' && !replyToId) {
    console.error(
      'Консультант может отправлять сообщения только в ответ на сообщения пользователя.'
    );
    return;
  }
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const newMessage = {
    message_id: messageId,
    user_id: userId,
    name: userName,
    message: message,
    timestamp: formatDateTime(new Date()),
    url: currentUrl,
    type: messageType,
    reply_to_id: replyToId || null
  };

  setMessages((prevMessages) => [...prevMessages]);
  setMessage('');

  apiPostRequest(
    '/help/send_message',
    JSON.stringify(newMessage),
    (msg, status) => {
      if (status === 200) {
      } else {
        console.error('Error in response:', msg);
      }
    },
    null,
    API_URL_V2
  );
}
export function fetchMessagesAndResponses(
  messageId,
  setMessages,
  setConsultantResponses,
  setFetchedMessageIds
) {
  apiPostRequest(
    '/help/message_updates',
    { message_id: messageId },
    (response, status) => {
      if (status === 200) {
        if (Array.isArray(response)) {
          const newMessages = response
            .filter((item) => item.type === 1)
            .map((item) => ({
              message_id: item.id,
              user_id: item.user_id,
              name: item.sender_name,
              message: item.message,
              timestamp: item.created_at,
              url: item.url,
              type: item.type,
              reply_to_id: item.reply_to_id
            }));

          const newResponses = response
            .filter((item) => item.type === 2)
            .map((item) => ({
              id: item.id,
              message: item.message,
              type: item.type,
              timestamp: item.created_at,
              reply_to_id: item.reply_to_id
            }));

          setMessages((prevMessages) => {
            const existingMessageIds = new Set(
              prevMessages.map((msg) => msg.message_id)
            );
            const uniqueNewMessages = newMessages.filter(
              (msg) => !existingMessageIds.has(msg.message_id)
            );
            return [...prevMessages, ...uniqueNewMessages];
          });

          setConsultantResponses((prevResponses) => {
            const existingResponseIds = new Set(
              prevResponses.map((res) => res.id)
            );
            const uniqueNewResponses = newResponses.filter(
              (res) => !existingResponseIds.has(res.id)
            );
            return [...prevResponses, ...uniqueNewResponses];
          });

          if (messageId) {
            setFetchedMessageIds((prevIds) => {
              const newIds = new Set(prevIds);
              newIds.add(messageId);
              return newIds;
            });
          }
        } else {
          console.error(JSON.stringify(response, null, 2));
        }
      } else {
        console.error('Ошибка в ответе:', JSON.stringify(response, null, 2));
      }
    },
    null,
    API_URL_V2
  );
}

export function combineAndSortMessages(messages, responses, userRole) {
  let combinedMessages = [...messages, ...responses];

  return combinedMessages.sort(
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  );
}

export function truncateMessage(message) {
  const maxLength = 10;
  if (message.length > maxLength) {
    return message.slice(0, maxLength) + '...';
  }
  return message;
}

export function calculateUnreadCount(
  messages,
  consultantResponses,
  chatOpenTime
) {
  if (!chatOpenTime) {
    return 0;
  }

  const chatOpenDate = new Date(chatOpenTime);

  if (isNaN(chatOpenDate.getTime())) {
    return 0;
  }

  const userRole = localStorage.getItem('role');

  const allMessages = [...messages, ...consultantResponses];

  return allMessages.reduce((count, item) => {
    if (!item.timestamp) {
      return count;
    }

    const messageDate = new Date(item.timestamp);

    if (isNaN(messageDate.getTime())) {
      return count;
    }

    const shouldCountMessage =
      (userRole === 'administrator' && item.type === 1) ||
      (userRole !== 'administrator' && item.type === 2);

    return chatOpenDate < messageDate && shouldCountMessage ? count + 1 : count;
  }, 0);
}

export function setPasswordUser(values) {
  const formData = new FormData();
  formData.append('id', values.id);
  formData.append('password', values.passwordUser);

  apiPostRequest('/force_password_reset', formData, (msg, status) => {
    this.changeModal(
      requestStatus(
        status,
        () => action && action(),
        msg,
        i18n.t('successfullyUpdated'),
        i18n.t('cannotSaveChanges')
      ),
      status
    );
  });
}

export function getReference(identify, info, id) {
  const formData = JSON.stringify({
    identify: identify,
    lang: getCurrentLanguage(),
    info: info ? 1 : 0
  });

  apiPostRequest(
    '/lists/get_ref',
    formData,
    (data, status) => {
      this.changeModal(
        requestStatus(
          status,
          () => this.setState({ tgOrderTypes: data }),
          id,
          i18n.t('successfullyUpdated'),
          i18n.t('cannotSaveChanges')
        ),
        status
      );
    },
    null,
    API_URL_V2
  );
}

export function loadOlderMessages(
  sortedMessages,
  setLoadingOlderMessages,
  setMessages,
  setConsultantResponses,
  setFetchedMessageIds
) {
  if (!sortedMessages.length) return;

  const firstMessageDate = sortedMessages[0]?.timestamp;
  setLoadingOlderMessages(true);

  apiPostRequest(
    '/help/more_messages',
    JSON.stringify({ createdAt: firstMessageDate }),
    (response, status) => {
      if (status === 200) {
        const oldMessages = response
          .filter((item) => Number(item.type) === 1)
          .map((item) => ({
            message_id: item.id,
            user_id: item.user_id,
            name: item.sender_name,
            message: item.message,
            timestamp: item.created_at,
            url: item.url,
            type: item.type,
            reply_to_id: item.reply_to_id
          }));

        const oldResponses = response
          .filter((item) => Number(item.type) === 2)
          .map((item) => ({
            id: item.id,
            message: item.message,
            type: item.type,
            timestamp: item.created_at,
            reply_to_id: item.reply_to_id
          }));

        setMessages((prevMessages) => {
          const existingMessageIds = new Set(
            prevMessages.map((msg) => msg.message_id)
          );
          const uniqueNewMessages = oldMessages.filter(
            (msg) => !existingMessageIds.has(msg.message_id)
          );
          return [...prevMessages, ...uniqueNewMessages];
        });

        setConsultantResponses((prevResponses) => {
          const existingResponseIds = new Set(
            prevResponses.map((res) => res.id)
          );
          const uniqueNewResponses = oldResponses.filter(
            (res) => !existingResponseIds.has(res.id)
          );
          return [...prevResponses, ...uniqueNewResponses];
        });

        setFetchedMessageIds((prevIds) => {
          const newIds = new Set(prevIds);
          oldMessages.forEach((msg) => newIds.add(msg.message_id));
          return Array.from(newIds);
        });
      } else {
        console.error('Ошибка при загрузке сообщений:', status, response);
      }

      setLoadingOlderMessages(false);
    },
    null,
    API_URL_V2
  );
}
