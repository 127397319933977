import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';

import {
  fetchTournamentWinners,
  changeModal,
  fetchTournaments,
  exportWinners,
  downloadFile,
  exportAwardSequenceReport,
  fetchAllCategoryTypesV2,
  selectOption
} from '../../helpers/util';
import { selectedValue } from 'helpers/selectors';

import { changeTitle } from '../../helpers/actions';

import { ReactComponent as ThirdMedal } from '../../assets/img/third_place.svg';
import { ReactComponent as SecondMedal } from '../../assets/img/second_place.svg';
import { ReactComponent as FirstMedal } from '../../assets/img/first_place.svg';
import { ReactComponent as Diploma } from '../../assets/img/diploma.svg';

import { ENABLED, ID } from 'helpers/constants';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { AuthContext } from 'AuthContext';
import Button from '../../components/Buttons/ActionButtons';
import SearchInput from '../../components/SearchInput/SearchInput';
import FilterCheckboxes from '../../components/FilterOptions/FilterCheckboxes';

import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import LoadingState from '../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import SideModal from '../../components/Snackbar/SideModal';
import Accordion from 'components/Accordion/Accordion';
import Table from 'components/Table/CardsList';
import Filter from 'components/Filter/Filter';

import styles from './styles';

class Winners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      success: false,
      loading: true,
      collapsed: {},
      open: {},
      role: localStorage.getItem('role'),
      winners: [],
      tournamentData: {},
      viewportWidth: window.innerWidth,
      isFilterOpen: false,
      searchBar: '',
      categoryTypes: [],
      selectedCheckboxes: [],
      selectedCheckboxesWinners: [],
      expandAll: false,
      filterWinnersValue: {
        categoryId: '',
        categoriesWithWinners: []
      },
      searchByOption: { id: '0' }
    };
    this.fetchTournamentWinners = fetchTournamentWinners.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.exportWinners = exportWinners.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.exportAwardSequenceReport = exportAwardSequenceReport.bind(this);
    this.fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
    this.selectOption = selectOption.bind(this);
    this.selectedValue = selectedValue.bind(this);

    this.myParam = props.match.params?.id;
  }

  static contextType = AuthContext;

  componentDidMount() {
    const { role } = this.state;

    changeTitle(this.props.t('winners'));

    this.setState({ loading: true });

    this.fetchTournaments(this.myParam, null, null, () => {
      const { tournamentData } = this.state;

      const data1 = new Promise((resolve) => {
        this.fetchTournamentWinners(this.myParam, () => {
          const { winners } = this.state;
          const totalRecords = winners?.length;

          let statistics = {
            totalRecords,
            total1Place: 0,
            total2Place: 0,
            total3Place: 0,
            totalPrintedDiploma: 0
          };

          winners?.map((it) => {
            it.winners?.map((winner) => {
              if (+winner.participant_place === 1) {
                statistics.total1Place += 1;
              } else if (+winner.participant_place === 2) {
                statistics.total2Place += 1;
              } else if (+winner.participant_place === 3) {
                statistics.total3Place += 1;
              }

              if (!!+winner.participant_is_diplom_printed) {
                statistics.totalPrintedDiploma += 1;
              }
            });
          });

          let categoriesWithWinners = winners.map(
            ({ category_name, category_id }) => ({
              category_name,
              category_id
            })
          );

          resolve({ categoriesWithWinners, statistics });
        });
      });

      const data2 = new Promise((resolve) => {
        this.fetchAllCategoryTypesV2(
          role === 'administrator' && {
            user_id: tournamentData?.organizer_id
          },
          (types) => {
            resolve(types);
          }
        );
      });

      Promise.all([data1, data2]).then(
        ([{ categoriesWithWinners, statistics }, res2]) => {
          const { filterWinnersValue } = this.state;
          let cpy = { ...filterWinnersValue };

          cpy.categoriesWithWinners = categoriesWithWinners;

          this.setState({
            statistics,
            filterWinnersValue: cpy,
            ...(res2 ? { categoryTypes: res2 } : {}),
            loading: false
          });
        }
      );
    });
  }

  onResize = () => this.setState({ viewportWidth: window.innerWidth });

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      this.fetchTournamentWinners(this.myParam, () => {
        this.setState({ loading: false });
      });
    });
  };

  cancelFilter = () => {
    this.setState({ selectedCheckboxes: [] }, () =>
      this.fetchTournamentWinners(this.myParam, () => {
        this.setState({ loading: false });
      })
    );
  };

  itemToggle = (key) =>
    this.setState((prevState) => ({
      collapsed: { ...prevState.collapsed, [key]: !this.state.collapsed[key] }
    }));

  hideSnackbar = () => this.setState({ showModal: false });

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value });
  };

  filterVision = () => {
    this.setState({ isFilterOpen: !this.state.isFilterOpen });
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () =>
      this.fetchTournamentWinners(this.myParam, () => {
        this.setState({ loading: false });
      })
    );
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  searchHandler = () => {
    this.fetchTournamentWinners(this.myParam, () =>
      this.setState({ loading: false })
    );
  };

  onSelectCheckboxExpandAll = () => {
    const { expandAll } = this.state;

    this.setState({ expandAll: !expandAll }, () => {
      this.expandOrCloseList(!expandAll);
    });
  };

  expandOrCloseList = (expandOrClose) => {
    const { winners } = this.state;
    const len = winners.length;
    let collapseAll = {};

    for (let i = 0; i < len; i++) {
      collapseAll[i] = expandOrClose;
    }

    this.setState({ collapsed: collapseAll });
  };

  handleFilterChange = (evt, val) => {
    this.selectOption(
      evt,
      val,
      'filterWinnersValue',
      'categoryId',
      'category_id',
      null,
      () => {
        this.fetchTournamentWinners(this.myParam, () => {
          this.setState({ loading: false });
        });
      }
    );
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      winners,
      collapsed,
      open,
      loading,
      tournamentData,
      isFilterOpen,
      searchBar,
      categoryTypes,
      selectedCheckboxes,
      expandAll,
      filterWinnersValue,
      searchByOption,
      statistics
    } = this.state;
    const { t } = this.props;
    const { classes } = this.props;
    const { viewportWidth } = this.context;

    const SEARCH_BY_OPTION = [
      {
        id: '0',
        label: t('athletes')
      },
      { id: '1', label: t('coaches') }
    ];

    const recordDataParams = (item, idx) => {
      return {
        main: {
          label: t('nameRegistrar'),
          style: {
            cardMainPart: classes.cardWrapper,
            recordName: classes.txtWrapper
          },
          name: item.participant_name,
          hasPhoto: true,
          photo: item?.participant_photo
        },
        info: [
          ...(tournamentData.no_participant_number !== ENABLED
            ? [
                {
                  name: t('number'),
                  value: item?.participant_number || '-'
                }
              ]
            : []),
          {
            name: t('age'),
            value: item.participant_age
          },
          {
            name: t('gender'),
            value: item.participant_gender
          },
          {
            name: t('weight'),
            value: item.participant_weight
          },
          {
            name: t('coach'),
            value: item.participant_coach
          },
          {
            name: t('diploma'),
            value: !!+item.participant_is_diplom_printed ? <Diploma /> : '-'
          },
          {
            name: t('rank'),
            value:
              +item.participant_place === 1 ? (
                <FirstMedal />
              ) : +item.participant_place === 2 ? (
                <SecondMedal />
              ) : +item.participant_place === 3 ? (
                <ThirdMedal />
              ) : (
                '-'
              )
          }
        ]
      };
    };

    const pageHeaderStatistics = {
      title: t('eventStatistics'),
      info: [
        {
          label: t('totalCategories'),
          name: statistics?.totalRecords
        },
        {
          label: t('firstPlaces'),
          name: statistics?.total1Place
        },
        {
          label: t('secondPlaces'),
          name: statistics?.total2Place
        },
        {
          label: t('thirdPlaces'),
          name: statistics?.total3Place
        },
        {
          label: t('printedDiplomas'),
          name: statistics?.totalPrintedDiploma
        }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            {tournamentData && (
              <HeaderTournInfo
                {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
                shouldShowBtn
              />
            )}
            <CheckboxBtn
              className={classes.marginLeft5}
              checked={expandAll}
              onChange={this.onSelectCheckboxExpandAll}
              label={t('expandList')}
            />
            <span
              className={clsx(
                classes.gridButtons,
                classes.verticallyCentered,
                !isFilterOpen && classes.marginBottom1
              )}>
              <Button
                onClick={() => this.filterVision()}
                label={
                  <span
                    className={clsx(
                      classes.flex,
                      classes.verticallyCentered,
                      classes.center,
                      classes.flexGrow1
                    )}>
                    <span className={classes.flexGrow1}>{t('filter')}</span>
                    <ArrowDropDownIcon
                      className={isFilterOpen && classes.arrowDropUp}
                    />
                  </span>
                }
                isSaveBtn={!isFilterOpen}
                className={isFilterOpen && classes.selected}
                style={{ gridArea: 'filterButton' }}
              />
              <Filter
                classes={{
                  root: classes.outlined
                }}
                value={
                  this.selectedValue(
                    filterWinnersValue.categoriesWithWinners,
                    'category_id',
                    filterWinnersValue.categoryId
                  ) || ''
                }
                options={filterWinnersValue?.categoriesWithWinners}
                onChange={(evt, val) => this.handleFilterChange(evt, val)}
                label={t('rfQual')}
                item={'category_name'}
                variant="outlined"
                style={{ gridArea: 'filter' }}
                disableClearable
              />
              <SearchInput
                className={classes.search}
                onChange={this.onSearch}
                clearSearch={this.onClearSearch}
                value={searchBar}
                isSearchInactive={!searchBar}
                onKeyPress={this.pressEnterHandler}
                style={{ gridArea: 'searchInput' }}
                specificDetails={{
                  childrenElemEnd: (
                    <Filter
                      value={
                        selectedValue(
                          SEARCH_BY_OPTION,
                          ID,
                          searchByOption.id
                        ) || ''
                      }
                      className={classes.styleFilter}
                      options={SEARCH_BY_OPTION}
                      item="label"
                      onChange={(e, val) =>
                        this.selectOption(e, val, 'searchByOption', ID, ID)
                      }
                      specificDetails={{
                        InputProps: { disableUnderline: true }
                      }}
                    />
                  )
                }}
              />
              <Button
                onClick={() => this.exportWinners(this.myParam)}
                label={t('saveList')}
                isSaveBtn={true}
                className={classes.center}
                style={{ gridArea: 'exportButton' }}
              />
            </span>
            <FilterCheckboxes
              {...{
                categoryTypes,
                selectedCheckboxes,
                isFilterOpen
              }}
              filterDetails={{
                ownerCategories: false,
                tounamentPresence: false,
                noUnisex: false
              }}
              onSelectCheckbox={this.onSelectCheckbox}
              cancelFilter={this.cancelFilter}
            />
            <Accordion
              itemToggle={this.itemToggle}
              data={winners}
              {...{ collapsed }}
              accordionContent={(finalists) => (
                <Table data={finalists} {...{ recordDataParams }} />
              )}
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Winners)));
