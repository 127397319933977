import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { ReactComponent as SkirmishesWhiteIcon } from 'assets/img/grid_skirmish.svg';

import './StylesIndividualGrid.css';

const SKIRMISH_HEIGHT = 48;
const SKIRMISH_WIDTH = 250;
const ROUND_WIDTH = 280;
const MIN_MARGIN = 5;
const MIN_SPACE = 2;
const SKIRMISH_HALF_HEIGHT = SKIRMISH_HEIGHT / 2;
const ROUND_NAME_HEIGHT = 35;

const calculatePositions = (skirmishes) => {
  const positions = [];

  // Sort skirmishes by order ASC
  const sortedSkirmishes = skirmishes.sort((a, b) => a.order - b.order);
  sortedSkirmishes.forEach((skirmish) => {
    const { round, block } = skirmish.position;
    const winnerTo = skirmish.winnerTo;
    const loserTo = skirmish.loserTo;

    // Calculate the position for the current skirmish
    const x = (round - 1) * ROUND_WIDTH + MIN_MARGIN;
    const block2SkirmishY =
      Math.max(...positions.map((it) => it.top)) + 2 * SKIRMISH_HEIGHT;

    const y =
      (block === 1
        ? getVerticalPosition(positions, skirmish.position)
        : block2SkirmishY) + MIN_SPACE;

    positions.push({
      ...skirmish.position,
      top: y,
      left: x,
      winnerTo,
      loserTo
    });
  });

  return positions;
};

function getVerticalPosition(allPositions, currentPosition) {
  // Determine top position
  const emptySlots = currentPosition.skirmishAbs - currentPosition.skirmish;
  let yPosition =
    (currentPosition.skirmish - 1) * SKIRMISH_HEIGHT +
    (emptySlots * SKIRMISH_HEIGHT) / currentPosition.skirmishAbs;

  // Adjust yPosition based on winnerTo and loserTo
  const previousSkirmishes = allPositions.filter(
    (pos) =>
      (pos.winnerTo &&
        pos.winnerTo.round === currentPosition.round &&
        pos.winnerTo.block === currentPosition.block &&
        pos.winnerTo.skirmishAbs === currentPosition.skirmishAbs) ||
      (pos.loserTo &&
        pos.loserTo.round === currentPosition.round &&
        pos.loserTo.block === currentPosition.block &&
        pos.loserTo.skirmishAbs === currentPosition.skirmishAbs)
  );

  if (previousSkirmishes.length > 0) {
    const yPositions = previousSkirmishes.map((el) => el.top);

    if (previousSkirmishes.length === 1) {
      // 1 previous skirmish => position slightly higher
      const singlePosition = yPositions[0];
      yPosition = Math.max(singlePosition - SKIRMISH_HEIGHT, 0);
    } else if (previousSkirmishes.length === 2) {
      // 2 previous skirmishes => position centered
      const [y1, y2] = yPositions;
      yPosition = (y1 + y2) / 2;
    }
  }

  // Prevent overlapping within the same round
  const sameRoundSkirmishes = allPositions.filter(
    (pos) =>
      pos.block === currentPosition.block &&
      pos.round === currentPosition.round &&
      pos.skirmishAbs !== currentPosition.skirmishAbs
  );

  if (sameRoundSkirmishes.length > 0) {
    const yPositions = sameRoundSkirmishes.map((el) => el.top);
    yPosition = Math.max(yPosition, Math.max(...yPositions) + SKIRMISH_HEIGHT);
  }

  return Math.max(yPosition, MIN_MARGIN);
}

const Skirmish = ({ grid, skirmish, position, data, onSwapParticipants }) => {
  const { t } = useTranslation();
  const { participants, name } = skirmish;

  return (
    <div
      id={`block_${skirmish.position.block}_round_${skirmish.position.round}_skirmishAbs_${skirmish.position.skirmishAbs}`}
      className="skirmish"
      style={{
        left: position?.left ?? 0,
        top: position?.top ?? 0,
        position: 'absolute'
      }}>
      <h6 className="skirmish-name">{name}</h6>
      <div className="participants">
        {participants.map((p, pIdx) => {
          const winner = grid.skirmishes.find(
            (it) =>
              it.winnerTo?.block === skirmish.position.block &&
              it.winnerTo?.round === skirmish.position.round &&
              it.winnerTo?.skirmishAbs === skirmish.position.skirmishAbs &&
              it.winnerTo?.position === pIdx + 1
          );
          const loser = grid.skirmishes.find(
            (it) =>
              it.loserTo?.block === skirmish.position.block &&
              it.loserTo?.round === skirmish.position.round &&
              it.loserTo?.skirmishAbs === skirmish.position.skirmishAbs &&
              it.loserTo?.position === pIdx + 1
          );

          const waitingParticipant = winner
            ? t('winnerOf', { name: winner?.name })
            : loser
            ? t('loserOf', { name: loser?.name })
            : `${t('wainting')}...`;
          const participant = data.participants.find((it) => it.id === p.id);

          return (
            <span
              key={`${name}_${pIdx}`}
              className={`participant ${p.isStartPosition ? 'active' : ''} ${
                data.swapParticipants?.some(
                  (it) => +it?.participant?.id === +p.id
                )
                  ? 'swap'
                  : ''
              }`}
              onClick={
                p.isStartPosition
                  ? () =>
                      onSwapParticipants({
                        participant: { ...p },
                        categoryId: grid.id
                      })
                  : undefined
              }>
              <h6
                style={{
                  ...(!participant
                    ? {
                        color: !(loser || winner) ? '#f44741' : '#8996ac',
                        fontWeight: 'normal'
                      }
                    : {})
                }}>
                {participant
                  ? `${
                      participant.countryISO3
                        ? `(${participant.countryISO3})`
                        : ''
                    } ${participant.number ? `${participant.number}. ` : ''} ${
                      participant?.name
                    }`
                  : waitingParticipant}
              </h6>
            </span>
          );
        })}
      </div>
    </div>
  );
};

const Block = ({ grid, data, positions, rounds, onSwapParticipants }) => {
  const calculatePath = (currentSkirmish, targetSkirmish) => {
    const currentCenterY = currentSkirmish.top + SKIRMISH_HALF_HEIGHT; // Center of current skirmish
    const targetCenterY = targetSkirmish.top + SKIRMISH_HALF_HEIGHT; // Center of target skirmish

    const startX = currentSkirmish.left + SKIRMISH_WIDTH; // Right edge of current skirmish
    const endX = targetSkirmish.left; // Left edge of target skirmish

    // Calculate the vertical difference between skirmishes
    const yDifference = targetCenterY - currentCenterY;

    // Adjust control points based on the vertical difference
    const controlPointY1 = currentCenterY + yDifference / 4; // Control point slightly above current center
    const controlPointY2 = targetCenterY - yDifference / 4; // Control point slightly below target center

    // Draw the curly bracket shape with dynamic control points
    return `M${startX} ${currentCenterY} C${startX + 30} ${controlPointY1}, ${
      endX - 30
    } ${controlPointY2}, ${endX} ${targetCenterY}`;
  };

  // Use the current skirmish and target skirmish directly
  // Ensure skirmish positions are based on the actual container's coordinates

  const pathData = positions.map((skirmish) => {
    const paths = [];

    // winnerTo
    if (skirmish.winnerTo) {
      const target = positions.find(
        (s) =>
          s.round === skirmish.winnerTo.round &&
          s.block === skirmish.winnerTo.block &&
          s.skirmishAbs === skirmish.winnerTo.skirmishAbs
      );
      if (target) {
        paths.push(calculatePath(skirmish, target));
      }
    }

    // loserTo
    if (skirmish.loserTo) {
      const target = positions.find(
        (s) =>
          s.round === skirmish.loserTo.round &&
          s.block === skirmish.loserTo.block &&
          s.skirmishAbs === skirmish.loserTo.skirmishAbs
      );
      if (target && skirmish.loserTo.round === 1) {
        paths.push(calculatePath(skirmish, target));
      }
    }

    return paths;
  });

  return (
    <>
      <svg className="connections">
        {pathData.map((d, index) => (
          <path key={index} d={d} stroke="#8996ac" fill="transparent" />
        ))}
      </svg>
      {Object.keys(rounds).map((round) => {
        return (
          <Fragment key={round}>
            {rounds[round].map((skirmish, skirmishIdx) => {
              let position = positions.find((pos) => {
                return (
                  pos.block === skirmish.position.block &&
                  pos.round === skirmish.position.round &&
                  pos.skirmishAbs === skirmish.position.skirmishAbs
                );
              });

              return (
                <Skirmish
                  key={skirmishIdx}
                  {...{ grid, skirmish, data, position, onSwapParticipants }}
                />
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

const Grid = ({ grid, data, onSwapParticipants }) => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const skirmishes = grid.skirmishes ?? [];
    const result = calculatePositions(skirmishes);

    setPositions(result);
  }, [grid.skirmishes]);

  // organize data by blocks and rounds
  const blocks = { 1: [], 2: [] };
  grid.skirmishes?.forEach((skirmish) => {
    const block = skirmish.position.block;
    const round = skirmish.position.round;

    blocks[block].push({ ...skirmish, round });
  });

  const rounds = Object.values(blocks).map((skirmishes) => {
    return skirmishes.reduce((acc, skirmish) => {
      if (!acc[skirmish.position.round]) acc[skirmish.position.round] = [];
      acc[skirmish.position.round].push(skirmish);
      return acc;
    }, {});
  });
  const category = data.categories.find((it) => +it.id === +grid.id);
  const bracketHeight =
    (positions
      ? Math.max(...positions.map((it) => it.top)) + SKIRMISH_HEIGHT
      : SKIRMISH_HEIGHT) +
    SKIRMISH_HALF_HEIGHT +
    MIN_MARGIN;
  const bracketWidth = Object.keys(rounds?.[0]).pop() * ROUND_WIDTH;
  const shouldShowRoundName = grid.skirmishes.some((it) => !!it.roundName);

  return (
    <>
      <span className="category-name">
        <SkirmishesWhiteIcon className="category-name-ic" />
        {category?.name}
      </span>
      <PerfectScrollbar id="bracket" className="bracket-scroll">
        {shouldShowRoundName && (
          <span
            className="rounds"
            style={{
              height: `${ROUND_NAME_HEIGHT}px`,
              width: `${bracketWidth}px`
            }}>
            {Object.keys(blocks).map((block) => {
              return (
                <Fragment key={block}>
                  {Object.entries(rounds[block - 1])?.map(
                    ([round, skirmishes]) => {
                      const currentRound = positions.find(
                        (it) => +it.round === +round && +it.block === +block
                      );

                      return (
                        <span
                          key={round}
                          className="round-name"
                          style={{
                            top: +block === 1 ? MIN_MARGIN : currentRound?.top,
                            left: currentRound?.left
                              ? currentRound?.left + SKIRMISH_WIDTH / 2 - 20
                              : 0
                          }}>
                          {skirmishes?.[0]?.roundName}
                        </span>
                      );
                    }
                  )}
                </Fragment>
              );
            })}
          </span>
        )}
        <div
          className="bracket-inner"
          style={{
            width: `${bracketWidth}px`,
            height: `${bracketHeight}px`
          }}>
          {Object.keys(blocks).map((block, blockIdx) => (
            <Block
              key={block}
              skirmishes={blocks[block]}
              {...{ data, grid, positions, block, onSwapParticipants }}
              rounds={rounds?.[blockIdx]}
            />
          ))}
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default Grid;
