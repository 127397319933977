import { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { withRouter } from '../../components/withRouter';

import {
  PARTICIPANT_FORM,
  ID,
  COACH_ID,
  ERRORS,
  COACH_FORM,
  ENABLED,
  CLOSE_DISCARD,
  KEEP_EDITING,
  ALL_SPORT_RANK,
  RATE
} from '../../helpers/constants';
import {
  fetchCountries,
  selectTournament,
  selectOption,
  textChangeHandler,
  fetchCategories,
  updateParticipant,
  addParticipant,
  changeModal,
  changeImage,
  changeDate,
  fetchUsers,
  validateForm,
  applyCategories,
  selectCheckbox,
  addNewCategory,
  selectMultipleCategories,
  selectMultipleCategoryType,
  selectMultipleTeams,
  removeCategory,
  validateMultipleCategoriesAssignment,
  fetchAllCategoryTypes,
  fetchReferenceRegion,
  fetchTournaments,
  fetchQdan,
  fetchTournamentList,
  fetchReference
} from '../../helpers/util';
import {
  categoryTypesPresentOnTournament,
  isSchool,
  selectedValue
} from '../../helpers/selectors';

import { AuthContext } from '../../AuthContext';

import SideModal from '../../components/Snackbar/SideModal';
import Filter from '../../components/Filter/Filter';
import Modal from '../../components/Modal/ModalNewDesign';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import AssignCategories from '../../components/QuickTournRegistration/AssignCategories';
import ParticipantForm from '../../components/QuickTournRegistration/ParticipantFormNewDesign/ParticipantForm';
import LoadingState from '../../components/LoadingState/LoadingState';

import styles from './Styles';

const defaultErrors = {
  first_name: '',
  last_name: '',
  age: '',
  gender: '',
  weight: '',
  birthday: ''
};
const initialStateCategory = {
  type: '',
  id: '',
  team_id: '',
  filteredCategories: []
};

class AddParticipant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coachesList: [...(props?.state?.coachesList ?? [])],
      participantForm: { ...(props?.state?.modalData ?? {}) },
      personalAssignedCategories: props.state
        ? props.state.personalAssignedCategories
        : [],
      countries: [],
      allRegions: [],
      qdanList: [...props.state.qdanList],
      categoryTypes: [],
      categories: [],
      filteredCategories: [],
      errors: {},
      tournamentData: {},
      tournamentsList: [],
      isFilterChecked: true,
      //text validation for city/club
      showModal: false,
      success: false,
      open: false,
      isLoading: true,
      selectedCategories: [initialStateCategory],
      selectedCategoriesErrors: [],
      isSending: false,
      all_sport_rank: []
    };

    this.tournamentIdParam = props.match.params.id;
    this.userId = localStorage.getItem('user_id');

    this.fetchCountries = fetchCountries.bind(this);
    this.selectTournament = selectTournament.bind(this);
    this.selectOption = selectOption.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.fetchCategories = fetchCategories.bind(this);
    this.updateParticipant = updateParticipant.bind(this);
    this.addParticipant = addParticipant.bind(this);
    this.changeModal = changeModal.bind(this);
    this.changeImage = changeImage.bind(this);
    this.changeDate = changeDate.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.validateForm = validateForm.bind(this);
    this.applyCategories = applyCategories.bind(this);
    this.selectCheckbox = selectCheckbox.bind(this);
    this.addNewCategory = addNewCategory.bind(this);
    this.selectMultipleCategories = selectMultipleCategories.bind(this);
    this.selectMultipleCategoryType = selectMultipleCategoryType.bind(this);
    this.selectMultipleTeams = selectMultipleTeams.bind(this);
    this.removeCategory = removeCategory.bind(this);
    this.validateMultipleCategoriesAssignment =
      validateMultipleCategoriesAssignment.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.fetchQdan = fetchQdan.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.fetchReferenceRegion = fetchReferenceRegion.bind(this);
    this.fetchTournamentList = fetchTournamentList.bind(this);
    this.fetchReference = fetchReference.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { selectedCategories, isFilterChecked, isEditing } = this.state;
    const { state } = this.props;

    if (
      isEditing &&
      ((selectedCategories &&
        selectedCategories !== prevState.selectedCategories) ||
        isFilterChecked !== prevState.isFilterChecked)
    ) {
      this.setState({ isAttemptingToEditModalFields: true });
    }

    if (
      state?.selectedRow &&
      state?.selectedRow !== prevProps.state?.selectedRow
    ) {
      this.setState({
        participantForm: state?.modalData,
        personalAssignedCategories: state?.personalAssignedCategories
      });
    }
  }

  componentDidMount() {
    const { state } = this.props;
    const { participantForm } = this.state;

    this.setState({ isLoading: true });
    this.fetchCountries();
    this.fetchReference(ALL_SPORT_RANK, (data) =>
      this.setState({ all_sport_rank: data })
    );

    if (!state.participantId) {
      //use the coach's login data to automatically fill in the new participant form
      this.assignYourself((user) => {
        this.fetchReferenceRegion(user?.country_id);
      });
    } else {
      this.fetchReferenceRegion(participantForm?.participant_country_id);
    }

    this.setState({ isLoading: false });
  }

  coachChangeHandler = (e, cb) => {
    const { coachesList } = this.state;
    const { isCoachItem } = this.props;

    if (coachesList && coachesList.length > 0) {
      const coach = coachesList.find(
        (coach) => coach?.[isCoachItem ? 'coach_id' : 'id'] === e
      );

      if (coach) {
        cb && cb(coach);
        this.setState({ coachForm: coach });
        this.autoFillCoachData(coach);
      }
    }
  };

  autoFillCoachData = (coach) => {
    this.setState((prevState) => ({
      participantForm: {
        ...prevState.participantForm,
        club: coach.club ?? coach.coach_club,
        region: coach.region ?? coach.coach_region,
        country_id: coach.country_id,
        coach_first_name: coach.coach_first_name ?? coach.c_first_name,
        coach_last_name: coach.coach_last_name ?? coach.c_last_name,
        coach_id: coach.id ?? coach?.coach_id,
        city: coach.city ?? coach.coach_city
      }
    }));
  };

  assignYourself = (cb) => {
    const { errors } = this.state;

    this.setState((prevState) => ({
      participantForm: {
        ...prevState.participantForm,
        coach_id: this.userId
      }
    }));

    delete errors.coach_id;
    this.coachChangeHandler(this.userId, cb);
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  saveParticipant = (evt) => {
    const {
      participantForm,
      coachForm,
      personalAssignedCategories,
      tournamentData,
      isSending
    } = this.state;
    const { state, triggerTableUpdate, t } = this.props;
    const { selectedTournamentInfo } = participantForm;
    const { role } = this.context.authState;
    const currentListOfCategories =
      personalAssignedCategories &&
      personalAssignedCategories.length > 0 &&
      personalAssignedCategories.map((key) => {
        return { id: key.id, ...(key.team_id ? { team_id: key.team_id } : {}) };
      });
    const assignCategories = currentListOfCategories
      ? currentListOfCategories
      : [];
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        participantForm: {
          ...prevState.participantForm,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.participantForm;

        this.validateForm(
          evt,
          role !== 'coach'
            ? {
                ...defaultErrors,
                ...(role !== 'recorder' && +selectedTournamentInfo?.type === 2
                  ? { first_name_national: '', last_name_national: '' }
                  : {}),
                ...(!!+selectedTournamentInfo?.height_needed
                  ? { height: '' }
                  : {}),
                ...(role !== 'recorder' &&
                !!+selectedTournamentInfo?.show_reg_patronymic
                  ? { patronymic: '' }
                  : {}),
                coach_id: ''
              }
            : defaultErrors,
          form,
          ERRORS,
          () => {
            if (form.id) {
              this.updateParticipant(
                evt,
                form,
                triggerTableUpdate && triggerTableUpdate(true),
                this.tournamentIdParam
                  ? selectedTournamentInfo
                  : tournamentData?.id,
                assignCategories,
                null,
                role
              );
            } else
              !isSending &&
                this.addParticipant(
                  evt,
                  form,
                  coachForm,
                  ERRORS,
                  PARTICIPANT_FORM,
                  (val, msg, status) => {
                    state &&
                      triggerTableUpdate(null, t('successfullyAdded'), status);
                    this.setState({ personalAssignedCategories: [] });
                  },
                  this.tournamentIdParam
                    ? selectedTournamentInfo
                    : tournamentData?.id,
                  assignCategories,
                  null,
                  this.tournamentIdParam && selectedTournamentInfo
                );
          }
        );
      }
    );
  };

  showModal = () => {
    const {
      participantForm,
      personalAssignedCategories,
      shouldSwitchTournament
    } = this.state;
    const { selectedTournamentInfo } = participantForm;

    this.setState({ open: true }, () => {
      if (!this.tournamentIdParam) {
        this.fetchTournaments(
          null,
          this.role !== 'administrator' && 1,
          '',
          null,
          true
        );
      } else {
        this.fetchCategories(
          () => {
            let selectedCategories = [];

            if (!shouldSwitchTournament) {
              this.fetchAllCategoryTypes((allSystemTypes) => {
                const categoryTypes = categoryTypesPresentOnTournament(
                  allSystemTypes || [],
                  selectedTournamentInfo?.category_types
                );

                this.setState({ categoryTypes });
              });
            }

            selectedCategories =
              Array.isArray(personalAssignedCategories) &&
              personalAssignedCategories.map((it) => {
                return {
                  ...it,
                  filteredCategories:
                    Array.isArray(this.state.filteredCategories) &&
                    (!it.type
                      ? this.state.filteredCategories
                      : this.state.filteredCategories.filter(
                          (cat) => `${cat.category_type_id}` === `${it.type}`
                        ))
                };
              });

            this.setState({
              shouldSwitchTournament: false,
              categoriesFitsParticipant: this.state.filteredCategories,
              selectedCategories:
                selectedCategories.length > 0
                  ? selectedCategories
                  : [
                      {
                        ...initialStateCategory,
                        filteredCategories: this.state.filteredCategories
                      }
                    ]
            });
          },
          selectedTournamentInfo?.id,
          null,
          null,
          null,
          this.state.isFilterChecked,
          { ...participantForm }
        );
      }
    });
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        isFilterChecked: true,
        shouldShowDiscardChanges: false,
        isEditing: false,
        selectedCategoriesErrors: []
      });
    }
  };
  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  fetchRegionBySelectedCountry = (key) => {
    const { participantForm } = this.state;
    let cpy = { ...participantForm };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ participantForm: cpy });
  };

  onRemoveCategoriesInForm = (item) => {
    const { personalAssignedCategories } = this.state;
    let copy = [...personalAssignedCategories];

    copy = copy?.filter((it) => it !== item);

    this.setState({ personalAssignedCategories: copy });
  };

  hideTournamentInformation = () => {
    const { participantForm } = this.state;
    let cpy = { ...participantForm };
    cpy.subModalData = null;

    this.setState({
      participantForm: cpy,
      pressedBtn: false,
      open: false
    });
  };

  onCategoriesFiltering = (tournId, cb) => {
    const { participantForm } = this.state;

    this.fetchCategories(
      (list) => this.setState({ filteredCategories: list }, () => cb && cb()),
      tournId,
      null,
      null,
      null,
      this.state.isFilterChecked,
      { ...participantForm }
    );
  };

  switchTournament = (evt, value) => {
    const isTraining = isSchool(value);

    if (value !== null) {
      this.setState({ tournamentData: value }, () => {
        const { tournamentData, participantForm } = this.state;

        const data1 = new Promise((resolve) => {
          this.fetchAllCategoryTypes((allSystemTypes) => {
            const categoryTypes = categoryTypesPresentOnTournament(
              allSystemTypes || [],
              tournamentData?.category_types
            );

            resolve(categoryTypes);
          });
        });

        const data2 = new Promise((resolve) => {
          this.onCategoriesFiltering(tournamentData?.id, () => {
            const { filteredCategories } = this.state;
            let newSelectedCategories = [initialStateCategory];

            if (participantForm?.id) {
              this.fetchTournamentList(
                participantForm?.id,
                tournamentData?.id,
                (data) => {
                  const tournamentCategories =
                    data?.tournaments?.[0]?.categories;
                  const assignedCategories = tournamentCategories?.map(
                    (cat) => ({
                      type: cat.category_type_id,
                      id: cat.category_id,
                      team_id: cat.team_id,
                      name: cat.category_name,
                      issue: cat.issues
                    })
                  );

                  if (assignedCategories?.length > 0) {
                    newSelectedCategories = assignedCategories?.map((it) => {
                      return {
                        ...it,
                        filteredCategories:
                          Array.isArray(filteredCategories) &&
                          (!it.type
                            ? filteredCategories
                            : filteredCategories.filter(
                                (cat) => +cat?.category_type_id === +it?.type
                              ))
                      };
                    });
                  }

                  resolve(newSelectedCategories);
                }
              );
            } else {
              resolve(newSelectedCategories);
            }
          });
        });

        Promise.all([data1, data2]).then(
          ([categoryTypes, newSelectedCategories]) => {
            this.setState({
              categoryTypes,
              selectedCategories: newSelectedCategories
            });
          }
        );

        if (isTraining) {
          this.fetchQdan();
        }
      });
    }
  };

  render() {
    const {
      coachesList,
      categoryTypes,
      participantForm,
      showModal,
      modalInfo,
      success,
      errors,
      isFilterChecked,
      countries,
      allRegions,
      qdanList,
      open,
      selectedCategories,
      personalAssignedCategories,
      isLoading,
      tournamentData,
      tournamentsList,
      shouldShowDiscardChanges,
      selectedCategoriesErrors,
      all_sport_rank
    } = this.state;
    const {
      selectCheckboxSchool,
      t,
      isCoachOptional,
      isCoachItem,
      classes,
      viewportWidth,
      navigate,
      location,
      specificDetails
    } = this.props;
    const { role } = this.context.authState;
    const { selectedTournamentInfo } = participantForm;
    const isRuLang = localStorage.getItem('i18nextLng') === 'ru';

    const MODAL_FIRST_NAME = (item) =>
      this.tournamentIdParam
        ? !+selectedTournamentInfo?.last_name_first
          ? item.participant_first_name
          : item.participant_last_name
        : !isRuLang
        ? item.first_name
        : item.last_name;
    const MODAL_LAST_NAME = (item) =>
      this.tournamentIdParam
        ? !+selectedTournamentInfo?.last_name_first
          ? item.participant_last_name
          : item.participant_first_name
        : !isRuLang
        ? item.last_name
        : item.first_name;

    const PART_FIRST_NAME_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'first_name'
        : 'last_name';

    const PART_LAST_NAME_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'last_name'
        : 'first_name';

    const PART_FIRST_NAME_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('firstname')
        : t('lastname');

    const PART_LAST_NAME_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('lastname')
        : t('firstname');

    const PART_FIRST_NAME_NATIONAL_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'first_name_national'
        : 'last_name_national';

    const PART_LAST_NAME_NATIONAL_VAL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? 'last_name_national'
        : 'first_name_national';

    const PART_FIRST_NAME_NATIONAL_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('firstNameNational')
        : t('lastNameNational');

    const PART_LAST_NAME_NATIONAL_LABEL =
      (this.tournamentIdParam && !+selectedTournamentInfo?.last_name_first) ||
      (!this.tournamentIdParam && !isRuLang)
        ? t('lastNameNational')
        : t('firstNameNational');

    let onClick, dialogTitle, dialogContent, buttonPurpose, dialo;

    onClick = () =>
      this.validateMultipleCategoriesAssignment(() =>
        this.applyCategories(participantForm)
      );
    dialogTitle = t('assignParticipant');
    dialogContent = (
      <>
        <CheckboxBtn
          checked={isFilterChecked}
          onChange={(evt) =>
            this.selectCheckbox(
              evt,
              participantForm,
              this.tournamentIdParam
                ? selectedTournamentInfo.id
                : tournamentData?.id,
              true
            )
          }
          label={t('autoSelectionByAgeSex')}
        />
        <AssignCategories
          {...{ categoryTypes, tournamentsList }}
          {...{ selectedCategories }}
          {...{ selectedCategoriesErrors }}
          switchTournament={this.switchTournament}
          addNewCategory={() => this.addNewCategory(initialStateCategory)}
          removeCategory={this.removeCategory}
          selectCategories={this.selectMultipleCategories}
          selectCategoryType={this.selectMultipleCategoryType}
          selectTeam={this.selectMultipleTeams}
          shouldDisableInput={!this.tournamentIdParam && !tournamentData?.id}
          selectedTournament={!this.tournamentIdParam && tournamentData}
        />
      </>
    );
    buttonPurpose = t('apply');

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{ dialogTitle, dialogContent, onClick, buttonPurpose }}
          subHeader={
            participantForm && (
              <span className={clsx(classes.flex, classes.column)}>
                <>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.marginBottom05,
                      classes.marginTop1
                    )}>
                    {[
                      MODAL_FIRST_NAME(participantForm),
                      MODAL_LAST_NAME(participantForm),
                      [
                        participantForm.age,
                        participantForm.gender,
                        participantForm.weight &&
                          `${participantForm.weight}${t('kg')}`,
                        (!!+selectedTournamentInfo?.height_needed ||
                          !this.tournamentIdParams) &&
                          participantForm.height &&
                          `${participantForm.height}${t('cm')}`,
                        (!!+selectedTournamentInfo?.show_reg_qdan ||
                          !this.tournamentIdParams) &&
                          participantForm.qdan_name &&
                          participantForm.qdan_name
                      ]
                        .filter(Boolean)
                        .join(', ')
                    ].join(' ')}
                  </span>
                  {t('coach')}:{' '}
                  {[
                    participantForm.coach_first_name,
                    participantForm.coach_last_name
                  ].join(' ')}
                </>
              </span>
            )
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
        />

        {isLoading ? (
          <LoadingState />
        ) : (
          <ParticipantForm
            {...{ countries }}
            {...{ selectCheckboxSchool }}
            openAssignFormInsideParticipantForm={this.showModal}
            {...{ all_sport_rank }}
            changeTxt={this.textChangeHandler}
            values={participantForm}
            selectOption={this.selectOption}
            changeImage={this.changeImage}
            {...{ errors }}
            param={PARTICIPANT_FORM}
            {...{ qdanList, specificDetails }}
            changeDate={(date) =>
              this.changeDate(
                date,
                PARTICIPANT_FORM,
                errors,
                null,
                this.tournamentIdParam &&
                  (selectedTournamentInfo?.calc_age_date ??
                    selectedTournamentInfo?.start_date)
              )
            }
            saveParticipant={this.saveParticipant}
            isFormWidthSet={viewportWidth}
            componentElems={{
              topElem: (this.tournamentIdParam ||
                isCoachItem ||
                role === 'administrator') && (
                <Filter
                  className={classes.maxHeight}
                  options={coachesList}
                  onChange={(evt, val) =>
                    this.selectOption(
                      evt,
                      val,
                      PARTICIPANT_FORM,
                      COACH_ID,
                      isCoachItem ? COACH_ID : ID,
                      errors,
                      (currentVal) => {
                        this.coachChangeHandler(currentVal);
                      }
                    )
                  }
                  value={
                    selectedValue(
                      coachesList,
                      isCoachItem ? COACH_ID : ID,
                      participantForm.coach_id
                    ) || ''
                  }
                  fullWidth
                  label={t('teamRepresentative')}
                  variant="outlined"
                  item={
                    isCoachItem
                      ? isCoachItem
                      : {
                          firstName:
                            !this.tournamentIdParam && role === 'administrator'
                              ? 'c_first_name'
                              : 'first_name',
                          lastName:
                            !this.tournamentIdParam && role === 'administrator'
                              ? 'c_last_name'
                              : 'last_name'
                        }
                  }
                  optional={isCoachOptional ? isCoachOptional : 'email'}
                  error={errors.coach_id}
                  helperText={errors.coach_id}
                  disabled={!!participantForm?.participant_id}
                  freeSolo={!!participantForm?.participant_id}
                  multiline
                  specificDetails={{
                    onClickLabel:
                      role === 'administrator'
                        ? () => {
                            navigate(
                              `/users?search=${
                                selectedValue(
                                  coachesList,
                                  ID,
                                  participantForm.coach_id,
                                  true
                                )?.email
                              }`,
                              {
                                state: { prevUrl: location.pathname }
                              }
                            );
                          }
                        : undefined
                  }}
                />
              ),

              rateElem: participantForm.rate !== undefined && (
                <Filter
                  className={classes.maxHeight}
                  options={RATE}
                  value={selectedValue(RATE, 'name', participantForm.rate)}
                  onChange={(evt, val) => {
                    this.selectOption(
                      evt,
                      val,
                      PARTICIPANT_FORM,
                      'rate',
                      'name',
                      errors
                    );
                  }}
                  fullWidth
                  label={t('rate')}
                  variant="outlined"
                  item="name"
                />
              ),
              tournamentsList: true
            }}
            showModal={this.showModal}
            openRegistration={
              !this.tournamentIdParam ||
              (selectedTournamentInfo &&
                selectedTournamentInfo.finished !== ENABLED)
            }
            personalAssignedCategories={
              this.tournamentIdParam && personalAssignedCategories
            }
            tournamentData={selectedTournamentInfo}
            fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
            {...{ allRegions }}
            tournamentIdParams={this.tournamentIdParam}
            partFirstLastNameOrder={{
              firstNameVal: PART_FIRST_NAME_VAL,
              lastNameVal: PART_LAST_NAME_VAL,
              firstNameLabel: PART_FIRST_NAME_LABEL,
              lastNameLabel: PART_LAST_NAME_LABEL,
              firstNameNationalVal: PART_FIRST_NAME_NATIONAL_VAL,
              lastNameNationalVal: PART_LAST_NAME_NATIONAL_VAL,
              firstNameNationalLabel: PART_FIRST_NAME_NATIONAL_LABEL,
              lastNameNationalLabel: PART_LAST_NAME_NATIONAL_LABEL
            }}
            onRemoveCategoriesInForm={this.onRemoveCategoriesInForm}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(AddParticipant))
);
